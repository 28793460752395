import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Container, Box, Button, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Marketplace from './pages/Marketplace';
import SignIn from './pages/SignIn';
import About from './pages/About';
import Header from './components/Header';
import Footer from './components/Footer';
import Services from './pages/Services';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
        background: {
            default: '#f4f6f8',
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif',
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Header />
                <Container maxWidth="lg">
                    <Box my={4}>
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/marketplace" element={<Marketplace />} />
                            <Route path="/services" element={<Services />} />
                            <Route path="/signin" element={<SignIn />} />
                            <Route path="/about" element={<About />} />
                        </Routes>
                    </Box>
                </Container>
                <Footer />
            </Router>
        </ThemeProvider>
    );
}

export default App;




// import React from 'react';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import { AppBar, Toolbar, Typography, Container, Box, Button, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
// import Home from './pages/Home';
// import Contact from './pages/Contact';
// import Marketplace from './pages/Marketplace';
// import SignIn from './pages/SignIn';
// import About from './pages/About';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import Services from "@pages/Services";
//
// const theme = createTheme({
//     palette: {
//         primary: {
//             main: '#1976d2',
//         },
//         secondary: {
//             main: '#dc004e',
//         },
//         background: {
//             default: '#f4f6f8',
//         },
//     },
//     typography: {
//         fontFamily: 'Roboto, Arial, sans-serif',
//     },
// });
//
// function App() {
//     return (
//         <ThemeProvider theme={theme}>
//             <CssBaseline />
//             <Router>
//                 <Header />
//                 <Container maxWidth="lg">
//                     <Box my={4}>
//                         <Switch>
//                             <Route path="/" exact component={Home} />
//                             <Route path="/contact" component={Contact} />
//                             <Route path="/marketplace" component={Marketplace} />
//                             <Route path="/services" component={Services} />
//                             <Route path="/signin" component={SignIn} />
//                             <Route path="/about" component={About} />
//                         </Switch>
//                     </Box>
//                 </Container>
//                 <Footer />
//             </Router>
//         </ThemeProvider>
//     );
// }
//
// export default App;
