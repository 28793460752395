import axios from 'axios';
import React, { useState } from 'react';
import { Container, Typography, Box, Grid, Paper, TextField, Button, Snackbar, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MuiAlert from '@mui/material/Alert';
import ReCAPTCHA from 'react-google-recaptcha';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
  },
  paper: {
    padding: theme.spacing(4),
  },
  submitButton: {
    position: 'relative',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Contact() {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      setError(true);
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post('https://us-central1-traccar-156417.cloudfunctions.net/contactFormHandlerh', {
        ...formData,
        captcha: captchaValue,
      });
      setLoading(false);
      setSuccess(true);
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
      });
      setCaptchaValue(null);
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccess(false);
    setError(false);
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <Box my={4}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Contact Us
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            For inquiries and deployment assistance, please fill out the form below or contact us at:
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            Email: support@secureaiguard.com
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            Schedule a 1-on-1 Session: <a href="https://calendly.com/secureaiguard" target="_blank" rel="noopener noreferrer">Book Now</a>
          </Typography>
          <Box mt={4}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    required
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    required
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    variant="outlined"
                    required
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Message"
                    multiline
                    rows={4}
                    variant="outlined"
                    required
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <ReCAPTCHA
                      sitekey="6Ldi718qAAAAAMFbk3GbVZdOhfqqTYxFVLiDKyBU"
                      onChange={handleCaptchaChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <div className={classes.submitButton}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                        disabled={loading}
                      >
                        Submit
                      </Button>
                      {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box>
      <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Your submission has been received. We will get back to you shortly.
        </Alert>
      </Snackbar>
      <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          There was an error submitting your form. Please try again later.
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Contact;




// import axios from 'axios';
// import React, { useState } from 'react';
// import { Container, Typography, Box, Grid, Paper, TextField, Button, Snackbar, CircularProgress } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import MuiAlert from '@mui/material/Alert';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     paddingTop: theme.spacing(8),
//   },
//   paper: {
//     padding: theme.spacing(4),
//   },
//   submitButton: {
//     position: 'relative',
//   },
//   buttonProgress: {
//     color: theme.palette.primary.main,
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     marginTop: -12,
//     marginLeft: -12,
//   },
// }));
//
// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }
//
// function Contact() {
//   const classes = useStyles();
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     message: '',
//   });
//   const [loading, setLoading] = useState(false);
//   const [success, setSuccess] = useState(false);
//   const [error, setError] = useState(false);
//
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };
//
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//
//     try {
//       const response = await axios.post('https://us-central1-traccar-156417.cloudfunctions.net/contactFormHandlerh', formData);
//       setLoading(false);
//       setSuccess(true);
//       setFormData({
//         firstName: '',
//         lastName: '',
//         email: '',
//         message: '',
//       });
//     } catch (err) {
//       setLoading(false);
//       setError(true);
//     }
//   };
//
//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setSuccess(false);
//     setError(false);
//   };
//
//   return (
//     <Container maxWidth="md" className={classes.root}>
//       <Box my={4}>
//         <Paper elevation={3} className={classes.paper}>
//           <Typography variant="h4" component="h1" gutterBottom align="center">
//             Contact Us
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             For inquiries and deployment assistance, please fill out the form below or contact us at:
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             Email: support@secureaiguard.com
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             Schedule a 1-on-1 Session: <a href="https://calendly.com/secureaiguard" target="_blank" rel="noopener noreferrer">Book Now</a>
//           </Typography>
//           <Box mt={4}>
//             <form onSubmit={handleSubmit}>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="First Name"
//                     variant="outlined"
//                     required
//                     name="firstName"
//                     value={formData.firstName}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Last Name"
//                     variant="outlined"
//                     required
//                     name="lastName"
//                     value={formData.lastName}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     fullWidth
//                     label="Email"
//                     type="email"
//                     variant="outlined"
//                     required
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     fullWidth
//                     label="Message"
//                     multiline
//                     rows={4}
//                     variant="outlined"
//                     required
//                     name="message"
//                     value={formData.message}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Box display="flex" justifyContent="center">
//                     <div className={classes.submitButton}>
//                       <Button
//                         variant="contained"
//                         color="primary"
//                         size="large"
//                         type="submit"
//                         disabled={loading}
//                       >
//                         Submit
//                       </Button>
//                       {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
//                     </div>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </form>
//           </Box>
//         </Paper>
//       </Box>
//       <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity="success">
//           Your submission has been received. We will get back to you shortly.
//         </Alert>
//       </Snackbar>
//       <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity="error">
//           There was an error submitting your form. Please try again later.
//         </Alert>
//       </Snackbar>
//     </Container>
//   );
// }
//
// export default Contact;






// import React, { useState } from 'react';
// import { Container, Typography, Box, Grid, Paper, TextField, Button, Snackbar, CircularProgress } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import MuiAlert from '@mui/material/Alert';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     paddingTop: theme.spacing(8),
//   },
//   paper: {
//     padding: theme.spacing(4),
//   },
//   submitButton: {
//     position: 'relative',
//   },
//   buttonProgress: {
//     color: theme.palette.primary.main,
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     marginTop: -12,
//     marginLeft: -12,
//   },
// }));
//
// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }
//
// function Contact() {
//   const classes = useStyles();
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     message: '',
//   });
//   const [loading, setLoading] = useState(false);
//   const [success, setSuccess] = useState(false);
//   const [error, setError] = useState(false);
//
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };
//
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//
//     // Simulate form submission
//     try {
//       // Replace with actual API call
//       await new Promise((resolve) => setTimeout(resolve, 2000));
//       setLoading(false);
//       setSuccess(true);
//       setFormData({
//         firstName: '',
//         lastName: '',
//         email: '',
//         message: '',
//       });
//     } catch (err) {
//       setLoading(false);
//       setError(true);
//     }
//   };
//
//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setSuccess(false);
//     setError(false);
//   };
//
//   return (
//     <Container maxWidth="md" className={classes.root}>
//       <Box my={4}>
//         <Paper elevation={3} className={classes.paper}>
//           <Typography variant="h4" component="h1" gutterBottom align="center">
//             Contact Us
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             For inquiries and deployment assistance, please fill out the form below or contact us at:
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             Email: support@secureaiguard.com
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             Schedule a 1-on-1 Session: <a href="https://calendly.com/secureaiguard" target="_blank" rel="noopener noreferrer">Book Now</a>
//           </Typography>
//           <Box mt={4}>
//             <form onSubmit={handleSubmit}>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="First Name"
//                     variant="outlined"
//                     required
//                     name="firstName"
//                     value={formData.firstName}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Last Name"
//                     variant="outlined"
//                     required
//                     name="lastName"
//                     value={formData.lastName}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     fullWidth
//                     label="Email"
//                     type="email"
//                     variant="outlined"
//                     required
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     fullWidth
//                     label="Message"
//                     multiline
//                     rows={4}
//                     variant="outlined"
//                     required
//                     name="message"
//                     value={formData.message}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Box display="flex" justifyContent="center">
//                     <div className={classes.submitButton}>
//                       <Button
//                         variant="contained"
//                         color="primary"
//                         size="large"
//                         type="submit"
//                         disabled={loading}
//                       >
//                         Submit
//                       </Button>
//                       {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
//                     </div>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </form>
//           </Box>
//         </Paper>
//       </Box>
//       <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity="success">
//           Your submission has been received. We will get back to you shortly.
//         </Alert>
//       </Snackbar>
//       <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity="error">
//           There was an error submitting your form. Please try again later.
//         </Alert>
//       </Snackbar>
//     </Container>
//   );
// }
//
// export default Contact;







// import React, { useState } from 'react';
// import { Container, Typography, Box, Grid, Paper, TextField, Button, Snackbar, CircularProgress } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import MuiAlert from '@mui/material/Alert';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     paddingTop: theme.spacing(8),
//   },
//   paper: {
//     padding: theme.spacing(4),
//   },
//   submitButton: {
//     position: 'relative',
//   },
//   buttonProgress: {
//     color: theme.palette.primary.main,
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     marginTop: -12,
//     marginLeft: -12,
//   },
// }));
//
// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }
//
// function Contact() {
//   const classes = useStyles();
//   const [formData, setFormData] = useState({
//     firstName: '',
//     lastName: '',
//     email: '',
//     message: '',
//   });
//   const [loading, setLoading] = useState(false);
//   const [success, setSuccess] = useState(false);
//   const [error, setError] = useState(false);
//
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };
//
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);
//
//     // Simulate form submission
//     try {
//       // Replace with actual API call
//       await new Promise((resolve) => setTimeout(resolve, 2000));
//       setLoading(false);
//       setSuccess(true);
//       setFormData({
//         firstName: '',
//         lastName: '',
//         email: '',
//         message: '',
//       });
//     } catch (err) {
//       setLoading(false);
//       setError(true);
//     }
//   };
//
//   const handleClose = (event, reason) => {
//     if (reason === 'clickaway') {
//       return;
//     }
//     setSuccess(false);
//     setError(false);
//   };
//
//   return (
//     <Container maxWidth="md" className={classes.root}>
//       <Box my={4}>
//         <Paper elevation={3} className={classes.paper}>
//           <Typography variant="h4" component="h1" gutterBottom align="center">
//             Contact Us
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             For inquiries and deployment assistance, please fill out the form below or contact us at:
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             Email: support@secureaiguard.com
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             Schedule a 1-on-1 Session: <a href="https://calendly.com/secureaiguard" target="_blank" rel="noopener noreferrer">Book Now</a>
//           </Typography>
//           <Box mt={4}>
//             <form onSubmit={handleSubmit}>
//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="First Name"
//                     variant="outlined"
//                     required
//                     name="firstName"
//                     value={formData.firstName}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Last Name"
//                     variant="outlined"
//                     required
//                     name="lastName"
//                     value={formData.lastName}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     fullWidth
//                     label="Email"
//                     type="email"
//                     variant="outlined"
//                     required
//                     name="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <TextField
//                     fullWidth
//                     label="Message"
//                     multiline
//                     rows={4}
//                     variant="outlined"
//                     required
//                     name="message"
//                     value={formData.message}
//                     onChange={handleChange}
//                   />
//                 </Grid>
//                 <Grid item xs={12}>
//                   <Box display="flex" justifyContent="center">
//                     <div className={classes.submitButton}>
//                       <Button
//                         variant="contained"
//                         color="primary"
//                         size="large"
//                         type="submit"
//                         disabled={loading}
//                       >
//                         Submit
//                       </Button>
//                       {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
//                     </div>
//                   </Box>
//                 </Grid>
//               </Grid>
//             </form>
//           </Box>
//         </Paper>
//       </Box>
//       <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity="success">
//           Your submission has been received. We will get back to you shortly.
//         </Alert>
//       </Snackbar>
//       <Snackbar open={error} autoHideDuration={6000} onClose={handleClose}>
//         <Alert onClose={handleClose} severity="error">
//           There was an error submitting your form. Please try again later.
//         </Alert>
//       </Snackbar>
//     </Container>
//   );
// }
//
// export default Contact;




// import React from 'react';
// import { Container, Typography, Box, Grid, Paper, TextField, Button } from '@mui/material';
// import { makeStyles } from '@mui/styles';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     paddingTop: theme.spacing(8), // Add top padding to create space between header and content
//   },
//   paper: {
//     padding: theme.spacing(4),
//   },
// }));
//
// function Contact() {
//   const classes = useStyles();
//
//   return (
//     <Container maxWidth="md" className={classes.root}>
//       <Box my={4}>
//         <Paper elevation={3} className={classes.paper}>
//           <Typography variant="h4" component="h1" gutterBottom align="center">
//             Contact Us
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             For inquiries and deployment assistance, please fill out the form below or contact us at:
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             Email: support@secureaiguard.com
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             Schedule a 1-on-1 Session: <a href="https://calendly.com/secureaiguard" target="_blank" rel="noopener noreferrer">Book Now</a>
//           </Typography>
//           <Box mt={4}>
//             <Grid container spacing={2}>
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   fullWidth
//                   label="First Name"
//                   variant="outlined"
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <TextField
//                   fullWidth
//                   label="Last Name"
//                   variant="outlined"
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   fullWidth
//                   label="Email"
//                   type="email"
//                   variant="outlined"
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <TextField
//                   fullWidth
//                   label="Message"
//                   multiline
//                   rows={4}
//                   variant="outlined"
//                   required
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <Box display="flex" justifyContent="center">
//                   <Button variant="contained" color="primary" size="large">
//                     Submit
//                   </Button>
//                 </Box>
//               </Grid>
//             </Grid>
//           </Box>
//         </Paper>
//       </Box>
//     </Container>
//   );
// }
//
// export default Contact;




// import React from 'react';
// import { Container, Typography, Box, Grid, Paper, TextField, Button } from '@mui/material';
//
// function Contact() {
//     return (
//         <Container maxWidth="md">
//             <Box my={4}>
//                 <Paper elevation={3} sx={{ padding: 4 }}>
//                     <Typography variant="h4" component="h1" gutterBottom align="center">
//                         Contact Us
//                     </Typography>
//                     <Typography variant="body1" gutterBottom align="center">
//                         For inquiries and deployment assistance, please fill out the form below or contact us at:
//                     </Typography>
//                     <Typography variant="body1" gutterBottom align="center">
//                         Email: support@secureaiguard.com
//                     </Typography>
//                     <Typography variant="body1" gutterBottom align="center">
//                         Schedule a 1-on-1 Session: <a href="https://calendly.com/secureaiguard">Book Now</a>
//                     </Typography>
//                     <Box mt={4}>
//                         <Grid container spacing={2}>
//                             <Grid item xs={12} sm={6}>
//                                 <TextField
//                                     fullWidth
//                                     label="First Name"
//                                     variant="outlined"
//                                     required
//                                 />
//                             </Grid>
//                             <Grid item xs={12} sm={6}>
//                                 <TextField
//                                     fullWidth
//                                     label="Last Name"
//                                     variant="outlined"
//                                     required
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     fullWidth
//                                     label="Email"
//                                     type="email"
//                                     variant="outlined"
//                                     required
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <TextField
//                                     fullWidth
//                                     label="Message"
//                                     multiline
//                                     rows={4}
//                                     variant="outlined"
//                                     required
//                                 />
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <Box display="flex" justifyContent="center">
//                                     <Button variant="contained" color="primary" size="large">
//                                         Submit
//                                     </Button>
//                                 </Box>
//                             </Grid>
//                         </Grid>
//                     </Box>
//                 </Paper>
//             </Box>
//         </Container>
//     );
// }
//
// export default Contact;
