import React from 'react';
import { Box, Container, Grid, Typography, Paper, Avatar } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import LayersIcon from '@mui/icons-material/Layers';
import UpdateIcon from '@mui/icons-material/Update';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import SchoolIcon from '@mui/icons-material/School';

const approachData = [
    {
        icon: <LockIcon fontSize="large" />,
        title: "Strategic Closed-Source Core",
        description: "The essence of SecureAI Guard's defense mechanism is intentionally kept closed-source to prevent reverse engineering and protect intellectual property."
    },
    {
        icon: <LayersIcon fontSize="large" />,
        title: "Multi-Layered Defense System",
        description: "Our defense strategy is composed of several interlocking layers, each designed to address distinct threat vectors."
    },
    {
        icon: <UpdateIcon fontSize="large" />,
        title: "Continuous Evolution",
        description: "We deploy consistent updates to our security infrastructure, ensuring resilience against the latest threats."
    },
    {
        icon: <GroupWorkIcon fontSize="large" />,
        title: "Community Feedback Loop",
        description: "Active engagement with the cybersecurity community allows us to refine and enhance our defenses collaboratively."
    },
    {
        icon: <SchoolIcon fontSize="large" />,
        title: "Research and Collaboration",
        description: "Partnerships with academic and industry leaders drive our pursuit of next-generation security technologies."
    }
];

function Approach() {
    return (
        <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
            <Container maxWidth="lg">
                <Typography variant="h4" component="h2" gutterBottom align="center">
                    Our Approach
                </Typography>
                <Typography variant="body1" gutterBottom align="center">
                    At SecureAI Guard, we are dedicated to fortifying language model applications against a spectrum of cyber threats. Our approach blends cutting-edge technology with strategic operational decisions.
                </Typography>
                <Grid container spacing={4}>
                    {approachData.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Paper elevation={3} sx={{ p: 3, textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
                                    {item.icon}
                                </Avatar>
                                <Typography variant="h6" component="h3" gutterBottom>
                                    {item.title}
                                </Typography>
                                <Typography variant="body1">
                                    {item.description}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default Approach;


// import React from 'react';
// import { Box, Container, Grid, Typography, Paper, Avatar } from '@mui/material';
// import LockIcon from '@mui/icons-material/Lock';
// import LayersIcon from '@mui/icons-material/Layers';
// import UpdateIcon from '@mui/icons-material/Update';
// import GroupWorkIcon from '@mui/icons-material/GroupWork';
// import SchoolIcon from '@mui/icons-material/School';
//
// const approachData = [
//     {
//         icon: <LockIcon fontSize="large" />,
//         title: "Strategic Closed-Source Core",
//         description: "The essence of SecureAI Guard's defense mechanism is intentionally kept closed-source to prevent reverse engineering and protect intellectual property."
//     },
//     {
//         icon: <LayersIcon fontSize="large" />,
//         title: "Multi-Layered Defense System",
//         description: "Our defense strategy is composed of several interlocking layers, each designed to address distinct threat vectors."
//     },
//     {
//         icon: <UpdateIcon fontSize="large" />,
//         title: "Continuous Evolution",
//         description: "We deploy consistent updates to our security infrastructure, ensuring resilience against the latest threats."
//     },
//     {
//         icon: <GroupWorkIcon fontSize="large" />,
//         title: "Community Feedback Loop",
//         description: "Active engagement with the cybersecurity community allows us to refine and enhance our defenses collaboratively."
//     },
//     {
//         icon: <SchoolIcon fontSize="large" />,
//         title: "Research and Collaboration",
//         description: "Partnerships with academic and industry leaders drive our pursuit of next-generation security technologies."
//     }
// ];
//
// function Approach() {
//     return (
//         <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
//             <Container maxWidth="lg">
//                 <Typography variant="h4" component="h2" gutterBottom align="center">
//                     Our Approach
//                 </Typography>
//                 <Typography variant="body1" gutterBottom align="center">
//                     At SecureAI Guard, we are dedicated to fortifying language model applications against a spectrum of cyber threats. Our approach blends cutting-edge technology with strategic operational decisions.
//                 </Typography>
//                 <Grid container spacing={4}>
//                     {approachData.map((item, index) => (
//                         <Grid item xs={12} sm={6} md={4} key={index}>
//                             <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }}>
//                                 <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
//                                     {item.icon}
//                                 </Avatar>
//                                 <Typography variant="h6" component="h3" gutterBottom>
//                                     {item.title}
//                                 </Typography>
//                                 <Typography variant="body1">
//                                     {item.description}
//                                 </Typography>
//                             </Paper>
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Container>
//         </Box>
//     );
// }
//
// export default Approach;
