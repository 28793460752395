import React from 'react';
import { Box, Container, Grid, Typography, Paper, Avatar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import SecurityIcon from '@mui/icons-material/Security';

const performanceData = [
    {
        icon: <CheckCircleIcon fontSize="large" />,
        title: "Social Engineering",
        description: "Identified and mitigated successfully.",
        status: "success"
    },
    {
        icon: <SecurityIcon fontSize="large" />,
        title: "Data Exfiltration Attempt",
        description: "Detected and blocked.",
        status: "success"
    },
    {
        icon: <ErrorIcon fontSize="large" />,
        title: "Illegal Activity Instruction",
        description: "Prevented execution.",
        status: "success"
    },
    {
        icon: <CheckCircleIcon fontSize="large" />,
        title: "Safe Technical Query",
        description: "No issues detected.",
        status: "success"
    },
    {
        icon: <ErrorIcon fontSize="large" />,
        title: "Implicit Risk Content",
        description: "Identified and flagged.",
        status: "warning"
    },
    {
        icon: <SecurityIcon fontSize="large" />,
        title: "Explicit Threat",
        description: "Blocked immediately.",
        status: "danger"
    }
];

function Performance() {
    return (
        <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
            <Container maxWidth="lg">
                <Typography variant="h4" component="h2" gutterBottom align="center">
                    Performance Benchmarks
                </Typography>
                <Typography variant="body1" gutterBottom align="center">
                    Our extensive testing showcases SecureAI Guard's effectiveness across various scenarios:
                </Typography>
                <Grid container spacing={4}>
                    {performanceData.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Paper elevation={3} sx={{ p: 3, textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <Avatar sx={{ bgcolor: item.status === 'success' ? 'green' : item.status === 'warning' ? 'orange' : 'red', mx: 'auto', mb: 2 }}>
                                    {item.icon}
                                </Avatar>
                                <Typography variant="h6" component="h3" gutterBottom>
                                    {item.title}
                                </Typography>
                                <Typography variant="body1">
                                    {item.description}
                                </Typography>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default Performance;


//
// import React, { useState } from 'react';
// import { Box, Container, Grid, Typography, Paper, Avatar, Tooltip, IconButton, Fade } from '@mui/material';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import ErrorIcon from '@mui/icons-material/Error';
// import SecurityIcon from '@mui/icons-material/Security';
//
// const performanceData = [
//     {
//         icon: <CheckCircleIcon fontSize="large" />,
//         title: "Social Engineering",
//         description: "Identified and mitigated successfully.",
//         status: "success"
//     },
//     {
//         icon: <SecurityIcon fontSize="large" />,
//         title: "Data Exfiltration Attempt",
//         description: "Detected and blocked.",
//         status: "success"
//     },
//     {
//         icon: <ErrorIcon fontSize="large" />,
//         title: "Illegal Activity Instruction",
//         description: "Prevented execution.",
//         status: "success"
//     },
//     {
//         icon: <CheckCircleIcon fontSize="large" />,
//         title: "Safe Technical Query",
//         description: "No issues detected.",
//         status: "success"
//     },
//     {
//         icon: <ErrorIcon fontSize="large" />,
//         title: "Implicit Risk Content",
//         description: "Identified and flagged.",
//         status: "warning"
//     },
//     {
//         icon: <SecurityIcon fontSize="large" />,
//         title: "Explicit Threat",
//         description: "Blocked immediately.",
//         status: "danger"
//     }
// ];
//
// function Performance() {
//     const [hoveredFeature, setHoveredFeature] = useState(null);
//
//     return (
//         <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
//             <Container maxWidth="lg">
//                 <Typography variant="h4" component="h2" gutterBottom align="center">
//                     Performance Benchmarks
//                 </Typography>
//                 <Typography variant="body1" gutterBottom align="center">
//                     Our extensive testing showcases SecureAI Guard's effectiveness across various scenarios:
//                 </Typography>
//                 <Grid container spacing={4}>
//                     {performanceData.map((item, index) => (
//                         <Grid item xs={12} sm={6} md={4} key={index}>
//                             <Paper elevation={3} sx={{ p: 3, textAlign: 'center', position: 'relative' }}>
//                                 <Tooltip title={item.description} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
//                                     <IconButton
//                                         onMouseEnter={() => setHoveredFeature(index)}
//                                         onMouseLeave={() => setHoveredFeature(null)}
//                                     >
//                                         <Avatar sx={{ bgcolor: item.status === 'success' ? 'green' : item.status === 'warning' ? 'orange' : 'red', mx: 'auto', mb: 2 }}>
//                                             {item.icon}
//                                         </Avatar>
//                                     </IconButton>
//                                 </Tooltip>
//                                 <Typography variant="h6" component="h3" gutterBottom>
//                                     {item.title}
//                                 </Typography>
//                                 {hoveredFeature === index && (
//                                     <Fade in={true} timeout={600}>
//                                         <Typography variant="body2" sx={{ mt: 2 }}>
//                                             {item.description}
//                                         </Typography>
//                                     </Fade>
//                                 )}
//                             </Paper>
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Container>
//         </Box>
//     );
// }
//
// export default Performance;
//
