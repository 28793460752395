import React from 'react';
import ReactDOM from 'react-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App';
// import { logMessage } from './logging';
// import { createCustomMetric, writeTimeSeriesData } from './monitoring';

// Create a custom theme with MUI
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#f4f6f8',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          textTransform: 'none',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '16px',
          margin: '16px 0',
        },
      },
    },
  },
});

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById('root')
);

// // Log a message when the application starts
// logMessage('Application started');
//
// // Create a custom metric and write time series data
// createCustomMetric().then(() => {
//   writeTimeSeriesData();
// });




// import React from 'react';
// import ReactDOM from 'react-dom';
// import { CssBaseline } from '@mui/material';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { StyledEngineProvider } from '@mui/material/styles';
// import App from './App';
// import { logMessage } from './logging';
// import { createCustomMetric, writeTimeSeriesData } from './monitoring';
//
// // Create a custom theme with MUI
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#1976d2',
//     },
//     secondary: {
//       main: '#dc004e',
//     },
//     background: {
//       default: '#f4f6f8',
//     },
//   },
//   typography: {
//     fontFamily: 'Roboto, Arial, sans-serif',
//     h1: {
//       fontSize: '2.5rem',
//       fontWeight: 700,
//     },
//     h2: {
//       fontSize: '2rem',
//       fontWeight: 600,
//     },
//     h3: {
//       fontSize: '1.75rem',
//       fontWeight: 500,
//     },
//     h4: {
//       fontSize: '1.5rem',
//       fontWeight: 500,
//     },
//     h5: {
//       fontSize: '1.25rem',
//       fontWeight: 400,
//     },
//     h6: {
//       fontSize: '1rem',
//       fontWeight: 400,
//     },
//     body1: {
//       fontSize: '1rem',
//       fontWeight: 400,
//     },
//     body2: {
//       fontSize: '0.875rem',
//       fontWeight: 400,
//     },
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           borderRadius: '8px',
//           textTransform: 'none',
//         },
//       },
//     },
//     MuiPaper: {
//       styleOverrides: {
//         root: {
//           padding: '16px',
//           margin: '16px 0',
//         },
//       },
//     },
//   },
// });
//
// ReactDOM.render(
//   <StyledEngineProvider injectFirst>
//     <ThemeProvider theme={theme}>
//       <CssBaseline />
//       <App />
//     </ThemeProvider>
//   </StyledEngineProvider>,
//   document.getElementById('root')
// );
//
// // Log a message when the application starts
// logMessage('Application started');
//
// // Create a custom metric and write time series data
// createCustomMetric().then(() => {
//   writeTimeSeriesData();
// });