import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Box, Grid, Paper, Avatar } from '@mui/material';
import TeamMember1 from '../../public/assets/images/futuristic2.jpg';
// import TeamMember1 from '../../public/assets/images/furturistic2.jpg';
// import TeamMember2 from '../assets/images/team-member-2.jpg';
// import TeamMember3 from '../assets/images/team-member-3.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(6),
  },
  section: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: 'auto',
    marginBottom: theme.spacing(2),
  },
}));

function About() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Box className={classes.section}>
          <Typography variant="h2" component="h1" gutterBottom align="center">
            About SecureAI Guard
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Our Mission and Vision
          </Typography>
          <Typography variant="body1" align="center">
            At SecureAI Guard, our mission is to provide enterprise-grade security solutions for large language model (LLM) and Generative AI applications. We are dedicated to safeguarding AI systems against a wide range of threats, ensuring secure and compliant interactions.
          </Typography>
          <Typography variant="body1" align="center">
            Our vision is to be the leading provider of AI security solutions, empowering organizations to leverage the full potential of AI while maintaining the highest standards of security and privacy.
          </Typography>
        </Box>

        {/*<Box className={classes.section}>*/}
        {/*  <Typography variant="h4" component="h2" gutterBottom align="center">*/}
        {/*    Our Team*/}
        {/*  </Typography>*/}
          {/*<Grid container spacing={4}>*/}
          {/*  <Grid item xs={12} sm={4}>*/}
          {/*    <Paper className={classes.paper} elevation={3}>*/}
          {/*      <Avatar src={TeamMember1} alt="Team Member 1" className={classes.avatar} />*/}
          {/*      <Typography variant="h6" component="h3" gutterBottom>*/}
          {/*        John Doe*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="body2">*/}
          {/*        CEO & Founder*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="body1">*/}
          {/*        John is a seasoned entrepreneur with over 20 years of experience in the tech industry. He founded SecureAI Guard to address the growing need for AI security solutions.*/}
          {/*      </Typography>*/}
          {/*    </Paper>*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={12} sm={4}>*/}
          {/*    <Paper className={classes.paper} elevation={3}>*/}
          {/*      /!*<Avatar src={TeamMember2} alt="Team Member 2" className={classes.avatar} />*!/*/}
          {/*      <Typography variant="h6" component="h3" gutterBottom>*/}
          {/*        Jane Smith*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="body2">*/}
          {/*        CTO*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="body1">*/}
          {/*        Jane is an expert in AI and cybersecurity with a PhD in Computer Science. She leads the technical team at SecureAI Guard, driving innovation and excellence.*/}
          {/*      </Typography>*/}
          {/*    </Paper>*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={12} sm={4}>*/}
          {/*    <Paper className={classes.paper} elevation={3}>*/}
          {/*      /!*<Avatar src={TeamMember3} alt="Team Member 3" className={classes.avatar} />*!/*/}
          {/*      <Typography variant="h6" component="h3" gutterBottom>*/}
          {/*        Michael Brown*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="body2">*/}
          {/*        Head of Security*/}
          {/*      </Typography>*/}
          {/*      <Typography variant="body1">*/}
          {/*        Michael has over 15 years of experience in cybersecurity. He is responsible for ensuring that SecureAI Guard's solutions meet the highest security standards.*/}
          {/*      </Typography>*/}
          {/*    </Paper>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        {/*</Box>*/}

        <Box className={classes.section}>
          <Typography variant="h4" component="h2" gutterBottom align="center">
            Our Values
          </Typography>
          <Typography variant="body1" align="center">
            Integrity, Innovation, Excellence, Customer Focus, and Collaboration are the core values that drive everything we do at SecureAI Guard. We are committed to delivering the best AI security solutions to our clients.
          </Typography>
        </Box>
      </Container>
    </div>
  );
}

export default About;
