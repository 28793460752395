import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Box, Grid, Paper, Avatar, Button, Modal, Backdrop, Fade, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import CloudIcon from '@mui/icons-material/Cloud';
import UpdateIcon from '@mui/icons-material/Update';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import LockIcon from '@mui/icons-material/Lock';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(6), // Add top padding to create space between header and content
  },
  section: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(4),
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
    cursor: 'pointer',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: theme.shadows[10],
      backgroundColor: theme.palette.primary.light,
    },
    '&:hover $overlay': {
      opacity: 1,
    },
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    margin: 'auto',
    marginBottom: theme.spacing(2),
    transition: 'transform 0.3s, color 0.3s',
    '&:hover': {
      transform: 'rotateY(180deg)',
      color: theme.palette.secondary.main,
    },
  },
  button: {
    marginTop: theme.spacing(4),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    width: '90%',
    height: '90%',
    overflowY: 'auto',
    outline: 'none',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
  backdrop: {
    backdropFilter: 'blur(10px)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity 0.3s',
    borderRadius: theme.shape.borderRadius,
  },
  overlayText: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
}));

const services = [
  {
    icon: <SecurityIcon fontSize="large" />,
    title: "Cutting-edge Threat Detection",
    description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification.",
    details: (
      <>
        <Typography variant="h4" gutterBottom>
          Cutting-edge Threat Detection
        </Typography>
        <Typography variant="body1" paragraph>
          Our cutting-edge threat detection service leverages a combination of advanced machine learning models, heuristic algorithms, and signature-based methods to identify and mitigate threats with high precision.
        </Typography>
        <img src="/assets/images/threat-detection.jpg" alt="Threat Detection" style={{ width: '100%', marginBottom: '20px' }} />
        <Typography variant="body1" paragraph>
          By analyzing patterns and behaviors, our system can detect both known and unknown threats, providing comprehensive protection for your AI applications. This service ensures that your systems remain secure against evolving cyber threats.
        </Typography>
        <Typography variant="body1" paragraph>
          Detailed reports and visual representations help you understand the nature of detected threats and take appropriate actions to safeguard your data and infrastructure.
        </Typography>
      </>
    ),
  },
  {
    icon: <SpeedIcon fontSize="large" />,
    title: "Real-time Analysis",
    description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications.",
    details: (
      <>
        <Typography variant="h4" gutterBottom>
          Real-time Analysis
        </Typography>
        <Typography variant="body1" paragraph>
          Our real-time analysis service provides sub-millisecond response times for content scanning, making it ideal for integration with real-time applications.
        </Typography>
        <img src="/assets/images/real-time-analysis.jpg" alt="Real-time Analysis" style={{ width: '100%', marginBottom: '20px' }} />
        <Typography variant="body1" paragraph>
          This service ensures that your AI applications can process and respond to user inputs instantly, enhancing user experience and operational efficiency.
        </Typography>
        <Typography variant="body1" paragraph>
          With real-time analysis, you can detect and mitigate threats as they occur, preventing potential damage and maintaining the integrity of your systems.
        </Typography>
      </>
    ),
  },
  {
    icon: <CloudIcon fontSize="large" />,
    title: "Cloud-Native Compatibility",
    description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run.",
    details: (
      <>
        <Typography variant="h4" gutterBottom>
          Cloud-Native Compatibility
        </Typography>
        <Typography variant="body1" paragraph>
          Our cloud-native compatibility service ensures seamless integration with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run.
        </Typography>
        <img src="/assets/images/cloud-native.jpg" alt="Cloud-Native Compatibility" style={{ width: '100%', marginBottom: '20px' }} />
        <Typography variant="body1" paragraph>
          This service allows you to leverage the scalability, flexibility, and cost-efficiency of cloud platforms while maintaining robust security measures.
        </Typography>
        <Typography variant="body1" paragraph>
          By integrating with cloud-native environments, we enable you to deploy and manage your AI applications effortlessly, ensuring high availability and performance.
        </Typography>
      </>
    ),
  },
  {
    icon: <UpdateIcon fontSize="large" />,
    title: "Continuous Updates",
    description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors.",
    details: (
      <>
        <Typography variant="h4" gutterBottom>
          Continuous Updates
        </Typography>
        <Typography variant="body1" paragraph>
          Our continuous updates service ensures that your security measures remain effective against emerging threats by regularly updating detection patterns and algorithms.
        </Typography>
        <img src="/assets/images/continuous-updates.jpg" alt="Continuous Updates" style={{ width: '100%', marginBottom: '20px' }} />
        <Typography variant="body1" paragraph>
          We monitor the latest developments in cybersecurity and incorporate new threat intelligence into our systems to provide you with up-to-date protection.
        </Typography>
        <Typography variant="body1" paragraph>
          This proactive approach helps you stay ahead of cybercriminals and safeguard your AI applications from evolving security challenges.
        </Typography>
      </>
    ),
  },
  {
    icon: <PrivacyTipIcon fontSize="large" />,
    title: "Data Privacy Assurance",
    description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain.",
    details: (
      <>
        <Typography variant="h4" gutterBottom>
          Data Privacy Assurance
        </Typography>
        <Typography variant="body1" paragraph>
          Our data privacy assurance service operates entirely on-premises or within your private cloud environment, ensuring that sensitive data never leaves your controlled domain.
        </Typography>
        <img src="/assets/images/data-privacy.jpg" alt="Data Privacy Assurance" style={{ width: '100%', marginBottom: '20px' }} />
        <Typography variant="body1" paragraph>
          This service guarantees compliance with data protection regulations and provides peace of mind by keeping your data secure and private.
        </Typography>
        <Typography variant="body1" paragraph>
          By implementing strict access controls and encryption, we ensure that only authorized users can access sensitive information, protecting your organization from data breaches and unauthorized access.
        </Typography>
      </>
    ),
  },
  {
    icon: <LockIcon fontSize="large" />,
    title: "Permission-Based Content Access",
    description: "Provides permission-based content access to users on LLM and vector databases, ensuring they only access data they are authorized to view.",
    details: (
      <>
        <Typography variant="h4" gutterBottom>
          Permission-Based Content Access
        </Typography>
        <Typography variant="body1" paragraph>
          Our service ensures that users only access data they are authorized to view in large language models (LLM) and vector databases. By implementing Single Sign-On (SSO) and different access levels based on user profiles, we guarantee secure and compliant data access.
        </Typography>
        <img src="/assets/images/permission-based-access.jpg" alt="Permission-Based Content Access" style={{ width: '100%', marginBottom: '20px' }} />
        <Typography variant="body1" paragraph>
          Users submitting prompts to generative AI applications will only receive responses they have access to. If a user attempts to access restricted data, they will be informed that they do not have access and should contact their data owner for permissions.
        </Typography>
        <Typography variant="body1" paragraph>
          This service allows organizations to ingest various data categories into vector databases while controlling access at the query level. It eliminates the need for multiple database silos, reducing management overhead, cost implications, and complexity in application development and implementation.
        </Typography>
      </>
    ),
  }
];

function Services() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleOpen = (service) => {
    setSelectedService(service);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedService(null);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Box className={classes.section}>
          <Typography variant="h2" component="h1" gutterBottom align="center">
            Our Services
          </Typography>
          <Typography variant="h5" component="h2" gutterBottom align="center">
            Comprehensive Security Solutions for AI Applications
          </Typography>
          <Typography variant="body1" align="center">
            At SecureAI Guard, we offer a range of services designed to protect your AI applications from various threats. Our solutions are tailored to meet the unique needs of each client, ensuring the highest levels of security and compliance.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper className={classes.paper} elevation={3} onClick={() => handleOpen(service)}>
                <Avatar className={classes.avatar} sx={{ bgcolor: 'primary.main' }}>
                  {service.icon}
                </Avatar>
                <Typography variant="h6" component="h3" gutterBottom>
                  {service.title}
                </Typography>
                <Typography variant="body1">
                  {service.description}
                </Typography>
                <Box className={classes.overlay}>
                  <Typography className={classes.overlayText}>Click for more details</Typography>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <Box className={classes.section} textAlign="center">
          <Typography variant="h4" component="h2" gutterBottom>
            Why Choose SecureAI Guard?
          </Typography>
          <Typography variant="body1" gutterBottom>
            Our team of experts is dedicated to providing the best security solutions for AI applications. We leverage the latest technologies and methodologies to ensure your AI systems are protected against evolving threats.
          </Typography>
          <Button variant="contained" color="primary" size="large" className={classes.button} href="/contact">
            Contact Us
          </Button>
        </Box>
      </Container>

      <Modal
        aria-labelledby="service-modal-title"
        aria-describedby="service-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          className: classes.backdrop,
        }}
      >
        <Fade in={open}>
          <Box className={classes.modalPaper} onDoubleClick={handleClose}>
            <IconButton onClick={handleClose} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
            {selectedService && (
              <>
                {selectedService.details}
              </>
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default Services;





// import React, { useState } from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Paper, Avatar, Button, Modal, Backdrop, Fade, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import SecurityIcon from '@mui/icons-material/Security';
// import SpeedIcon from '@mui/icons-material/Speed';
// import CloudIcon from '@mui/icons-material/Cloud';
// import UpdateIcon from '@mui/icons-material/Update';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
// import LockIcon from '@mui/icons-material/Lock';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//     paddingTop: theme.spacing(6), // Add top padding to create space between header and content
//   },
//   section: {
//     marginTop: theme.spacing(6),
//     marginBottom: theme.spacing(6),
//   },
//   paper: {
//     position: 'relative',
//     padding: theme.spacing(4),
//     textAlign: 'center',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
//     cursor: 'pointer',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: theme.shadows[10],
//       backgroundColor: theme.palette.primary.light,
//     },
//     '&:hover $overlay': {
//       opacity: 1,
//     },
//   },
//   avatar: {
//     width: theme.spacing(12),
//     height: theme.spacing(12),
//     margin: 'auto',
//     marginBottom: theme.spacing(2),
//     transition: 'transform 0.3s, color 0.3s',
//     '&:hover': {
//       transform: 'rotateY(180deg)',
//       color: theme.palette.secondary.main,
//     },
//   },
//   button: {
//     marginTop: theme.spacing(4),
//   },
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   modalPaper: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(4),
//     width: '90%',
//     height: '90%',
//     overflowY: 'auto',
//     outline: 'none',
//     position: 'relative',
//   },
//   closeButton: {
//     position: 'absolute',
//     top: theme.spacing(2),
//     right: theme.spacing(2),
//   },
//   backdrop: {
//     backdropFilter: 'blur(10px)',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   },
//   sectionTitle: {
//     marginBottom: theme.spacing(4),
//   },
//   overlay: {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.6)',
//     color: theme.palette.common.white,
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     opacity: 0,
//     transition: 'opacity 0.3s',
//     borderRadius: theme.shape.borderRadius,
//   },
//   overlayText: {
//     fontSize: '1.2rem',
//     fontWeight: 'bold',
//   },
// }));
//
// const services = [
//   {
//     icon: <SecurityIcon fontSize="large" />,
//     title: "Cutting-edge Threat Detection",
//     description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cutting-edge Threat Detection
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cutting-edge Threat Detection with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/threat-detection.jpg" alt="Threat Detection" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <SpeedIcon fontSize="large" />,
//     title: "Real-time Analysis",
//     description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Real-time Analysis
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Real-time Analysis with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/real-time-analysis.jpg" alt="Real-time Analysis" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <CloudIcon fontSize="large" />,
//     title: "Cloud-Native Compatibility",
//     description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cloud-Native Compatibility
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cloud-Native Compatibility with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/cloud-native.jpg" alt="Cloud-Native Compatibility" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <UpdateIcon fontSize="large" />,
//     title: "Continuous Updates",
//     description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Continuous Updates
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Continuous Updates with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/continuous-updates.jpg" alt="Continuous Updates" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <PrivacyTipIcon fontSize="large" />,
//     title: "Data Privacy Assurance",
//     description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Data Privacy Assurance
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Data Privacy Assurance with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/data-privacy.jpg" alt="Data Privacy Assurance" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <LockIcon fontSize="large" />,
//     title: "Permission-Based Content Access",
//     description: "Provides permission-based content access to users on LLM and vector databases, ensuring they only access data they are authorized to view.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Permission-Based Content Access
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Our service ensures that users only access data they are authorized to view in large language models (LLM) and vector databases. By implementing Single Sign-On (SSO) and different access levels based on user profiles, we guarantee secure and compliant data access.
//         </Typography>
//         <img src="/assets/images/permission-based-access.jpg" alt="Permission-Based Content Access" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           Users submitting prompts to generative AI applications will only receive responses they have access to. If a user attempts to access restricted data, they will be informed that they do not have access and should contact their data owner for permissions.
//         </Typography>
//         <Typography variant="body1" paragraph>
//           This service allows organizations to ingest various data classifications into vector databases while controlling access at the query level. It eliminates the need for multiple database silos, reducing management overhead, cost implications, and complexity in application development and implementation.
//         </Typography>
//       </>
//     ),
//   }
// ];
//
// function Services() {
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);
//   const [selectedService, setSelectedService] = useState(null);
//
//   const handleOpen = (service) => {
//     setSelectedService(service);
//     setOpen(true);
//   };
//
//   const handleClose = () => {
//     setOpen(false);
//     setSelectedService(null);
//   };
//
//   return (
//     <div className={classes.root}>
//       <Container maxWidth="lg">
//         <Box className={classes.section}>
//           <Typography variant="h2" component="h1" gutterBottom align="center">
//             Our Services
//           </Typography>
//           <Typography variant="h5" component="h2" gutterBottom align="center">
//             Comprehensive Security Solutions for AI Applications
//           </Typography>
//           <Typography variant="body1" align="center">
//             At SecureAI Guard, we offer a range of services designed to protect your AI applications from various threats. Our solutions are tailored to meet the unique needs of each client, ensuring the highest levels of security and compliance.
//           </Typography>
//         </Box>
//
//         <Grid container spacing={4}>
//           {services.map((service, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Paper className={classes.paper} elevation={3} onClick={() => handleOpen(service)}>
//                 <Avatar className={classes.avatar} sx={{ bgcolor: 'primary.main' }}>
//                   {service.icon}
//                 </Avatar>
//                 <Typography variant="h6" component="h3" gutterBottom>
//                   {service.title}
//                 </Typography>
//                 <Typography variant="body1">
//                   {service.description}
//                 </Typography>
//                 <Box className={classes.overlay}>
//                   <Typography className={classes.overlayText}>Click for more details</Typography>
//                 </Box>
//               </Paper>
//             </Grid>
//           ))}
//         </Grid>
//
//         <Box className={classes.section} textAlign="center">
//           <Typography variant="h4" component="h2" gutterBottom>
//             Why Choose SecureAI Guard?
//           </Typography>
//           <Typography variant="body1" gutterBottom>
//             Our team of experts is dedicated to providing the best security solutions for AI applications. We leverage the latest technologies and methodologies to ensure your AI systems are protected against evolving threats.
//           </Typography>
//           <Button variant="contained" color="primary" size="large" className={classes.button} href="/contact">
//             Contact Us
//           </Button>
//         </Box>
//       </Container>
//
//       <Modal
//         aria-labelledby="service-modal-title"
//         aria-describedby="service-modal-description"
//         className={classes.modal}
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//           className: classes.backdrop,
//         }}
//       >
//         <Fade in={open}>
//           <Box className={classes.modalPaper} onDoubleClick={handleClose}>
//             <IconButton onClick={handleClose} className={classes.closeButton}>
//               <CloseIcon />
//             </IconButton>
//             {selectedService && (
//               <>
//                 {selectedService.details}
//               </>
//             )}
//           </Box>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }
//
// export default Services;




// import React, { useState } from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Paper, Avatar, Button, Modal, Backdrop, Fade, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import SecurityIcon from '@mui/icons-material/Security';
// import SpeedIcon from '@mui/icons-material/Speed';
// import CloudIcon from '@mui/icons-material/Cloud';
// import UpdateIcon from '@mui/icons-material/Update';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//     paddingTop: theme.spacing(6), // Add top padding to create space between header and content
//   },
//   section: {
//     marginTop: theme.spacing(6),
//     marginBottom: theme.spacing(6),
//   },
//   paper: {
//     position: 'relative',
//     padding: theme.spacing(4),
//     textAlign: 'center',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
//     cursor: 'pointer',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: theme.shadows[10],
//       backgroundColor: theme.palette.primary.light,
//     },
//     '&:hover $overlay': {
//       opacity: 1,
//     },
//   },
//   avatar: {
//     width: theme.spacing(12),
//     height: theme.spacing(12),
//     margin: 'auto',
//     marginBottom: theme.spacing(2),
//     transition: 'transform 0.3s, color 0.3s',
//     '&:hover': {
//       transform: 'rotateY(180deg)',
//       color: theme.palette.secondary.main,
//     },
//   },
//   button: {
//     marginTop: theme.spacing(4),
//   },
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   modalPaper: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(4),
//     width: '90%',
//     height: '90%',
//     overflowY: 'auto',
//     outline: 'none',
//     position: 'relative',
//   },
//   closeButton: {
//     position: 'absolute',
//     top: theme.spacing(2),
//     right: theme.spacing(2),
//   },
//   backdrop: {
//     backdropFilter: 'blur(10px)',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   },
//   sectionTitle: {
//     marginBottom: theme.spacing(4),
//   },
//   overlay: {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     backgroundColor: 'rgba(0, 0, 0, 0.6)',
//     color: theme.palette.common.white,
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     opacity: 0,
//     transition: 'opacity 0.3s',
//     borderRadius: theme.shape.borderRadius,
//   },
//   overlayText: {
//     fontSize: '1.2rem',
//     fontWeight: 'bold',
//   },
// }));
//
// const services = [
//   {
//     icon: <SecurityIcon fontSize="large" />,
//     title: "Cutting-edge Threat Detection",
//     description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cutting-edge Threat Detection
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cutting-edge Threat Detection with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/threat-detection.jpg" alt="Threat Detection" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <SpeedIcon fontSize="large" />,
//     title: "Real-time Analysis",
//     description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Real-time Analysis
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Real-time Analysis with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/real-time-analysis.jpg" alt="Real-time Analysis" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <CloudIcon fontSize="large" />,
//     title: "Cloud-Native Compatibility",
//     description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cloud-Native Compatibility
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cloud-Native Compatibility with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/cloud-native.jpg" alt="Cloud-Native Compatibility" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <UpdateIcon fontSize="large" />,
//     title: "Continuous Updates",
//     description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Continuous Updates
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Continuous Updates with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/continuous-updates.jpg" alt="Continuous Updates" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <PrivacyTipIcon fontSize="large" />,
//     title: "Data Privacy Assurance",
//     description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Data Privacy Assurance
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Data Privacy Assurance with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/data-privacy.jpg" alt="Data Privacy Assurance" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   }
// ];
//
// function Services() {
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);
//   const [selectedService, setSelectedService] = useState(null);
//
//   const handleOpen = (service) => {
//     setSelectedService(service);
//     setOpen(true);
//   };
//
//   const handleClose = () => {
//     setOpen(false);
//     setSelectedService(null);
//   };
//
//   return (
//     <div className={classes.root}>
//       <Container maxWidth="lg">
//         <Box className={classes.section}>
//           <Typography variant="h2" component="h1" gutterBottom align="center">
//             Our Services
//           </Typography>
//           <Typography variant="h5" component="h2" gutterBottom align="center">
//             Comprehensive Security Solutions for AI Applications
//           </Typography>
//           <Typography variant="body1" align="center">
//             At SecureAI Guard, we offer a range of services designed to protect your AI applications from various threats. Our solutions are tailored to meet the unique needs of each client, ensuring the highest levels of security and compliance.
//           </Typography>
//         </Box>
//
//         <Grid container spacing={4}>
//           {services.map((service, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Paper className={classes.paper} elevation={3} onClick={() => handleOpen(service)}>
//                 <Avatar className={classes.avatar} sx={{ bgcolor: 'primary.main' }}>
//                   {service.icon}
//                 </Avatar>
//                 <Typography variant="h6" component="h3" gutterBottom>
//                   {service.title}
//                 </Typography>
//                 <Typography variant="body1">
//                   {service.description}
//                 </Typography>
//                 <Box className={classes.overlay}>
//                   <Typography className={classes.overlayText}>Click for more details</Typography>
//                 </Box>
//               </Paper>
//             </Grid>
//           ))}
//         </Grid>
//
//         <Box className={classes.section} textAlign="center">
//           <Typography variant="h4" component="h2" gutterBottom>
//             Why Choose SecureAI Guard?
//           </Typography>
//           <Typography variant="body1" gutterBottom>
//             Our team of experts is dedicated to providing the best security solutions for AI applications. We leverage the latest technologies and methodologies to ensure your AI systems are protected against evolving threats.
//           </Typography>
//           <Button variant="contained" color="primary" size="large" className={classes.button} href="/contact">
//             Contact Us
//           </Button>
//         </Box>
//       </Container>
//
//       <Modal
//         aria-labelledby="service-modal-title"
//         aria-describedby="service-modal-description"
//         className={classes.modal}
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//           className: classes.backdrop,
//         }}
//       >
//         <Fade in={open}>
//           <Box className={classes.modalPaper} onDoubleClick={handleClose}>
//             <IconButton onClick={handleClose} className={classes.closeButton}>
//               <CloseIcon />
//             </IconButton>
//             {selectedService && (
//               <>
//                 {selectedService.details}
//               </>
//             )}
//           </Box>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }
//
// export default Services;




// import React, { useState } from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Paper, Avatar, Button, Modal, Backdrop, Fade, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import SecurityIcon from '@mui/icons-material/Security';
// import SpeedIcon from '@mui/icons-material/Speed';
// import CloudIcon from '@mui/icons-material/Cloud';
// import UpdateIcon from '@mui/icons-material/Update';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//     paddingTop: theme.spacing(6), // Add top padding to create space between header and content
//   },
//   section: {
//     marginTop: theme.spacing(6),
//     marginBottom: theme.spacing(6),
//   },
//   paper: {
//     padding: theme.spacing(4),
//     textAlign: 'center',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: theme.shadows[10],
//       backgroundColor: theme.palette.primary.light,
//     },
//     cursor: 'pointer',
//   },
//   avatar: {
//     width: theme.spacing(12),
//     height: theme.spacing(12),
//     margin: 'auto',
//     marginBottom: theme.spacing(2),
//     transition: 'transform 0.3s, color 0.3s',
//     '&:hover': {
//       transform: 'rotateY(180deg)',
//       color: theme.palette.secondary.main,
//     },
//   },
//   button: {
//     marginTop: theme.spacing(4),
//   },
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   modalPaper: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(4),
//     width: '90%',
//     height: '90%',
//     overflowY: 'auto',
//     outline: 'none',
//     position: 'relative',
//   },
//   closeButton: {
//     position: 'absolute',
//     top: theme.spacing(2),
//     right: theme.spacing(2),
//   },
//   backdrop: {
//     backdropFilter: 'blur(10px)',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   },
//   sectionTitle: {
//     marginBottom: theme.spacing(4),
//   },
// }));
//
// const services = [
//   {
//     icon: <SecurityIcon fontSize="large" />,
//     title: "Cutting-edge Threat Detection",
//     description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cutting-edge Threat Detection
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cutting-edge Threat Detection with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/threat-detection.jpg" alt="Threat Detection" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <SpeedIcon fontSize="large" />,
//     title: "Real-time Analysis",
//     description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Real-time Analysis
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Real-time Analysis with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/real-time-analysis.jpg" alt="Real-time Analysis" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <CloudIcon fontSize="large" />,
//     title: "Cloud-Native Compatibility",
//     description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cloud-Native Compatibility
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cloud-Native Compatibility with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/cloud-native.jpg" alt="Cloud-Native Compatibility" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <UpdateIcon fontSize="large" />,
//     title: "Continuous Updates",
//     description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Continuous Updates
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Continuous Updates with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/continuous-updates.jpg" alt="Continuous Updates" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <PrivacyTipIcon fontSize="large" />,
//     title: "Data Privacy Assurance",
//     description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Data Privacy Assurance
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Data Privacy Assurance with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/data-privacy.jpg" alt="Data Privacy Assurance" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   }
// ];
//
// function Services() {
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);
//   const [selectedService, setSelectedService] = useState(null);
//
//   const handleOpen = (service) => {
//     setSelectedService(service);
//     setOpen(true);
//   };
//
//   const handleClose = () => {
//     setOpen(false);
//     setSelectedService(null);
//   };
//
//   return (
//     <div className={classes.root}>
//       <Container maxWidth="lg">
//         <Box className={classes.section}>
//           <Typography variant="h2" component="h1" gutterBottom align="center">
//             Our Services
//           </Typography>
//           <Typography variant="h5" component="h2" gutterBottom align="center">
//             Comprehensive Security Solutions for AI Applications
//           </Typography>
//           <Typography variant="body1" align="center">
//             At SecureAI Guard, we offer a range of services designed to protect your AI applications from various threats. Our solutions are tailored to meet the unique needs of each client, ensuring the highest levels of security and compliance.
//           </Typography>
//         </Box>
//
//         <Grid container spacing={4}>
//           {services.map((service, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Paper className={classes.paper} elevation={3} onClick={() => handleOpen(service)}>
//                 <Avatar className={classes.avatar} sx={{ bgcolor: 'primary.main' }}>
//                   {service.icon}
//                 </Avatar>
//                 <Typography variant="h6" component="h3" gutterBottom>
//                   {service.title}
//                 </Typography>
//                 <Typography variant="body1">
//                   {service.description}
//                 </Typography>
//               </Paper>
//             </Grid>
//           ))}
//         </Grid>
//
//         <Box className={classes.section} textAlign="center">
//           <Typography variant="h4" component="h2" gutterBottom>
//             Why Choose SecureAI Guard?
//           </Typography>
//           <Typography variant="body1" gutterBottom>
//             Our team of experts is dedicated to providing the best security solutions for AI applications. We leverage the latest technologies and methodologies to ensure your AI systems are protected against evolving threats.
//           </Typography>
//           <Button variant="contained" color="primary" size="large" className={classes.button} href="/contact">
//             Contact Us
//           </Button>
//         </Box>
//       </Container>
//
//       <Modal
//         aria-labelledby="service-modal-title"
//         aria-describedby="service-modal-description"
//         className={classes.modal}
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//           className: classes.backdrop,
//         }}
//       >
//         <Fade in={open}>
//           <Box className={classes.modalPaper} onDoubleClick={handleClose}>
//             <IconButton onClick={handleClose} className={classes.closeButton}>
//               <CloseIcon />
//             </IconButton>
//             {selectedService && (
//               <>
//                 {selectedService.details}
//               </>
//             )}
//           </Box>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }
//
// export default Services;






// import React, { useState } from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Paper, Avatar, Button, Modal, Backdrop, Fade, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import SecurityIcon from '@mui/icons-material/Security';
// import SpeedIcon from '@mui/icons-material/Speed';
// import CloudIcon from '@mui/icons-material/Cloud';
// import UpdateIcon from '@mui/icons-material/Update';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//     paddingTop: theme.spacing(6), // Add top padding to create space between header and content
//
//   },
//   section: {
//     marginTop: theme.spacing(6),
//     marginBottom: theme.spacing(6),
//   },
//   paper: {
//     padding: theme.spacing(4),
//     textAlign: 'center',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: theme.shadows[10],
//       backgroundColor: theme.palette.primary.light,
//     },
//     cursor: 'pointer',
//   },
//   avatar: {
//     width: theme.spacing(12),
//     height: theme.spacing(12),
//     margin: 'auto',
//     marginBottom: theme.spacing(2),
//     transition: 'transform 0.3s, color 0.3s',
//     '&:hover': {
//       transform: 'rotateY(180deg)',
//       color: theme.palette.secondary.main,
//     },
//   },
//   button: {
//     marginTop: theme.spacing(4),
//   },
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   modalPaper: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(4),
//     width: '90%',
//     height: '90%',
//     overflowY: 'auto',
//     outline: 'none',
//     position: 'relative',
//   },
//   closeButton: {
//     position: 'absolute',
//     top: theme.spacing(2),
//     right: theme.spacing(2),
//   },
//   backdrop: {
//     backdropFilter: 'blur(10px)',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   },
// }));
//
// const services = [
//   {
//     icon: <SecurityIcon fontSize="large" />,
//     title: "Cutting-edge Threat Detection",
//     description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cutting-edge Threat Detection
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cutting-edge Threat Detection with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/threat-detection.jpg" alt="Threat Detection" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <SpeedIcon fontSize="large" />,
//     title: "Real-time Analysis",
//     description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Real-time Analysis
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Real-time Analysis with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/real-time-analysis.jpg" alt="Real-time Analysis" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <CloudIcon fontSize="large" />,
//     title: "Cloud-Native Compatibility",
//     description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cloud-Native Compatibility
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cloud-Native Compatibility with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/cloud-native.jpg" alt="Cloud-Native Compatibility" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <UpdateIcon fontSize="large" />,
//     title: "Continuous Updates",
//     description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Continuous Updates
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Continuous Updates with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/continuous-updates.jpg" alt="Continuous Updates" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <PrivacyTipIcon fontSize="large" />,
//     title: "Data Privacy Assurance",
//     description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Data Privacy Assurance
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Data Privacy Assurance with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/data-privacy.jpg" alt="Data Privacy Assurance" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   }
// ];
//
// function Services() {
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);
//   const [selectedService, setSelectedService] = useState(null);
//
//   const handleOpen = (service) => {
//     setSelectedService(service);
//     setOpen(true);
//   };
//
//   const handleClose = () => {
//     setOpen(false);
//     setSelectedService(null);
//   };
//
//   return (
//     <div className={classes.root}>
//       <Container maxWidth="lg">
//         <Box className={classes.section}>
//           <Typography variant="h2" component="h1" gutterBottom align="center">
//             Our Services
//           </Typography>
//           <Typography variant="h5" component="h2" gutterBottom align="center">
//             Comprehensive Security Solutions for AI Applications
//           </Typography>
//           <Typography variant="body1" align="center">
//             At SecureAI Guard, we offer a range of services designed to protect your AI applications from various threats. Our solutions are tailored to meet the unique needs of each client, ensuring the highest levels of security and compliance.
//           </Typography>
//         </Box>
//
//         <Grid container spacing={4}>
//           {services.map((service, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Paper className={classes.paper} elevation={3} onClick={() => handleOpen(service)}>
//                 <Avatar className={classes.avatar} sx={{ bgcolor: 'primary.main' }}>
//                   {service.icon}
//                 </Avatar>
//                 <Typography variant="h6" component="h3" gutterBottom>
//                   {service.title}
//                 </Typography>
//                 <Typography variant="body1">
//                   {service.description}
//                 </Typography>
//               </Paper>
//             </Grid>
//           ))}
//         </Grid>
//
//         <Box className={classes.section} textAlign="center">
//           <Typography variant="h4" component="h2" gutterBottom>
//             Why Choose SecureAI Guard?
//           </Typography>
//           <Typography variant="body1" gutterBottom>
//             Our team of experts is dedicated to providing the best security solutions for AI applications. We leverage the latest technologies and methodologies to ensure your AI systems are protected against evolving threats.
//           </Typography>
//           <Button variant="contained" color="primary" size="large" className={classes.button} href="/contact">
//             Contact Us
//           </Button>
//         </Box>
//       </Container>
//
//       <Modal
//         aria-labelledby="service-modal-title"
//         aria-describedby="service-modal-description"
//         className={classes.modal}
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//           className: classes.backdrop,
//         }}
//       >
//         <Fade in={open}>
//           <Box className={classes.modalPaper} onDoubleClick={handleClose}>
//             <IconButton onClick={handleClose} className={classes.closeButton}>
//               <CloseIcon />
//             </IconButton>
//             {selectedService && (
//               <>
//                 {selectedService.details}
//               </>
//             )}
//           </Box>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }
//
// export default Services;



// import React, { useState } from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Paper, Avatar, Button, Modal, Backdrop, Fade, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import SecurityIcon from '@mui/icons-material/Security';
// import SpeedIcon from '@mui/icons-material/Speed';
// import CloudIcon from '@mui/icons-material/Cloud';
// import UpdateIcon from '@mui/icons-material/Update';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   section: {
//     marginTop: theme.spacing(6),
//     marginBottom: theme.spacing(6),
//   },
//   paper: {
//     padding: theme.spacing(4),
//     textAlign: 'center',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     transition: 'transform 0.3s, box-shadow 0.3s',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: theme.shadows[10],
//     },
//     cursor: 'pointer',
//   },
//   avatar: {
//     width: theme.spacing(12),
//     height: theme.spacing(12),
//     margin: 'auto',
//     marginBottom: theme.spacing(2),
//   },
//   button: {
//     marginTop: theme.spacing(4),
//   },
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   modalPaper: {
//     backgroundColor: theme.palette.background.paper,
//     borderRadius: theme.shape.borderRadius,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(4),
//     width: '90%',
//     height: '90%',
//     overflowY: 'auto',
//     outline: 'none',
//     position: 'relative',
//   },
//   closeButton: {
//     position: 'absolute',
//     top: theme.spacing(2),
//     right: theme.spacing(2),
//   },
//   backdrop: {
//     backdropFilter: 'blur(10px)',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   },
// }));
//
// const services = [
//   {
//     icon: <SecurityIcon fontSize="large" />,
//     title: "Cutting-edge Threat Detection",
//     description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cutting-edge Threat Detection
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cutting-edge Threat Detection with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/threat-detection.jpg" alt="Threat Detection" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <SpeedIcon fontSize="large" />,
//     title: "Real-time Analysis",
//     description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Real-time Analysis
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Real-time Analysis with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/real-time-analysis.jpg" alt="Real-time Analysis" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <CloudIcon fontSize="large" />,
//     title: "Cloud-Native Compatibility",
//     description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cloud-Native Compatibility
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cloud-Native Compatibility with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/cloud-native.jpg" alt="Cloud-Native Compatibility" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <UpdateIcon fontSize="large" />,
//     title: "Continuous Updates",
//     description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Continuous Updates
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Continuous Updates with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/continuous-updates.jpg" alt="Continuous Updates" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <PrivacyTipIcon fontSize="large" />,
//     title: "Data Privacy Assurance",
//     description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Data Privacy Assurance
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Data Privacy Assurance with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/data-privacy.jpg" alt="Data Privacy Assurance" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   }
// ];
//
// function Services() {
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);
//   const [selectedService, setSelectedService] = useState(null);
//
//   const handleOpen = (service) => {
//     setSelectedService(service);
//     setOpen(true);
//   };
//
//   const handleClose = () => {
//     setOpen(false);
//     setSelectedService(null);
//   };
//
//   return (
//     <div className={classes.root}>
//       <Container maxWidth="lg">
//         <Box className={classes.section}>
//           <Typography variant="h2" component="h1" gutterBottom align="center">
//             Our Services
//           </Typography>
//           <Typography variant="h5" component="h2" gutterBottom align="center">
//             Comprehensive Security Solutions for AI Applications
//           </Typography>
//           <Typography variant="body1" align="center">
//             At SecureAI Guard, we offer a range of services designed to protect your AI applications from various threats. Our solutions are tailored to meet the unique needs of each client, ensuring the highest levels of security and compliance.
//           </Typography>
//         </Box>
//
//         <Grid container spacing={4}>
//           {services.map((service, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Paper className={classes.paper} elevation={3} onClick={() => handleOpen(service)}>
//                 <Avatar className={classes.avatar} sx={{ bgcolor: 'primary.main' }}>
//                   {service.icon}
//                 </Avatar>
//                 <Typography variant="h6" component="h3" gutterBottom>
//                   {service.title}
//                 </Typography>
//                 <Typography variant="body1">
//                   {service.description}
//                 </Typography>
//               </Paper>
//             </Grid>
//           ))}
//         </Grid>
//
//         <Box className={classes.section} textAlign="center">
//           <Typography variant="h4" component="h2" gutterBottom>
//             Why Choose SecureAI Guard?
//           </Typography>
//           <Typography variant="body1" gutterBottom>
//             Our team of experts is dedicated to providing the best security solutions for AI applications. We leverage the latest technologies and methodologies to ensure your AI systems are protected against evolving threats.
//           </Typography>
//           <Button variant="contained" color="primary" size="large" className={classes.button} href="/contact">
//             Contact Us
//           </Button>
//         </Box>
//       </Container>
//
//       <Modal
//         aria-labelledby="service-modal-title"
//         aria-describedby="service-modal-description"
//         className={classes.modal}
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//           className: classes.backdrop,
//         }}
//       >
//         <Fade in={open}>
//           <Box className={classes.modalPaper}>
//             <IconButton onClick={handleClose} className={classes.closeButton}>
//               <CloseIcon />
//             </IconButton>
//             {selectedService && (
//               <>
//                 {selectedService.details}
//               </>
//             )}
//           </Box>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }
//
// export default Services;



// import React, { useState } from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Paper, Avatar, Button, Modal, Backdrop, Fade } from '@mui/material';
// import SecurityIcon from '@mui/icons-material/Security';
// import SpeedIcon from '@mui/icons-material/Speed';
// import CloudIcon from '@mui/icons-material/Cloud';
// import UpdateIcon from '@mui/icons-material/Update';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   section: {
//     marginTop: theme.spacing(6),
//     marginBottom: theme.spacing(6),
//   },
//   paper: {
//     padding: theme.spacing(4),
//     textAlign: 'center',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     transition: 'transform 0.3s, box-shadow 0.3s',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: theme.shadows[10],
//     },
//     cursor: 'pointer',
//   },
//   avatar: {
//     width: theme.spacing(12),
//     height: theme.spacing(12),
//     margin: 'auto',
//     marginBottom: theme.spacing(2),
//   },
//   button: {
//     marginTop: theme.spacing(4),
//   },
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   modalPaper: {
//     backgroundColor: theme.palette.background.paper,
//     border: 'none',
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(4),
//     width: '90%',
//     height: '90%',
//     overflowY: 'auto',
//     outline: 'none',
//   },
//   closeButton: {
//     position: 'absolute',
//     top: theme.spacing(2),
//     right: theme.spacing(2),
//   },
// }));
//
// const services = [
//   {
//     icon: <SecurityIcon fontSize="large" />,
//     title: "Cutting-edge Threat Detection",
//     description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cutting-edge Threat Detection
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cutting-edge Threat Detection with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/threat-detection.jpg" alt="Threat Detection" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <SpeedIcon fontSize="large" />,
//     title: "Real-time Analysis",
//     description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Real-time Analysis
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Real-time Analysis with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/real-time-analysis.jpg" alt="Real-time Analysis" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <CloudIcon fontSize="large" />,
//     title: "Cloud-Native Compatibility",
//     description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Cloud-Native Compatibility
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Cloud-Native Compatibility with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/cloud-native.jpg" alt="Cloud-Native Compatibility" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <UpdateIcon fontSize="large" />,
//     title: "Continuous Updates",
//     description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Continuous Updates
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Continuous Updates with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/continuous-updates.jpg" alt="Continuous Updates" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   },
//   {
//     icon: <PrivacyTipIcon fontSize="large" />,
//     title: "Data Privacy Assurance",
//     description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain.",
//     details: (
//       <>
//         <Typography variant="h4" gutterBottom>
//           Data Privacy Assurance
//         </Typography>
//         <Typography variant="body1" paragraph>
//           Detailed information about Data Privacy Assurance with examples and visual representation.
//         </Typography>
//         <img src="/assets/images/data-privacy.jpg" alt="Data Privacy Assurance" style={{ width: '100%', marginBottom: '20px' }} />
//         <Typography variant="body1" paragraph>
//           More detailed content, images, graphs, and explanations about the service.
//         </Typography>
//       </>
//     ),
//   }
// ];
//
// function Services() {
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);
//   const [selectedService, setSelectedService] = useState(null);
//
//   const handleOpen = (service) => {
//     setSelectedService(service);
//     setOpen(true);
//   };
//
//   const handleClose = () => {
//     setOpen(false);
//     setSelectedService(null);
//   };
//
//   return (
//     <div className={classes.root}>
//       <Container maxWidth="lg">
//         <Box className={classes.section}>
//           <Typography variant="h2" component="h1" gutterBottom align="center">
//             Our Services
//           </Typography>
//           <Typography variant="h5" component="h2" gutterBottom align="center">
//             Comprehensive Security Solutions for AI Applications
//           </Typography>
//           <Typography variant="body1" align="center">
//             At SecureAI Guard, we offer a range of services designed to protect your AI applications from various threats. Our solutions are tailored to meet the unique needs of each client, ensuring the highest levels of security and compliance.
//           </Typography>
//         </Box>
//
//         <Grid container spacing={4}>
//           {services.map((service, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Paper className={classes.paper} elevation={3} onClick={() => handleOpen(service)}>
//                 <Avatar className={classes.avatar} sx={{ bgcolor: 'primary.main' }}>
//                   {service.icon}
//                 </Avatar>
//                 <Typography variant="h6" component="h3" gutterBottom>
//                   {service.title}
//                 </Typography>
//                 <Typography variant="body1">
//                   {service.description}
//                 </Typography>
//               </Paper>
//             </Grid>
//           ))}
//         </Grid>
//
//         <Box className={classes.section} textAlign="center">
//           <Typography variant="h4" component="h2" gutterBottom>
//             Why Choose SecureAI Guard?
//           </Typography>
//           <Typography variant="body1" gutterBottom>
//             Our team of experts is dedicated to providing the best security solutions for AI applications. We leverage the latest technologies and methodologies to ensure your AI systems are protected against evolving threats.
//           </Typography>
//           <Button variant="contained" color="primary" size="large" className={classes.button} href="/contact">
//             Contact Us
//           </Button>
//         </Box>
//       </Container>
//
//       <Modal
//         aria-labelledby="service-modal-title"
//         aria-describedby="service-modal-description"
//         className={classes.modal}
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//         }}
//       >
//         <Fade in={open}>
//           <Box className={classes.modalPaper}>
//             <Button onClick={handleClose} className={classes.closeButton}>Close</Button>
//             {selectedService && (
//               <>
//                 {selectedService.details}
//               </>
//             )}
//           </Box>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }
//
// export default Services;




// import React, { useState } from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Paper, Avatar, Button, Modal, Backdrop, Fade } from '@mui/material';
// import SecurityIcon from '@mui/icons-material/Security';
// import SpeedIcon from '@mui/icons-material/Speed';
// import CloudIcon from '@mui/icons-material/Cloud';
// import UpdateIcon from '@mui/icons-material/Update';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   section: {
//     marginTop: theme.spacing(6),
//     marginBottom: theme.spacing(6),
//   },
//   paper: {
//     padding: theme.spacing(4),
//     textAlign: 'center',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     transition: 'transform 0.3s, box-shadow 0.3s',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: theme.shadows[10],
//     },
//     cursor: 'pointer',
//   },
//   avatar: {
//     width: theme.spacing(12),
//     height: theme.spacing(12),
//     margin: 'auto',
//     marginBottom: theme.spacing(2),
//   },
//   button: {
//     marginTop: theme.spacing(4),
//   },
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   modalPaper: {
//     backgroundColor: theme.palette.background.paper,
//     border: '2px solid #000',
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(4),
//     maxWidth: '80%',
//     maxHeight: '80%',
//     overflowY: 'auto',
//   },
// }));
//
// const services = [
//   {
//     icon: <SecurityIcon fontSize="large" />,
//     title: "Cutting-edge Threat Detection",
//     description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification.",
//     details: "Detailed information about Cutting-edge Threat Detection with examples and visual representation."
//   },
//   {
//     icon: <SpeedIcon fontSize="large" />,
//     title: "Real-time Analysis",
//     description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications.",
//     details: "Detailed information about Real-time Analysis with examples and visual representation."
//   },
//   {
//     icon: <CloudIcon fontSize="large" />,
//     title: "Cloud-Native Compatibility",
//     description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run.",
//     details: "Detailed information about Cloud-Native Compatibility with examples and visual representation."
//   },
//   {
//     icon: <UpdateIcon fontSize="large" />,
//     title: "Continuous Updates",
//     description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors.",
//     details: "Detailed information about Continuous Updates with examples and visual representation."
//   },
//   {
//     icon: <PrivacyTipIcon fontSize="large" />,
//     title: "Data Privacy Assurance",
//     description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain.",
//     details: "Detailed information about Data Privacy Assurance with examples and visual representation."
//   }
// ];
//
// function Services() {
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);
//   const [selectedService, setSelectedService] = useState(null);
//
//   const handleOpen = (service) => {
//     setSelectedService(service);
//     setOpen(true);
//   };
//
//   const handleClose = () => {
//     setOpen(false);
//     setSelectedService(null);
//   };
//
//   return (
//     <div className={classes.root}>
//       <Container maxWidth="lg">
//         <Box className={classes.section}>
//           <Typography variant="h2" component="h1" gutterBottom align="center">
//             Our Services
//           </Typography>
//           <Typography variant="h5" component="h2" gutterBottom align="center">
//             Comprehensive Security Solutions for AI Applications
//           </Typography>
//           <Typography variant="body1" align="center">
//             At SecureAI Guard, we offer a range of services designed to protect your AI applications from various threats. Our solutions are tailored to meet the unique needs of each client, ensuring the highest levels of security and compliance.
//           </Typography>
//         </Box>
//
//         <Grid container spacing={4}>
//           {services.map((service, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Paper className={classes.paper} elevation={3} onClick={() => handleOpen(service)}>
//                 <Avatar className={classes.avatar} sx={{ bgcolor: 'primary.main' }}>
//                   {service.icon}
//                 </Avatar>
//                 <Typography variant="h6" component="h3" gutterBottom>
//                   {service.title}
//                 </Typography>
//                 <Typography variant="body1">
//                   {service.description}
//                 </Typography>
//               </Paper>
//             </Grid>
//           ))}
//         </Grid>
//
//         <Box className={classes.section} textAlign="center">
//           <Typography variant="h4" component="h2" gutterBottom>
//             Why Choose SecureAI Guard?
//           </Typography>
//           <Typography variant="body1" gutterBottom>
//             Our team of experts is dedicated to providing the best security solutions for AI applications. We leverage the latest technologies and methodologies to ensure your AI systems are protected against evolving threats.
//           </Typography>
//           <Button variant="contained" color="primary" size="large" className={classes.button} href="/contact">
//             Contact Us
//           </Button>
//         </Box>
//       </Container>
//
//       <Modal
//         aria-labelledby="service-modal-title"
//         aria-describedby="service-modal-description"
//         className={classes.modal}
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//         }}
//       >
//         <Fade in={open}>
//           <Box className={classes.modalPaper}>
//             {selectedService && (
//               <>
//                 <Typography variant="h4" id="service-modal-title" gutterBottom>
//                   {selectedService.title}
//                 </Typography>
//                 <Typography variant="body1" id="service-modal-description">
//                   {selectedService.details}
//                 </Typography>
//               </>
//             )}
//           </Box>
//         </Fade>
//       </Modal>
//     </div>
//   );
// }
//
// export default Services;




// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Paper, Avatar, Button } from '@mui/material';
// import SecurityIcon from '@mui/icons-material/Security';
// import SpeedIcon from '@mui/icons-material/Speed';
// import CloudIcon from '@mui/icons-material/Cloud';
// import UpdateIcon from '@mui/icons-material/Update';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   section: {
//     marginTop: theme.spacing(6),
//     marginBottom: theme.spacing(6),
//   },
//   paper: {
//     padding: theme.spacing(4),
//     textAlign: 'center',
//     height: '100%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//   },
//   avatar: {
//     width: theme.spacing(12),
//     height: theme.spacing(12),
//     margin: 'auto',
//     marginBottom: theme.spacing(2),
//   },
//   button: {
//     marginTop: theme.spacing(4),
//   },
// }));
//
// const services = [
//   {
//     icon: <SecurityIcon fontSize="large" />,
//     title: "Cutting-edge Threat Detection",
//     description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification."
//   },
//   {
//     icon: <SpeedIcon fontSize="large" />,
//     title: "Real-time Analysis",
//     description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications."
//   },
//   {
//     icon: <CloudIcon fontSize="large" />,
//     title: "Cloud-Native Compatibility",
//     description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run."
//   },
//   {
//     icon: <UpdateIcon fontSize="large" />,
//     title: "Continuous Updates",
//     description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors."
//   },
//   {
//     icon: <PrivacyTipIcon fontSize="large" />,
//     title: "Data Privacy Assurance",
//     description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain."
//   }
// ];
//
// function Services() {
//   const classes = useStyles();
//
//   return (
//     <div className={classes.root}>
//       <Container maxWidth="lg">
//         <Box className={classes.section}>
//           <Typography variant="h2" component="h1" gutterBottom align="center">
//             Our Services
//           </Typography>
//           <Typography variant="h5" component="h2" gutterBottom align="center">
//             Comprehensive Security Solutions for AI Applications
//           </Typography>
//           <Typography variant="body1" align="center">
//             At SecureAI Guard, we offer a range of services designed to protect your AI applications from various threats. Our solutions are tailored to meet the unique needs of each client, ensuring the highest levels of security and compliance.
//           </Typography>
//         </Box>
//
//         <Grid container spacing={4}>
//           {services.map((service, index) => (
//             <Grid item xs={12} sm={6} md={4} key={index}>
//               <Paper className={classes.paper} elevation={3}>
//                 <Avatar className={classes.avatar} sx={{ bgcolor: 'primary.main' }}>
//                   {service.icon}
//                 </Avatar>
//                 <Typography variant="h6" component="h3" gutterBottom>
//                   {service.title}
//                 </Typography>
//                 <Typography variant="body1">
//                   {service.description}
//                 </Typography>
//               </Paper>
//             </Grid>
//           ))}
//         </Grid>
//
//         <Box className={classes.section} textAlign="center">
//           <Typography variant="h4" component="h2" gutterBottom>
//             Why Choose SecureAI Guard?
//           </Typography>
//           <Typography variant="body1" gutterBottom>
//             Our team of experts is dedicated to providing the best security solutions for AI applications. We leverage the latest technologies and methodologies to ensure your AI systems are protected against evolving threats.
//           </Typography>
//           <Button variant="contained" color="primary" size="large" className={classes.button} href="/contact">
//             Contact Us
//           </Button>
//         </Box>
//       </Container>
//     </div>
//   );
// }
//
// export default Services;
