import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Box, Paper, TextField, Button, Tabs, Tab, Avatar, Link } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(6),
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    maxWidth: '500px',
    width: '100%',
  },
  avatar: {
    margin: 'auto',
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(4),
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  switchMode: {
    marginTop: theme.spacing(2),
  },
}));

function SignIn() {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const [isSignUp, setIsSignUp] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const toggleSignUp = () => {
    setIsSignUp(!isSignUp);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Paper className={classes.paper} elevation={3}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant="h4" component="h1" gutterBottom>
            {isSignUp ? 'Sign Up' : 'Sign In'}
          </Typography>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            className={classes.tabs}
          >
            <Tab icon={<PersonIcon />} label="Customer" />
            <Tab icon={<BusinessIcon />} label="Staff" />
          </Tabs>
          {tabIndex === 0 && (
            <form className={classes.form} noValidate autoComplete="off">
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="customer-email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="customer-password"
                autoComplete="current-password"
              />
              {isSignUp && (
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="customer-name"
                    label="Full Name"
                    name="name"
                    autoComplete="name"
                  />
                  <Typography variant="body2" color="textSecondary" align="center">
                    Note: Your sign-up request will require approval. You will receive an email once your account is approved.
                  </Typography>
                </>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submitButton}
              >
                {isSignUp ? 'Sign Up as Customer' : 'Sign In as Customer'}
              </Button>
            </form>
          )}
          {tabIndex === 1 && (
            <form className={classes.form} noValidate autoComplete="off">
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="staff-email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="staff-password"
                autoComplete="current-password"
              />
              {isSignUp && (
                <>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="staff-name"
                    label="Full Name"
                    name="name"
                    autoComplete="name"
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="staff-id"
                    label="Staff ID"
                    name="staffId"
                    autoComplete="staff-id"
                  />
                  <Typography variant="body2" color="textSecondary" align="center">
                    Note: Your sign-up request will require approval. You will receive an email once your account is approved.
                  </Typography>
                </>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submitButton}
              >
                {isSignUp ? 'Sign Up as Staff' : 'Sign In as Staff'}
              </Button>
            </form>
          )}
          <Link href="#" variant="body2" onClick={toggleSignUp} className={classes.switchMode}>
            {isSignUp ? 'Already have an account? Sign In' : "Don't have an account? Sign Up"}
          </Link>
        </Paper>
      </Container>
    </div>
  );
}

export default SignIn;


// import React, { useState } from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Paper, TextField, Button, Tabs, Tab, Avatar } from '@mui/material';
// import PersonIcon from '@mui/icons-material/Person';
// import BusinessIcon from '@mui/icons-material/Business';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//     minHeight: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   paper: {
//     padding: theme.spacing(4),
//     textAlign: 'center',
//     maxWidth: '500px',
//     width: '100%',
//   },
//   avatar: {
//     margin: 'auto',
//     backgroundColor: theme.palette.primary.main,
//   },
//   form: {
//     marginTop: theme.spacing(2),
//   },
//   submitButton: {
//     marginTop: theme.spacing(4),
//   },
//   tabs: {
//     marginBottom: theme.spacing(2),
//   },
// }));
//
// function SignIn() {
//   const classes = useStyles();
//   const [tabIndex, setTabIndex] = useState(0);
//
//   const handleTabChange = (event, newValue) => {
//     setTabIndex(newValue);
//   };
//
//   return (
//     <div className={classes.root}>
//       <Container maxWidth="sm">
//         <Paper className={classes.paper} elevation={3}>
//           <Avatar className={classes.avatar}>
//             <LockOutlinedIcon />
//           </Avatar>
//           <Typography variant="h4" component="h1" gutterBottom>
//             Sign In
//           </Typography>
//           <Tabs
//             value={tabIndex}
//             onChange={handleTabChange}
//             indicatorColor="primary"
//             textColor="primary"
//             centered
//             className={classes.tabs}
//           >
//             <Tab icon={<PersonIcon />} label="Customer" />
//             <Tab icon={<BusinessIcon />} label="Staff" />
//           </Tabs>
//           {tabIndex === 0 && (
//             <form className={classes.form} noValidate autoComplete="off">
//               <TextField
//                 variant="outlined"
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="customer-email"
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//               />
//               <TextField
//                 variant="outlined"
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type="password"
//                 id="customer-password"
//                 autoComplete="current-password"
//               />
//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 color="primary"
//                 className={classes.submitButton}
//               >
//                 Sign In as Customer
//               </Button>
//             </form>
//           )}
//           {tabIndex === 1 && (
//             <form className={classes.form} noValidate autoComplete="off">
//               <TextField
//                 variant="outlined"
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="staff-email"
//                 label="Email Address"
//                 name="email"
//                 autoComplete="email"
//                 autoFocus
//               />
//               <TextField
//                 variant="outlined"
//                 margin="normal"
//                 required
//                 fullWidth
//                 name="password"
//                 label="Password"
//                 type="password"
//                 id="staff-password"
//                 autoComplete="current-password"
//               />
//               <TextField
//                 variant="outlined"
//                 margin="normal"
//                 required
//                 fullWidth
//                 id="staff-id"
//                 label="Staff ID"
//                 name="staffId"
//                 autoComplete="staff-id"
//               />
//               <Button
//                 type="submit"
//                 fullWidth
//                 variant="contained"
//                 color="primary"
//                 className={classes.submitButton}
//               >
//                 Sign In as Staff
//               </Button>
//             </form>
//           )}
//         </Paper>
//       </Container>
//     </div>
//   );
// }
//
// export default SignIn;
