import React from 'react';
import { Container, Typography, Box, Grid, Paper, Button, Card, CardContent, CardActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AwsIcon from '../../public/assets/images/aws-svgrepo-com.svg'; // Replace with actual AWS icon
import AzureIcon from '../../public/assets/images/azure-svgrepo-com.svg'; // Replace with actual Azure icon
import GoogleCloudIcon from '../../public/assets/images/google-cloud-svgrepo-com.svg'; // Replace with actual Google Cloud icon

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(8), // Add top padding to create space between header and content
  },
  paper: {
    padding: theme.spacing(4),
  },
  card: {
    transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: theme.shadows[6],
      backgroundColor: theme.palette.action.hover,
    },
  },
  icon: {
    width: 50,
    height: 50,
    marginBottom: theme.spacing(2),
    transition: 'transform 0.3s',
  },
  awsIcon: {
    color: '#FF9900', // AWS color
    '&:hover': {
      transform: 'rotateY(180deg)',
    },
  },
  azureIcon: {
    color: '#0078D4', // Azure color
    '&:hover': {
      transform: 'rotateY(180deg)',
    },
  },
  googleCloudIcon: {
    color: '#4285F4', // Google Cloud color
    '&:hover': {
      transform: 'rotateY(180deg)',
    },
  },
  button: {
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.white,
    },
  },
}));

function Marketplace() {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Box my={4}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Marketplace Deployment
          </Typography>
          <Typography variant="body1" gutterBottom align="center">
            SecureAI Guard is available on all major cloud platform marketplaces. Deploy our solution seamlessly on your preferred cloud platform.
          </Typography>
          <Box mt={4}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardContent>
                    <img src={AwsIcon} alt="AWS" className={`${classes.icon} ${classes.awsIcon}`} />
                    <Typography variant="h5" component="div">
                      AWS Marketplace
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Deploy SecureAI Guard on AWS with ease. Click the button below to get started.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary" href="https://aws.amazon.com/marketplace" className={classes.button}>
                      Go to AWS Marketplace
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardContent>
                    <img src={AzureIcon} alt="Azure" className={`${classes.icon} ${classes.azureIcon}`} />
                    <Typography variant="h5" component="div">
                      Azure Marketplace
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Deploy SecureAI Guard on Azure with ease. Click the button below to get started.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary" href="https://azuremarketplace.microsoft.com" className={classes.button}>
                      Go to Azure Marketplace
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardContent>
                    <img src={GoogleCloudIcon} alt="Google Cloud" className={`${classes.icon} ${classes.googleCloudIcon}`} />
                    <Typography variant="h5" component="div">
                      Google Cloud Marketplace
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Deploy SecureAI Guard on Google Cloud with ease. Click the button below to get started.
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" color="primary" href="https://cloud.google.com/marketplace" className={classes.button}>
                      Go to Google Cloud Marketplace
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}

export default Marketplace;





// import React from 'react';
// import { Container, Typography, Box, Grid, Paper, Button, Card, CardContent, CardActions } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import AwsIcon from '../../public/assets/images/aws-svgrepo-com.svg'; // Replace with actual AWS icon
// import AzureIcon from '../../public/assets/images/azure-svgrepo-com.svg'; // Replace with actual Azure icon
// import GoogleCloudIcon from '../../public/assets/images/google-cloud-svgrepo-com.svg'; // Replace with actual Google Cloud icon
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: 'flex',
//     flexDirection: 'column',
//     minHeight: '100vh',
//   },
//   mainContent: {
//     flexGrow: 1,
//   },
//   paper: {
//     padding: theme.spacing(4),
//   },
//   card: {
//     transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: theme.shadows[6],
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
//   icon: {
//     width: 50,
//     height: 50,
//     marginBottom: theme.spacing(2),
//     transition: 'transform 0.3s',
//   },
//   awsIcon: {
//     color: '#FF9900', // AWS color
//     '&:hover': {
//       transform: 'rotateY(180deg)',
//     },
//   },
//   azureIcon: {
//     color: '#0078D4', // Azure color
//     '&:hover': {
//       transform: 'rotateY(180deg)',
//     },
//   },
//   googleCloudIcon: {
//     color: '#4285F4', // Google Cloud color
//     '&:hover': {
//       transform: 'rotateY(180deg)',
//     },
//   },
//   button: {
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//       color: theme.palette.common.white,
//     },
//   },
// }));
//
// function Marketplace() {
//   const classes = useStyles();
//
//   return (
//     <div className={classes.root}>
//       <Container maxWidth="lg" className={classes.mainContent}>
//         <Box my={4}>
//           <Paper elevation={3} className={classes.paper}>
//             <Typography variant="h4" component="h1" gutterBottom align="center">
//               Marketplace Deployment
//             </Typography>
//             <Typography variant="body1" gutterBottom align="center">
//               SecureAI Guard is available on all major cloud platform marketplaces. Deploy our solution seamlessly on your preferred cloud platform.
//             </Typography>
//             <Box mt={4}>
//               <Grid container spacing={4}>
//                 <Grid item xs={12} sm={6} md={4}>
//                   <Card className={classes.card}>
//                     <CardContent>
//                       <img src={AwsIcon} alt="AWS" className={`${classes.icon} ${classes.awsIcon}`} />
//                       <Typography variant="h5" component="div">
//                         AWS Marketplace
//                       </Typography>
//                       <Typography variant="body2" color="text.secondary">
//                         Deploy SecureAI Guard on AWS with ease. Click the button below to get started.
//                       </Typography>
//                     </CardContent>
//                     <CardActions>
//                       <Button size="small" color="primary" href="https://aws.amazon.com/marketplace" className={classes.button}>
//                         Go to AWS Marketplace
//                       </Button>
//                     </CardActions>
//                   </Card>
//                 </Grid>
//                 <Grid item xs={12} sm={6} md={4}>
//                   <Card className={classes.card}>
//                     <CardContent>
//                       <img src={AzureIcon} alt="Azure" className={`${classes.icon} ${classes.azureIcon}`} />
//                       <Typography variant="h5" component="div">
//                         Azure Marketplace
//                       </Typography>
//                       <Typography variant="body2" color="text.secondary">
//                         Deploy SecureAI Guard on Azure with ease. Click the button below to get started.
//                       </Typography>
//                     </CardContent>
//                     <CardActions>
//                       <Button size="small" color="primary" href="https://azuremarketplace.microsoft.com" className={classes.button}>
//                         Go to Azure Marketplace
//                       </Button>
//                     </CardActions>
//                   </Card>
//                 </Grid>
//                 <Grid item xs={12} sm={6} md={4}>
//                   <Card className={classes.card}>
//                     <CardContent>
//                       <img src={GoogleCloudIcon} alt="Google Cloud" className={`${classes.icon} ${classes.googleCloudIcon}`} />
//                       <Typography variant="h5" component="div">
//                         Google Cloud Marketplace
//                       </Typography>
//                       <Typography variant="body2" color="text.secondary">
//                         Deploy SecureAI Guard on Google Cloud with ease. Click the button below to get started.
//                       </Typography>
//                     </CardContent>
//                     <CardActions>
//                       <Button size="small" color="primary" href="https://cloud.google.com/marketplace" className={classes.button}>
//                         Go to Google Cloud Marketplace
//                       </Button>
//                     </CardActions>
//                   </Card>
//                 </Grid>
//               </Grid>
//             </Box>
//           </Paper>
//         </Box>
//       </Container>
//     </div>
//   );
// }
//
// export default Marketplace;




// import React from 'react';
// import { Container, Typography, Box, Grid, Paper, Button, Card, CardContent, CardActions } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import AwsIcon from '../../public/assets/images/aws-svgrepo-com.svg'; // Replace with actual AWS icon
// import AzureIcon from '../../public/assets/images/azure-svgrepo-com.svg'; // Replace with actual Azure icon
// import GoogleCloudIcon from '../../public/assets/images/google-cloud-svgrepo-com.svg'; // Replace with actual Google Cloud icon
//
// const useStyles = makeStyles((theme) => ({
//   paper: {
//     padding: theme.spacing(4),
//   },
//   card: {
//     transition: 'transform 0.3s, box-shadow 0.3s, background-color 0.3s',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: theme.shadows[6],
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
//   icon: {
//     width: 50,
//     height: 50,
//     marginBottom: theme.spacing(2),
//     transition: 'transform 0.3s',
//   },
//   awsIcon: {
//     color: '#FF9900', // AWS color
//     '&:hover': {
//       transform: 'rotateY(180deg)',
//     },
//   },
//   azureIcon: {
//     color: '#0078D4', // Azure color
//     '&:hover': {
//       transform: 'rotateY(180deg)',
//     },
//   },
//   googleCloudIcon: {
//     color: '#4285F4', // Google Cloud color
//     '&:hover': {
//       transform: 'rotateY(180deg)',
//     },
//   },
//   button: {
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//       color: theme.palette.common.white,
//     },
//   },
// }));
//
// function Marketplace() {
//   const classes = useStyles();
//
//   return (
//     <Container maxWidth="lg">
//       <Box my={4}>
//         <Paper elevation={3} className={classes.paper}>
//           <Typography variant="h4" component="h1" gutterBottom align="center">
//             Marketplace Deployment
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             SecureAI Guard is available on all major cloud platform marketplaces. Deploy our solution seamlessly on your preferred cloud platform.
//           </Typography>
//           <Box mt={4}>
//             <Grid container spacing={4}>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Card className={classes.card}>
//                   <CardContent>
//                     <img src={AwsIcon} alt="AWS" className={`${classes.icon} ${classes.awsIcon}`} />
//                     <Typography variant="h5" component="div">
//                       AWS Marketplace
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Deploy SecureAI Guard on AWS with ease. Click the button below to get started.
//                     </Typography>
//                   </CardContent>
//                   <CardActions>
//                     <Button size="small" color="primary" href="https://aws.amazon.com/marketplace" className={classes.button}>
//                       Go to AWS Marketplace
//                     </Button>
//                   </CardActions>
//                 </Card>
//               </Grid>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Card className={classes.card}>
//                   <CardContent>
//                     <img src={AzureIcon} alt="Azure" className={`${classes.icon} ${classes.azureIcon}`} />
//                     <Typography variant="h5" component="div">
//                       Azure Marketplace
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Deploy SecureAI Guard on Azure with ease. Click the button below to get started.
//                     </Typography>
//                   </CardContent>
//                   <CardActions>
//                     <Button size="small" color="primary" href="https://azuremarketplace.microsoft.com" className={classes.button}>
//                       Go to Azure Marketplace
//                     </Button>
//                   </CardActions>
//                 </Card>
//               </Grid>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Card className={classes.card}>
//                   <CardContent>
//                     <img src={GoogleCloudIcon} alt="Google Cloud" className={`${classes.icon} ${classes.googleCloudIcon}`} />
//                     <Typography variant="h5" component="div">
//                       Google Cloud Marketplace
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Deploy SecureAI Guard on Google Cloud with ease. Click the button below to get started.
//                     </Typography>
//                   </CardContent>
//                   <CardActions>
//                     <Button size="small" color="primary" href="https://cloud.google.com/marketplace" className={classes.button}>
//                       Go to Google Cloud Marketplace
//                     </Button>
//                   </CardActions>
//                 </Card>
//               </Grid>
//             </Grid>
//           </Box>
//         </Paper>
//       </Box>
//     </Container>
//   );
// }
//
// export default Marketplace;





// import React from 'react';
// import { Container, Typography, Box, Grid, Paper, Button, Card, CardContent, CardActions } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import AwsIcon from '../../public/assets/images/aws-svgrepo-com.svg'; // Replace with actual AWS icon
// import AzureIcon from '../../public/assets/images/azure-svgrepo-com.svg'; // Replace with actual Azure icon
// import GoogleCloudIcon from '../../public/assets/images/google-cloud-svgrepo-com.svg'; // Replace with actual Google Cloud icon
//
// const useStyles = makeStyles((theme) => ({
//   paper: {
//     padding: theme.spacing(4),
//     transition: 'background-color 0.3s',
//     '&:hover': {
//       backgroundColor: theme.palette.action.hover,
//     },
//   },
//   card: {
//     transition: 'transform 0.3s, box-shadow 0.3s',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: theme.shadows[6],
//     },
//   },
//   icon: {
//     width: 50,
//     height: 50,
//     marginBottom: theme.spacing(2),
//   },
//   awsIcon: {
//     color: '#FF9900', // AWS color
//   },
//   azureIcon: {
//     color: '#0078D4', // Azure color
//   },
//   googleCloudIcon: {
//     color: '#4285F4', // Google Cloud color
//   },
//   button: {
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//       color: theme.palette.common.white,
//     },
//   },
// }));
//
// function Marketplace() {
//   const classes = useStyles();
//
//   return (
//     <Container maxWidth="lg">
//       <Box my={4}>
//         <Paper elevation={3} className={classes.paper}>
//           <Typography variant="h4" component="h1" gutterBottom align="center">
//             Marketplace Deployment
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             SecureAI Guard is available on all major cloud platform marketplaces. Deploy our solution seamlessly on your preferred cloud platform.
//           </Typography>
//           <Box mt={4}>
//             <Grid container spacing={4}>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Card className={classes.card}>
//                   <CardContent>
//                     <img src={AwsIcon} alt="AWS" className={`${classes.icon} ${classes.awsIcon}`} />
//                     <Typography variant="h5" component="div">
//                       AWS Marketplace
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Deploy SecureAI Guard on AWS with ease. Click the button below to get started.
//                     </Typography>
//                   </CardContent>
//                   <CardActions>
//                     <Button size="small" color="primary" href="https://aws.amazon.com/marketplace" className={classes.button}>
//                       Go to AWS Marketplace
//                     </Button>
//                   </CardActions>
//                 </Card>
//               </Grid>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Card className={classes.card}>
//                   <CardContent>
//                     <img src={AzureIcon} alt="Azure" className={`${classes.icon} ${classes.azureIcon}`} />
//                     <Typography variant="h5" component="div">
//                       Azure Marketplace
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Deploy SecureAI Guard on Azure with ease. Click the button below to get started.
//                     </Typography>
//                   </CardContent>
//                   <CardActions>
//                     <Button size="small" color="primary" href="https://azuremarketplace.microsoft.com" className={classes.button}>
//                       Go to Azure Marketplace
//                     </Button>
//                   </CardActions>
//                 </Card>
//               </Grid>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Card className={classes.card}>
//                   <CardContent>
//                     <img src={GoogleCloudIcon} alt="Google Cloud" className={`${classes.icon} ${classes.googleCloudIcon}`} />
//                     <Typography variant="h5" component="div">
//                       Google Cloud Marketplace
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Deploy SecureAI Guard on Google Cloud with ease. Click the button below to get started.
//                     </Typography>
//                   </CardContent>
//                   <CardActions>
//                     <Button size="small" color="primary" href="https://cloud.google.com/marketplace" className={classes.button}>
//                       Go to Google Cloud Marketplace
//                     </Button>
//                   </CardActions>
//                 </Card>
//               </Grid>
//             </Grid>
//           </Box>
//         </Paper>
//       </Box>
//     </Container>
//   );
// }
//
// export default Marketplace;



// import React from 'react';
// import { Container, Typography, Box, Grid, Paper, Button, Card, CardContent, CardActions } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import AwsIcon from '@mui/icons-material/CloudQueue'; // Placeholder icon, replace with actual AWS icon
// import AzureIcon from '@mui/icons-material/Cloud'; // Placeholder icon, replace with actual Azure icon
// import GoogleCloudIcon from '@mui/icons-material/CloudCircle'; // Placeholder icon, replace with actual Google Cloud icon
//
// const useStyles = makeStyles((theme) => ({
//   card: {
//     transition: 'transform 0.3s, box-shadow 0.3s',
//     '&:hover': {
//       transform: 'scale(1.05)',
//       boxShadow: theme.shadows[6],
//     },
//   },
//   icon: {
//     fontSize: 50,
//     marginBottom: theme.spacing(2),
//     color: theme.palette.primary.main,
//   },
//   button: {
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//       color: theme.palette.common.white,
//     },
//   },
// }));
//
// function Marketplace() {
//   const classes = useStyles();
//
//   return (
//     <Container maxWidth="lg">
//       <Box my={4}>
//         <Paper elevation={3} sx={{ padding: 4 }}>
//           <Typography variant="h4" component="h1" gutterBottom align="center">
//             Marketplace Deployment
//           </Typography>
//           <Typography variant="body1" gutterBottom align="center">
//             SecureAI Guard is available on all major cloud platform marketplaces. Deploy our solution seamlessly on your preferred cloud platform.
//           </Typography>
//           <Box mt={4}>
//             <Grid container spacing={4}>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Card className={classes.card}>
//                   <CardContent>
//                     <AwsIcon className={classes.icon} />
//                     <Typography variant="h5" component="div">
//                       AWS Marketplace
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Deploy SecureAI Guard on AWS with ease. Click the button below to get started.
//                     </Typography>
//                   </CardContent>
//                   <CardActions>
//                     <Button size="small" color="primary" href="https://aws.amazon.com/marketplace" className={classes.button}>
//                       Go to AWS Marketplace
//                     </Button>
//                   </CardActions>
//                 </Card>
//               </Grid>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Card className={classes.card}>
//                   <CardContent>
//                     <AzureIcon className={classes.icon} />
//                     <Typography variant="h5" component="div">
//                       Azure Marketplace
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Deploy SecureAI Guard on Azure with ease. Click the button below to get started.
//                     </Typography>
//                   </CardContent>
//                   <CardActions>
//                     <Button size="small" color="primary" href="https://azuremarketplace.microsoft.com" className={classes.button}>
//                       Go to Azure Marketplace
//                     </Button>
//                   </CardActions>
//                 </Card>
//               </Grid>
//               <Grid item xs={12} sm={6} md={4}>
//                 <Card className={classes.card}>
//                   <CardContent>
//                     <GoogleCloudIcon className={classes.icon} />
//                     <Typography variant="h5" component="div">
//                       Google Cloud Marketplace
//                     </Typography>
//                     <Typography variant="body2" color="text.secondary">
//                       Deploy SecureAI Guard on Google Cloud with ease. Click the button below to get started.
//                     </Typography>
//                   </CardContent>
//                   <CardActions>
//                     <Button size="small" color="primary" href="https://cloud.google.com/marketplace" className={classes.button}>
//                       Go to Google Cloud Marketplace
//                     </Button>
//                   </CardActions>
//                 </Card>
//               </Grid>
//             </Grid>
//           </Box>
//         </Paper>
//       </Box>
//     </Container>
//   );
// }
//
// export default Marketplace;




// import React from 'react';
// import { Container, Typography, Box, Grid, Paper, Button, Card, CardContent, CardActions } from '@mui/material';
//
// function Marketplace() {
//     return (
//         <Container maxWidth="lg">
//             <Box my={4}>
//                 <Paper elevation={3} sx={{ padding: 4 }}>
//                     <Typography variant="h4" component="h1" gutterBottom align="center">
//                         Marketplace Deployment
//                     </Typography>
//                     <Typography variant="body1" gutterBottom align="center">
//                         SecureAI Guard is available on all major cloud platform marketplaces. Deploy our solution seamlessly on your preferred cloud platform.
//                     </Typography>
//                     <Box mt={4}>
//                         <Grid container spacing={4}>
//                             <Grid item xs={12} sm={6} md={4}>
//                                 <Card>
//                                     <CardContent>
//                                         <Typography variant="h5" component="div">
//                                             AWS Marketplace
//                                         </Typography>
//                                         <Typography variant="body2" color="text.secondary">
//                                             Deploy SecureAI Guard on AWS with ease. Click the button below to get started.
//                                         </Typography>
//                                     </CardContent>
//                                     <CardActions>
//                                         <Button size="small" color="primary" href="https://aws.amazon.com/marketplace">
//                                             Go to AWS Marketplace
//                                         </Button>
//                                     </CardActions>
//                                 </Card>
//                             </Grid>
//                             <Grid item xs={12} sm={6} md={4}>
//                                 <Card>
//                                     <CardContent>
//                                         <Typography variant="h5" component="div">
//                                             Azure Marketplace
//                                         </Typography>
//                                         <Typography variant="body2" color="text.secondary">
//                                             Deploy SecureAI Guard on Azure with ease. Click the button below to get started.
//                                         </Typography>
//                                     </CardContent>
//                                     <CardActions>
//                                         <Button size="small" color="primary" href="https://azuremarketplace.microsoft.com">
//                                             Go to Azure Marketplace
//                                         </Button>
//                                     </CardActions>
//                                 </Card>
//                             </Grid>
//                             <Grid item xs={12} sm={6} md={4}>
//                                 <Card>
//                                     <CardContent>
//                                         <Typography variant="h5" component="div">
//                                             Google Cloud Marketplace
//                                         </Typography>
//                                         <Typography variant="body2" color="text.secondary">
//                                             Deploy SecureAI Guard on Google Cloud with ease. Click the button below to get started.
//                                         </Typography>
//                                     </CardContent>
//                                     <CardActions>
//                                         <Button size="small" color="primary" href="https://cloud.google.com/marketplace">
//                                             Go to Google Cloud Marketplace
//                                         </Button>
//                                     </CardActions>
//                                 </Card>
//                             </Grid>
//                         </Grid>
//                     </Box>
//                 </Paper>
//             </Box>
//         </Container>
//     );
// }
//
// export default Marketplace;
