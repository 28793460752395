import React, { useState } from 'react';
import { Box, Container, Grid, Typography, Paper, Avatar, Tooltip, IconButton, Fade } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import CloudIcon from '@mui/icons-material/Cloud';
import UpdateIcon from '@mui/icons-material/Update';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import PolicyIcon from '@mui/icons-material/Policy';
import InsightsIcon from '@mui/icons-material/Insights';
import BugReportIcon from '@mui/icons-material/BugReport';
import DashboardIcon from '@mui/icons-material/Dashboard';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ShieldIcon from '@mui/icons-material/Shield';
import LockIcon from '@mui/icons-material/Lock';

const features = [
  {
    icon: <SecurityIcon fontSize="large" />,
    title: "Cutting-edge Threat Detection",
    description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification."
  },
  {
    icon: <SpeedIcon fontSize="large" />,
    title: "Real-time Analysis",
    description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications."
  },
  {
    icon: <CloudIcon fontSize="large" />,
    title: "Cloud-Native Compatibility",
    description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run."
  },
  {
    icon: <UpdateIcon fontSize="large" />,
    title: "Continuous Updates",
    description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors."
  },
  {
    icon: <PrivacyTipIcon fontSize="large" />,
    title: "Data Privacy Assurance",
    description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain."
  },
  {
    icon: <VerifiedUserIcon fontSize="large" />,
    title: "Adaptive Risk Scoring",
    description: "Dynamically adjusts risk scores based on user behavior, context, and content sensitivity, providing a tailored security posture for each interaction."
  },
  {
    icon: <ShieldIcon fontSize="large" />,
    title: "Intelligent Content Disarm & Reconstruction",
    description: "Processes incoming files and messages to remove potential threats, delivering sanitized content without disrupting business continuity."
  },
  {
    icon: <LockIcon fontSize="large" />,
    title: "Permission-Based Content Access",
    description: "Provides permission-based content access to users on LLM and vector databases, ensuring they only access data they are authorized to view."
  }
];

const futureFeatures = [
  {
    icon: <AutoGraphIcon fontSize="large" />,
    title: "Automated Threat Intelligence Feed",
    description: "Integration with global threat intelligence to automatically update security measures."
  },
  {
    icon: <PolicyIcon fontSize="large" />,
    title: "Custom Policy Engine",
    description: "Ability to create and enforce custom security policies based on enterprise-specific requirements."
  },
  {
    icon: <InsightsIcon fontSize="large" />,
    title: "Explainable AI Security Insights",
    description: "Detailed explanations of security decisions to provide clarity and build trust among stakeholders."
  },
  // {
  //   icon: <BugReportIcon fontSize="large" />,
  //   title: "Anomaly Detection with AI",
  //   description: "Advanced machine learning models to detect and respond to anomalous behavior indicative of new threats."
  // },
  {
    icon: <DashboardIcon fontSize="large" />,
    title: "Compliance Reporting Dashboard",
    description: "Interactive dashboards to monitor compliance with regulatory standards and internal policies."
  }
];

function FeaturesAndFutureFeatures() {
  const [hoveredFeature, setHoveredFeature] = useState(null);

  const allFeatures = [...features, ...futureFeatures];

  return (
    <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
      <Container maxWidth="lg">
        <Typography variant="h4" component="h2" gutterBottom align="center">
          Key Features
        </Typography>
        <Grid container spacing={4}>
          {allFeatures.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Paper elevation={3} sx={{ p: 3, textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Tooltip title={feature.description} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
                  <IconButton
                    onMouseEnter={() => setHoveredFeature(index)}
                    onMouseLeave={() => setHoveredFeature(null)}
                  >
                    <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
                      {feature.icon}
                    </Avatar>
                  </IconButton>
                </Tooltip>
                <Typography variant="h6" component="h3" gutterBottom>
                  {feature.title}
                </Typography>
                {hoveredFeature === index && (
                  <Fade in={true} timeout={600}>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      {feature.description}
                    </Typography>
                  </Fade>
                )}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default FeaturesAndFutureFeatures;






// import React, { useState } from 'react';
// import { Box, Container, Grid, Typography, Paper, Avatar, Tooltip, IconButton, Fade } from '@mui/material';
// import SecurityIcon from '@mui/icons-material/Security';
// import SpeedIcon from '@mui/icons-material/Speed';
// import CloudIcon from '@mui/icons-material/Cloud';
// import UpdateIcon from '@mui/icons-material/Update';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
// import AutoGraphIcon from '@mui/icons-material/AutoGraph';
// import PolicyIcon from '@mui/icons-material/Policy';
// import InsightsIcon from '@mui/icons-material/Insights';
// import BugReportIcon from '@mui/icons-material/BugReport';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
// import ShieldIcon from '@mui/icons-material/Shield';
//
// const features = [
//     {
//         icon: <SecurityIcon fontSize="large" />,
//         title: "Cutting-edge Threat Detection",
//         description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification."
//     },
//     {
//         icon: <SpeedIcon fontSize="large" />,
//         title: "Real-time Analysis",
//         description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications."
//     },
//     {
//         icon: <CloudIcon fontSize="large" />,
//         title: "Cloud-Native Compatibility",
//         description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run."
//     },
//     {
//         icon: <UpdateIcon fontSize="large" />,
//         title: "Continuous Updates",
//         description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors."
//     },
//     {
//         icon: <PrivacyTipIcon fontSize="large" />,
//         title: "Data Privacy Assurance",
//         description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain."
//     },
//     {
//         icon: <VerifiedUserIcon fontSize="large" />,
//         title: "Adaptive Risk Scoring",
//         description: "Dynamically adjusts risk scores based on user behavior, context, and content sensitivity, providing a tailored security posture for each interaction."
//     },
//     {
//         icon: <ShieldIcon fontSize="large" />,
//         title: "Intelligent Content Disarm & Reconstruction",
//         description: "Processes incoming files and messages to remove potential threats, delivering sanitized content without disrupting business continuity."
//     }
// ];
//
// const futureFeatures = [
//     {
//         icon: <AutoGraphIcon fontSize="large" />,
//         title: "Automated Threat Intelligence Feed",
//         description: "Integration with global threat intelligence to automatically update security measures."
//     },
//     {
//         icon: <PolicyIcon fontSize="large" />,
//         title: "Custom Policy Engine",
//         description: "Ability to create and enforce custom security policies based on enterprise-specific requirements."
//     },
//     {
//         icon: <InsightsIcon fontSize="large" />,
//         title: "Explainable AI Security Insights",
//         description: "Detailed explanations of security decisions to provide clarity and build trust among stakeholders."
//     },
//     {
//         icon: <BugReportIcon fontSize="large" />,
//         title: "Anomaly Detection with AI",
//         description: "Advanced machine learning models to detect and respond to anomalous behavior indicative of new threats."
//     },
//     {
//         icon: <DashboardIcon fontSize="large" />,
//         title: "Compliance Reporting Dashboard",
//         description: "Interactive dashboards to monitor compliance with regulatory standards and internal policies."
//     }
// ];
//
// function FeaturesAndFutureFeatures() {
//     const [hoveredFeature, setHoveredFeature] = useState(null);
//
//     const allFeatures = [...features, ...futureFeatures];
//
//     return (
//         <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
//             <Container maxWidth="lg">
//                 <Typography variant="h4" component="h2" gutterBottom align="center">
//                     Key Features
//                 </Typography>
//                 <Grid container spacing={4}>
//                     {allFeatures.map((feature, index) => (
//                         <Grid item xs={12} sm={6} md={4} key={index}>
//                             <Paper elevation={3} sx={{ p: 3, textAlign: 'center', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
//                                 <Tooltip title={feature.description} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
//                                     <IconButton
//                                         onMouseEnter={() => setHoveredFeature(index)}
//                                         onMouseLeave={() => setHoveredFeature(null)}
//                                     >
//                                         <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
//                                             {feature.icon}
//                                         </Avatar>
//                                     </IconButton>
//                                 </Tooltip>
//                                 <Typography variant="h6" component="h3" gutterBottom>
//                                     {feature.title}
//                                 </Typography>
//                                 {hoveredFeature === index && (
//                                     <Fade in={true} timeout={600}>
//                                         <Typography variant="body2" sx={{ mt: 2 }}>
//                                             {feature.description}
//                                         </Typography>
//                                     </Fade>
//                                 )}
//                             </Paper>
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Container>
//         </Box>
//     );
// }
//
// export default FeaturesAndFutureFeatures;


// import React, { useState } from 'react';
// import { Box, Container, Grid, Typography, Paper, Avatar, Tooltip, IconButton, Fade } from '@mui/material';
// import SecurityIcon from '@mui/icons-material/Security';
// import SpeedIcon from '@mui/icons-material/Speed';
// import CloudIcon from '@mui/icons-material/Cloud';
// import UpdateIcon from '@mui/icons-material/Update';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
// import AutoGraphIcon from '@mui/icons-material/AutoGraph';
// import PolicyIcon from '@mui/icons-material/Policy';
// import InsightsIcon from '@mui/icons-material/Insights';
// import BugReportIcon from '@mui/icons-material/BugReport';
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
// import ShieldIcon from '@mui/icons-material/Shield';
//
// const features = [
//     {
//         icon: <SecurityIcon fontSize="large" />,
//         title: "Cutting-edge Threat Detection",
//         description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification."
//     },
//     {
//         icon: <SpeedIcon fontSize="large" />,
//         title: "Real-time Analysis",
//         description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications."
//     },
//     {
//         icon: <CloudIcon fontSize="large" />,
//         title: "Cloud-Native Compatibility",
//         description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run."
//     },
//     {
//         icon: <UpdateIcon fontSize="large" />,
//         title: "Continuous Updates",
//         description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors."
//     },
//     {
//         icon: <PrivacyTipIcon fontSize="large" />,
//         title: "Data Privacy Assurance",
//         description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain."
//     },
//     {
//         icon: <VerifiedUserIcon fontSize="large" />,
//         title: "Adaptive Risk Scoring",
//         description: "Dynamically adjusts risk scores based on user behavior, context, and content sensitivity, providing a tailored security posture for each interaction."
//     },
//     {
//         icon: <ShieldIcon fontSize="large" />,
//         title: "Intelligent Content Disarm & Reconstruction",
//         description: "Processes incoming files and messages to remove potential threats, delivering sanitized content without disrupting business continuity."
//     }
// ];
//
// const futureFeatures = [
//     {
//         icon: <AutoGraphIcon fontSize="large" />,
//         title: "Automated Threat Intelligence Feed",
//         description: "Integration with global threat intelligence to automatically update security measures."
//     },
//     {
//         icon: <PolicyIcon fontSize="large" />,
//         title: "Custom Policy Engine",
//         description: "Ability to create and enforce custom security policies based on enterprise-specific requirements."
//     },
//     {
//         icon: <InsightsIcon fontSize="large" />,
//         title: "Explainable AI Security Insights",
//         description: "Detailed explanations of security decisions to provide clarity and build trust among stakeholders."
//     },
//     {
//         icon: <BugReportIcon fontSize="large" />,
//         title: "Anomaly Detection with AI",
//         description: "Advanced machine learning models to detect and respond to anomalous behavior indicative of new threats."
//     },
//     {
//         icon: <DashboardIcon fontSize="large" />,
//         title: "Compliance Reporting Dashboard",
//         description: "Interactive dashboards to monitor compliance with regulatory standards and internal policies."
//     }
// ];
//
// function FeaturesAndFutureFeatures() {
//     const [hoveredFeature, setHoveredFeature] = useState(null);
//
//     const allFeatures = [...features, ...futureFeatures];
//
//     return (
//         <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
//             <Container maxWidth="lg">
//                 <Typography variant="h4" component="h2" gutterBottom align="center">
//                     Key Features & Future Features
//                 </Typography>
//                 <Grid container spacing={4}>
//                     {allFeatures.map((feature, index) => (
//                         <Grid item xs={12} sm={6} md={4} key={index}>
//                             <Paper elevation={3} sx={{ p: 3, textAlign: 'center', position: 'relative' }}>
//                                 <Tooltip title={feature.description} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
//                                     <IconButton
//                                         onMouseEnter={() => setHoveredFeature(index)}
//                                         onMouseLeave={() => setHoveredFeature(null)}
//                                     >
//                                         <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
//                                             {feature.icon}
//                                         </Avatar>
//                                     </IconButton>
//                                 </Tooltip>
//                                 <Typography variant="h6" component="h3" gutterBottom>
//                                     {feature.title}
//                                 </Typography>
//                                 {hoveredFeature === index && (
//                                     <Fade in={true} timeout={600}>
//                                         <Typography variant="body2" sx={{ mt: 2 }}>
//                                             {feature.description}
//                                         </Typography>
//                                     </Fade>
//                                 )}
//                             </Paper>
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Container>
//         </Box>
//     );
// }
//
// export default FeaturesAndFutureFeatures;



// import React, { useState } from 'react';
// import { Box, Container, Grid, Typography, Paper, Avatar, Tooltip, IconButton, Fade } from '@mui/material';
// import SecurityIcon from '@mui/icons-material/Security';
// import SpeedIcon from '@mui/icons-material/Speed';
// import CloudIcon from '@mui/icons-material/Cloud';
// import UpdateIcon from '@mui/icons-material/Update';
// import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
// import AutoGraphIcon from '@mui/icons-material/AutoGraph';
// import PolicyIcon from '@mui/icons-material/Policy';
// import InsightsIcon from '@mui/icons-material/Insights';
// import BugReportIcon from '@mui/icons-material/BugReport';
// import DashboardIcon from '@mui/icons-material/Dashboard';
//
// const features = [
//     {
//         icon: <SecurityIcon fontSize="large" />,
//         title: "Cutting-edge Threat Detection",
//         description: "Employs a sophisticated blend of machine learning models, heuristic algorithms, and signature-based methods to deliver precise threat identification."
//     },
//     {
//         icon: <SpeedIcon fontSize="large" />,
//         title: "Real-time Analysis",
//         description: "Delivers sub-millisecond response times for content scanning, ensuring seamless integration with real-time applications."
//     },
//     {
//         icon: <CloudIcon fontSize="large" />,
//         title: "Cloud-Native Compatibility",
//         description: "Fully compatible with modern cloud-native and serverless architectures, including AWS Lambda, Azure Functions, and Google Cloud Run."
//     },
//     {
//         icon: <UpdateIcon fontSize="large" />,
//         title: "Continuous Updates",
//         description: "Regularly updated detection patterns and algorithms to stay ahead of emerging security challenges and threat vectors."
//     },
//     {
//         icon: <PrivacyTipIcon fontSize="large" />,
//         title: "Data Privacy Assurance",
//         description: "Operates entirely on-premises or within your private cloud environment, guaranteeing that sensitive data never leaves your controlled domain."
//     }
// ];
//
// const futureFeatures = [
//     {
//         icon: <AutoGraphIcon fontSize="large" />,
//         title: "Automated Threat Intelligence Feed",
//         description: "Integration with global threat intelligence to automatically update security measures."
//     },
//     {
//         icon: <PolicyIcon fontSize="large" />,
//         title: "Custom Policy Engine",
//         description: "Ability to create and enforce custom security policies based on enterprise-specific requirements."
//     },
//     {
//         icon: <InsightsIcon fontSize="large" />,
//         title: "Explainable AI Security Insights",
//         description: "Detailed explanations of security decisions to provide clarity and build trust among stakeholders."
//     },
//     {
//         icon: <BugReportIcon fontSize="large" />,
//         title: "Anomaly Detection with AI",
//         description: "Advanced machine learning models to detect and respond to anomalous behavior indicative of new threats."
//     },
//     {
//         icon: <DashboardIcon fontSize="large" />,
//         title: "Compliance Reporting Dashboard",
//         description: "Interactive dashboards to monitor compliance with regulatory standards and internal policies."
//     }
// ];
//
// function FeaturesAndFutureFeatures() {
//     const [hoveredFeature, setHoveredFeature] = useState(null);
//
//     const allFeatures = [...features, ...futureFeatures];
//
//     return (
//         <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
//             <Container maxWidth="lg">
//                 <Typography variant="h4" component="h2" gutterBottom align="center">
//                     Key Features
//                 </Typography>
//                 <Grid container spacing={4}>
//                     {allFeatures.map((feature, index) => (
//                         <Grid item xs={12} sm={6} md={4} key={index}>
//                             <Paper elevation={3} sx={{ p: 3, textAlign: 'center', position: 'relative' }}>
//                                 <Tooltip title={feature.description} arrow placement="top" TransitionComponent={Fade} TransitionProps={{ timeout: 600 }}>
//                                     <IconButton
//                                         onMouseEnter={() => setHoveredFeature(index)}
//                                         onMouseLeave={() => setHoveredFeature(null)}
//                                     >
//                                         <Avatar sx={{ bgcolor: 'primary.main', mx: 'auto', mb: 2 }}>
//                                             {feature.icon}
//                                         </Avatar>
//                                     </IconButton>
//                                 </Tooltip>
//                                 <Typography variant="h6" component="h3" gutterBottom>
//                                     {feature.title}
//                                 </Typography>
//                                 {hoveredFeature === index && (
//                                     <Fade in={true} timeout={600}>
//                                         <Typography variant="body2" sx={{ mt: 2 }}>
//                                             {feature.description}
//                                         </Typography>
//                                     </Fade>
//                                 )}
//                             </Paper>
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Container>
//         </Box>
//     );
// }
//
// export default FeaturesAndFutureFeatures;
