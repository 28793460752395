// // Header.js
// import React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import { makeStyles } from '@mui/styles';
// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//   },
//   appBar: {
//     backgroundColor: '#333',
//   },
// }));
// const Header = () => {
//   const classes = useStyles();
//   return (
//     <div className={classes.root}>
//       <AppBar position='static' className={classes.appBar}>
//         <Toolbar>
//           <IconButton edge='start' className={classes.menuButton} color='inherit' aria-label='menu'>
//             <MenuIcon />
//           </IconButton>
//           <Typography variant='h6' className={classes.title}>
//             My Website
//           </Typography>
//           <Button color='inherit'>Login</Button>
//         </Toolbar>
//       </AppBar>
//     </div>
//   );
// };
// export default Header;


// import React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import { makeStyles } from '@mui/styles';
// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//   },
//   appBar: {
//     background: '#3f51b5', // Change to adapt the background color
//   },
//   button: {
//     color: '#fff', // Change to adapt the button color
//   }
// }));
// const Header = () => {
//   const classes = useStyles();
//   return (
//     <div className={classes.root}>
//       <AppBar position='static' className={classes.appBar}>
//         <Toolbar>
//           <IconButton edge='start' className={classes.menuButton} color='inherit' aria-label='menu'>
//             <MenuIcon />
//           </IconButton>
//           <Typography variant='h6' className={classes.title}>
//             My Website
//           </Typography>
//           <Button className={classes.button}>Login</Button>
//           <Button className={classes.button}>Sign Up</Button>
//         </Toolbar>
//       </AppBar>
//     </div>
//   );
// };
// export default Header;


// // Header.js
// import React from 'react';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import IconButton from '@mui/material/IconButton';
// import MenuIcon from '@mui/icons-material/Menu';
// import { makeStyles } from '@mui/styles';
// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//   },
//   appBar: {
//     backgroundColor: '#3f51b5', // Change this to adapt the background color
//   },
//   button: {
//     color: '#fff', // Change this to adapt the button text color
//   },
// }));
// const Header = () => {
//   const classes = useStyles();
//   return (
//     <div className={classes.root}>
//       <AppBar position='static' className={classes.appBar}>
//         <Toolbar>
//           <IconButton edge='start' className={classes.menuButton} color='inherit' aria-label='menu'>
//             <MenuIcon />
//           </IconButton>
//           <Typography variant='h6' className={classes.title}>
//             Header Title {/* Adapt the text as needed */}
//           </Typography>
//           <Button className={classes.button} onClick={() => alert('Button 1 clicked!')}>Button 1</Button>
//           <Button className={classes.button} onClick={() => alert('Button 2 clicked!')}>Button 2</Button>
//         </Toolbar>
//       </AppBar>
//     </div>
//   );
// }
// export default Header;

// import React from 'react';
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   Button,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Box,
//   Container,
// } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
// function Header() {
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//   const drawerList = (
//     <Box
//       sx={{ width: 250 }}
//       role='presentation'
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to='/'>
//           <ListItemText primary='Home' />
//         </ListItem>
//         <ListItem button component={Link} to='/contact'>
//           <ListItemText primary='Contact' />
//         </ListItem>
//         <ListItem button component={Link} to='/marketplace'>
//           <ListItemText primary='Marketplace' />
//         </ListItem>
//       </List>
//     </Box>
//   );
//   return (
//     <>
//       <AppBar position='static' sx={{ backgroundColor: '#333' }}>
//         <Container maxWidth='lg'>
//           <Toolbar disableGutters>
//             <IconButton
//               edge='start'
//               color='inherit'
//               aria-label='menu'
//               sx={{ mr: 2, display: { xs: 'block', md: 'none' } }}
//               onClick={toggleDrawer(true)}
//             >
//               <MenuIcon />
//             </IconButton>
//             <Typography
//               variant='h6'
//               component={Link}
//               to='/'
//               sx={{
//                 flexGrow: 1,
//                 textAlign: { xs: 'center', md: 'left' },
//                 color: 'inherit',
//                 textDecoration: 'none',
//               }}
//             >
//               SecureAI
//             </Typography>
//             <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
//               <Button color='inherit' component={Link} to='/'>Home</Button>
//               <Button color='inherit' component={Link} to='/contact'>Contact</Button>
//               <Button color='inherit' component={Link} to='/marketplace'>Marketplace</Button>
//             </Box>
//             <Button color='inherit' component={Link} to='/signup' sx={{ display: { xs: 'none', md: 'block' } }}>Sign Up</Button>
//           </Toolbar>
//         </Container>
//       </AppBar>
//       <Drawer anchor='left' open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
// export default Header;


// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
//
// function Header() {
//     const [drawerOpen, setDrawerOpen] = React.useState(false);
//
//     const toggleDrawer = (open) => (event) => {
//         if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//             return;
//         }
//         setDrawerOpen(open);
//     };
//
//     const drawerList = (
//         <Box
//             sx={{ width: 250 }}
//             role="presentation"
//             onClick={toggleDrawer(false)}
//             onKeyDown={toggleDrawer(false)}
//         >
//             <List>
//                 <ListItem button component={Link} to="/">
//                     <ListItemText primary="Home" />
//                 </ListItem>
//                 <ListItem button component={Link} to="/contact">
//                     <ListItemText primary="Contact" />
//                 </ListItem>
//                 <ListItem button component={Link} to="/marketplace">
//                     <ListItemText primary="Marketplace" />
//                 </ListItem>
//             </List>
//         </Box>
//     );
//
//     return (
//         <>
//             <AppBar position="static">
//                 <Toolbar>
//                     <IconButton
//                         edge="start"
//                         color="inherit"
//                         aria-label="menu"
//                         sx={{ mr: 2 }}
//                         onClick={toggleDrawer(true)}
//                     >
//                         <MenuIcon />
//                     </IconButton>
//                     <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
//                         SecureAI Guard
//                     </Typography>
//                     <Button color="inherit" component={Link} to="/">Home</Button>
//                     <Button color="inherit" component={Link} to="/contact">Contact</Button>
//                     <Button color="inherit" component={Link} to="/marketplace">Marketplace</Button>
//                 </Toolbar>
//             </AppBar>
//             <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//                 {drawerList}
//             </Drawer>
//         </>
//     );
// }
//
// export default Header;



// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const drawerList = (
//     <Box
//       sx={{ width: 250 }}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/">
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about">
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services">
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace">
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact">
//           <ListItemText primary="Contact" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar position="static" className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             sx={{ mr: 2 }}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <img src="/assets/images/logo.png" alt="Company Logo" style={{ height: '40px' }} />
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services">All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace">Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;



// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'sticky',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const drawerList = (
//     <Box
//       sx={{ width: 250 }}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/">
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about">
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services">
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace">
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact">
//           <ListItemText primary="Contact" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             sx={{ mr: 2 }}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <img src="/assets/images/logo.png" alt="Company Logo" style={{ height: '40px' }} />
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services">All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace">Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;


// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'sticky',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const drawerList = (
//     <Box
//       sx={{ width: 250 }}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/">
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about">
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services">
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace">
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact">
//           <ListItemText primary="Contact" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             sx={{ mr: 2 }}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <img src="/assets/images/logo.png" alt="Company Logo" style={{ height: '40px' }} />
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services">All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace">Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;


//
// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'sticky',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <img src="../../public/assets/images/logo.png" alt="SecureAI Guard" style={{ height: '40px' }} />
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;


// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'sticky',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <img src="/assets/images/logo.png" alt="SecureAI Guard" style={{ height: '40px' }} />
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;

//
// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'sticky',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Link to="/">
//               <img src="/assets/images/logo.png" alt="SecureAI Guard" style={{ height: '40px' }} />
//             </Link>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;


// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'sticky',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Link to="/">
//               <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
//             </Link>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;



// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem, Tooltip } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'sticky',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
//   tooltip: {
//     fontSize: '1rem',
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
//               <Link to="/">
//                 <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
//               </Link>
//             </Tooltip>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;



// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem, Tooltip } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'sticky',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
//   tooltip: {
//     fontSize: '1rem',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)', // Very light and faint color
//     color: 'rgba(0, 0, 0, 0.5)', // Faint text color
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     boxShadow: theme.shadows[1],
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
//               <Link to="/">
//                 <img src={Logo} alt="SecureAI Guard" style={{ height: '50px' }} />
//               </Link>
//             </Tooltip>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;



// Working well

// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem, Tooltip } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'fixed', // Changed to fixed to stick to the top
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//     width: '100%', // Ensure full width
//     marginBottom: 0, // Remove any margin at the bottom
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
//   tooltip: {
//     fontSize: '1rem',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)', // Very light and faint color
//     color: 'rgba(0, 0, 0, 0.5)', // Faint text color
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     boxShadow: theme.shadows[1],
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
//               <Link to="/">
//                 <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
//               </Link>
//             </Tooltip>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;



// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem, Tooltip } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//     width: '100%',
//     marginBottom: 0,
//   },
//   notificationBar: {
//     backgroundColor: theme.palette.secondary.main,
//     color: theme.palette.common.white,
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     padding: theme.spacing(1),
//     position: 'relative',
//   },
//   marquee: {
//     flexGrow: 1,
//     whiteSpace: 'nowrap',
//     overflow: 'hidden',
//     '& span': {
//       display: 'inline-block',
//       paddingLeft: '100%',
//       animation: '$marquee 15s linear infinite',
//     },
//   },
//   '@keyframes marquee': {
//     '0%': { transform: 'translateX(0)' },
//     '100%': { transform: 'translateX(-100%)' },
//   },
//   closeButton: {
//     color: theme.palette.common.white,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   requestDemoButton: {
//     marginLeft: theme.spacing(2),
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
//   tooltip: {
//     fontSize: '1rem',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)',
//     color: 'rgba(0, 0, 0, 0.5)',
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     boxShadow: theme.shadows[1],
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [notificationVisible, setNotificationVisible] = React.useState(true);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const handleNotificationClose = () => {
//     setNotificationVisible(false);
//   };
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       {notificationVisible && (
//         <Box className={classes.notificationBar}>
//           <Box className={classes.marquee}>
//             <span>Enterprise-grade Notification: New features released! Check them out now!</span>
//           </Box>
//           <IconButton className={classes.closeButton} onClick={handleNotificationClose}>
//             <CloseIcon />
//           </IconButton>
//         </Box>
//       )}
//       <AppBar className={classes.appBar}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
//               <Link to="/">
//                 <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
//               </Link>
//             </Tooltip>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//           <Button className={classes.requestDemoButton} component={Link} to="/request-demo">
//             Request a Demo
//           </Button>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;


// import React from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem, Tooltip, Alert, Collapse } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//     width: '100%',
//     marginBottom: 0,
//   },
//   notificationBar: {
//     width: '100%',
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.appBar + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   requestDemoButton: {
//     marginLeft: theme.spacing(2),
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
//   tooltip: {
//     fontSize: '1rem',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)',
//     color: 'rgba(0, 0, 0, 0.5)',
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     boxShadow: theme.shadows[1],
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = React.useState(false);
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [notificationVisible, setNotificationVisible] = React.useState(true);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const handleNotificationClose = () => {
//     setNotificationVisible(false);
//   };
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <Collapse in={notificationVisible} className={classes.notificationBar}>
//         <Alert
//           severity="info"
//           action={
//             <IconButton
//               aria-label="close"
//               color="inherit"
//               size="small"
//               onClick={handleNotificationClose}
//             >
//               <CloseIcon fontSize="inherit" />
//             </IconButton>
//           }
//         >
//           Enterprise-grade Notification: New features released! Check them out now!
//         </Alert>
//       </Collapse>
//       <AppBar className={classes.appBar} style={{ top: notificationVisible ? '48px' : '0' }}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
//               <Link to="/">
//                 <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
//               </Link>
//             </Tooltip>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button color="inherit" component={Link} to="/">Home</Button>
//             <Button color="inherit" component={Link} to="/about">About</Button>
//             <Button
//               color="inherit"
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button color="inherit" component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button color="inherit" component={Link} to="/signin">Sign In</Button>
//           </Box>
//           <Button className={classes.requestDemoButton} component={Link} to="/contact">
//             Request a Demo
//           </Button>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;





// import React, { useEffect, useState } from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem, Tooltip, Alert, Collapse } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     transition: 'background-color 0.3s',
//     color: theme.palette.text.primary,
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//     width: '100%',
//     marginBottom: 0,
//   },
//   scrolledAppBar: {
//     backgroundColor: theme.palette.primary.main,
//   },
//   notificationBar: {
//     width: '100%',
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.appBar + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   requestDemoButton: {
//     marginLeft: theme.spacing(2),
//     backgroundColor: theme.palette.secondary.main,
//     color: theme.palette.common.white,
//     '&:hover': {
//       backgroundColor: theme.palette.secondary.dark,
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
//   tooltip: {
//     fontSize: '1rem',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)',
//     color: 'rgba(0, 0, 0, 0.5)',
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     boxShadow: theme.shadows[1],
//   },
//   menuItem: {
//     color: theme.palette.secondary.main,
//     '&:hover': {
//       color: theme.palette.secondary.dark,
//     },
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [notificationVisible, setNotificationVisible] = useState(true);
//   const [scrolled, setScrolled] = useState(false);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const handleNotificationClose = () => {
//     setNotificationVisible(false);
//   };
//
//   const handleScroll = () => {
//     if (window.scrollY > 50) {
//       setScrolled(true);
//     } else {
//       setScrolled(false);
//     }
//   };
//
//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <Collapse in={notificationVisible} className={classes.notificationBar}>
//         <Alert
//           severity="info"
//           action={
//             <IconButton
//               aria-label="close"
//               color="inherit"
//               size="small"
//               onClick={handleNotificationClose}
//             >
//               <CloseIcon fontSize="inherit" />
//             </IconButton>
//           }
//         >
//           Enterprise-grade Notification: New features released! Check them out now!
//         </Alert>
//       </Collapse>
//       <AppBar className={`${classes.appBar} ${scrolled ? classes.scrolledAppBar : ''}`} style={{ top: notificationVisible ? '48px' : '0' }}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
//               <Link to="/">
//                 <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
//               </Link>
//             </Tooltip>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button className={classes.menuItem} component={Link} to="/">Home</Button>
//             <Button className={classes.menuItem} component={Link} to="/about">About</Button>
//             <Button
//               className={classes.menuItem}
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button className={classes.menuItem} component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button className={classes.menuItem} component={Link} to="/signin">Sign In</Button>
//           </Box>
//           <Button className={classes.requestDemoButton} component={Link} to="/contact">
//             Request a Demo
//           </Button>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;





// import React, { useEffect, useState } from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem, Tooltip, Alert, Collapse } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     transition: 'background-color 0.3s',
//     color: theme.palette.text.primary,
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//     width: '100%',
//     marginBottom: 0,
//   },
//   scrolledAppBar: {
//     backgroundColor: theme.palette.primary.main,
//   },
//   notificationBar: {
//     width: '100%',
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.appBar + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   requestDemoButton: {
//     marginLeft: theme.spacing(2),
//     backgroundColor: theme.palette.secondary.main,
//     color: theme.palette.common.white,
//     '&:hover': {
//       backgroundColor: theme.palette.secondary.dark,
//     },
//   },
//   scrolledRequestDemoButton: {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.primary.main,
//     '&:hover': {
//       backgroundColor: theme.palette.grey[300],
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
//   tooltip: {
//     fontSize: '1rem',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)',
//     color: 'rgba(0, 0, 0, 0.5)',
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     boxShadow: theme.shadows[1],
//   },
//   menuItem: {
//     color: theme.palette.secondary.main,
//     '&:hover': {
//       color: theme.palette.secondary.dark,
//     },
//   },
//   scrolledMenuItem: {
//     color: theme.palette.common.white,
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [notificationVisible, setNotificationVisible] = useState(true);
//   const [scrolled, setScrolled] = useState(false);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const handleNotificationClose = () => {
//     setNotificationVisible(false);
//   };
//
//   const handleScroll = () => {
//     if (window.scrollY > 50) {
//       setScrolled(true);
//     } else {
//       setScrolled(false);
//     }
//   };
//
//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <Collapse in={notificationVisible} className={classes.notificationBar}>
//         <Alert
//           severity="info"
//           action={
//             <IconButton
//               aria-label="close"
//               color="inherit"
//               size="small"
//               onClick={handleNotificationClose}
//             >
//               <CloseIcon fontSize="inherit" />
//             </IconButton>
//           }
//         >
//           Enterprise-grade Notification: New features released! Check them out now!
//         </Alert>
//       </Collapse>
//       <AppBar className={`${classes.appBar} ${scrolled ? classes.scrolledAppBar : ''}`} style={{ top: notificationVisible ? '48px' : '0' }}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
//               <Link to="/">
//                 <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
//               </Link>
//             </Tooltip>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/">Home</Button>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/about">About</Button>
//             <Button
//               className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`}
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/signin">Sign In</Button>
//           </Box>
//           <Button className={`${classes.requestDemoButton} ${scrolled ? classes.scrolledRequestDemoButton : ''}`} component={Link} to="/contact">
//             Request a Demo
//           </Button>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;



// import React, { useEffect, useState } from 'react';
// import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem, Tooltip, Alert, Collapse } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     transition: 'background-color 0.3s',
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//     width: '100%',
//     marginBottom: 0,
//   },
//   scrolledAppBar: {
//     backgroundColor: theme.palette.primary.main,
//   },
//   notificationBar: {
//     width: '100%',
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.appBar + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   requestDemoButton: {
//     marginLeft: theme.spacing(2),
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//     },
//   },
//   scrolledRequestDemoButton: {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.primary.main,
//     '&:hover': {
//       backgroundColor: theme.palette.grey[300],
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
//   tooltip: {
//     fontSize: '1rem',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)',
//     color: 'rgba(0, 0, 0, 0.5)',
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     boxShadow: theme.shadows[1],
//   },
//   menuItem: {
//     color: theme.palette.text.primary,
//     '&:hover': {
//       color: theme.palette.secondary.dark,
//     },
//   },
//   scrolledMenuItem: {
//     color: theme.palette.common.white,
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [notificationVisible, setNotificationVisible] = useState(true);
//   const [scrolled, setScrolled] = useState(false);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const handleNotificationClose = () => {
//     setNotificationVisible(false);
//   };
//
//   const handleScroll = () => {
//     if (window.scrollY > 50) {
//       setScrolled(true);
//     } else {
//       setScrolled(false);
//     }
//   };
//
//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <Collapse in={notificationVisible} className={classes.notificationBar}>
//         <Alert
//           severity="info"
//           action={
//             <IconButton
//               aria-label="close"
//               color="inherit"
//               size="small"
//               onClick={handleNotificationClose}
//             >
//               <CloseIcon fontSize="inherit" />
//             </IconButton>
//           }
//         >
//           Enterprise-grade Notification: New features released! Check them out now!
//         </Alert>
//       </Collapse>
//       <AppBar className={`${classes.appBar} ${scrolled ? classes.scrolledAppBar : ''}`} style={{ top: notificationVisible ? '48px' : '0' }}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
//               <Link to="/">
//                 <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
//               </Link>
//             </Tooltip>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/">Home</Button>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/about">About</Button>
//             <Button
//               className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`}
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/signin">Sign In</Button>
//           </Box>
//           <Button className={`${classes.requestDemoButton} ${scrolled ? classes.scrolledRequestDemoButton : ''}`} component={Link} to="/contact">
//             Request a Demo
//           </Button>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;




// import React, { useEffect, useState } from 'react';
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   Button,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Box,
//   Menu,
//   MenuItem,
//   Tooltip,
//   Alert,
//   Collapse,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     transition: 'background-color 0.3s',
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//     width: '100%',
//     marginBottom: 0,
//   },
//   scrolledAppBar: {
//     backgroundColor: theme.palette.primary.main,
//   },
//   notificationBar: {
//     width: '100%',
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.appBar + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   requestDemoButton: {
//     marginLeft: theme.spacing(2),
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//     },
//   },
//   scrolledRequestDemoButton: {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.primary.main,
//     '&:hover': {
//       backgroundColor: theme.palette.grey[300],
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
//   tooltip: {
//     fontSize: '1rem',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)',
//     color: 'rgba(0, 0, 0, 0.5)',
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     boxShadow: theme.shadows[1],
//   },
//   menuItem: {
//     color: theme.palette.text.primary,
//     '&:hover': {
//       color: theme.palette.secondary.dark,
//     },
//   },
//   scrolledMenuItem: {
//     color: theme.palette.common.white,
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [notificationVisible, setNotificationVisible] = useState(true);
//   const [scrolled, setScrolled] = useState(false);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const handleNotificationClose = () => {
//     setNotificationVisible(false);
//   };
//
//   const handleScroll = () => {
//     if (window.scrollY > 50) {
//       setScrolled(true);
//     } else {
//       setScrolled(false);
//     }
//   };
//
//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <Collapse in={notificationVisible} className={classes.notificationBar}>
//         <Alert
//           severity="info"
//           action={
//             <IconButton
//               aria-label="close"
//               color="inherit"
//               size="small"
//               onClick={handleNotificationClose}
//             >
//               <CloseIcon fontSize="inherit" />
//             </IconButton>
//           }
//         >
//           Enterprise-grade Notification: New features released! Check them out now!
//         </Alert>
//       </Collapse>
//       <AppBar className={`${classes.appBar} ${scrolled ? classes.scrolledAppBar : ''}`} style={{ top: notificationVisible ? '48px' : '0' }}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
//               <Link to="/">
//                 <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
//               </Link>
//             </Tooltip>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/">Home</Button>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/about">About</Button>
//             <Button
//               className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`}
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/signin">Sign In</Button>
//           </Box>
//           <Button className={`${classes.requestDemoButton} ${scrolled ? classes.scrolledRequestDemoButton : ''}`} component={Link} to="/contact">
//             Request a Demo
//           </Button>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;





// import React, { useEffect, useState } from 'react';
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   Button,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Box,
//   Menu,
//   MenuItem,
//   Tooltip,
//   Alert,
//   Collapse,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     transition: 'background-color 0.3s',
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//     width: '100%',
//     marginBottom: 0,
//   },
//   scrolledAppBar: {
//     backgroundColor: theme.palette.primary.main,
//   },
//   notificationBar: {
//     width: '100%',
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.appBar + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   requestDemoButton: {
//     marginLeft: theme.spacing(2),
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//     },
//   },
//   scrolledRequestDemoButton: {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.primary.main,
//     '&:hover': {
//       backgroundColor: theme.palette.grey[300],
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
//   tooltip: {
//     fontSize: '1rem',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)',
//     color: 'rgba(0, 0, 0, 0.5)',
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     boxShadow: theme.shadows[1],
//   },
//   menuItem: {
//     color: theme.palette.text.primary,
//     '&:hover': {
//       color: theme.palette.secondary.dark,
//     },
//   },
//   scrolledMenuItem: {
//     color: theme.palette.common.white,
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [notificationVisible, setNotificationVisible] = useState(true);
//   const [scrolled, setScrolled] = useState(false);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const handleNotificationClose = () => {
//     setNotificationVisible(false);
//   };
//
//   const handleScroll = () => {
//     if (window.scrollY > 50) {
//       setScrolled(true);
//     } else {
//       setScrolled(false);
//     }
//   };
//
//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
//
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setNotificationVisible(false);
//     }, 5000);
//     return () => clearTimeout(timer);
//   }, []);
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <Collapse in={notificationVisible} className={classes.notificationBar}>
//         <Alert
//           severity="info"
//           action={
//             <IconButton
//               aria-label="close"
//               color="inherit"
//               size="small"
//               onClick={handleNotificationClose}
//             >
//               <CloseIcon fontSize="inherit" />
//             </IconButton>
//           }
//         >
//           Enterprise-grade Notification: New features released! Check them out now!
//         </Alert>
//       </Collapse>
//       <AppBar className={`${classes.appBar} ${scrolled ? classes.scrolledAppBar : ''}`} style={{ top: notificationVisible ? '48px' : '0' }}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
//               <Link to="/">
//                 <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
//               </Link>
//             </Tooltip>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/">Home</Button>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/about">About</Button>
//             <Button
//               className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`}
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/signin">Sign In</Button>
//           </Box>
//           <Button className={`${classes.requestDemoButton} ${scrolled ? classes.scrolledRequestDemoButton : ''}`} component={Link} to="/contact">
//             Request a Demo
//           </Button>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;




// import React, { useEffect, useState } from 'react';
// import {
//   AppBar,
//   Toolbar,
//   Typography,
//   Button,
//   IconButton,
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Box,
//   Menu,
//   MenuItem,
//   Tooltip,
//   Alert,
//   Collapse,
//   useMediaQuery,
//   useTheme,
// } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import CloseIcon from '@mui/icons-material/Close';
// import { Link } from 'react-router-dom';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     transition: 'background-color 0.3s',
//     backgroundColor: theme.palette.background.paper,
//     color: theme.palette.text.primary,
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.drawer + 1,
//     width: '100%',
//     marginBottom: 0,
//   },
//   scrolledAppBar: {
//     backgroundColor: theme.palette.primary.main,
//   },
//   notificationBar: {
//     width: '100%',
//     position: 'fixed',
//     top: 0,
//     zIndex: theme.zIndex.appBar + 1,
//   },
//   logo: {
//     flexGrow: 1,
//     textAlign: 'left',
//   },
//   navLinks: {
//     flexGrow: 2,
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   signInButton: {
//     flexGrow: 1,
//     textAlign: 'right',
//     [theme.breakpoints.down('sm')]: {
//       display: 'none',
//     },
//   },
//   requestDemoButton: {
//     marginLeft: theme.spacing(2),
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.dark,
//     },
//   },
//   scrolledRequestDemoButton: {
//     backgroundColor: theme.palette.common.white,
//     color: theme.palette.primary.main,
//     '&:hover': {
//       backgroundColor: theme.palette.grey[300],
//     },
//   },
//   menuButton: {
//     [theme.breakpoints.up('md')]: {
//       display: 'none',
//     },
//   },
//   drawerList: {
//     width: 250,
//     paddingTop: theme.spacing(8), // Ensure the drawer content starts below the header
//   },
//   drawerItem: {
//     textAlign: 'center',
//   },
//   tooltip: {
//     fontSize: '1rem',
//     backgroundColor: 'rgba(255, 255, 255, 0.8)',
//     color: 'rgba(0, 0, 0, 0.5)',
//     borderRadius: theme.shape.borderRadius,
//     padding: theme.spacing(1),
//     boxShadow: theme.shadows[1],
//   },
//   menuItem: {
//     color: theme.palette.text.primary,
//     '&:hover': {
//       color: theme.palette.secondary.dark,
//     },
//   },
//   scrolledMenuItem: {
//     color: theme.palette.common.white,
//   },
// }));
//
// function Header() {
//   const classes = useStyles();
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
//   const [drawerOpen, setDrawerOpen] = useState(false);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [notificationVisible, setNotificationVisible] = useState(true);
//   const [scrolled, setScrolled] = useState(false);
//
//   const toggleDrawer = (open) => (event) => {
//     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
//       return;
//     }
//     setDrawerOpen(open);
//   };
//
//   const handleMenuClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//
//   const handleMenuClose = () => {
//     setAnchorEl(null);
//   };
//
//   const handleMenuItemClick = () => {
//     setAnchorEl(null);
//     setDrawerOpen(false);
//   };
//
//   const handleNotificationClose = () => {
//     setNotificationVisible(false);
//   };
//
//   const handleScroll = () => {
//     if (window.scrollY > 50) {
//       setScrolled(true);
//     } else {
//       setScrolled(false);
//     }
//   };
//
//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);
//
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setNotificationVisible(false);
//     }, 5000);
//     return () => clearTimeout(timer);
//   }, []);
//
//   const drawerList = (
//     <Box
//       className={classes.drawerList}
//       role="presentation"
//       onClick={toggleDrawer(false)}
//       onKeyDown={toggleDrawer(false)}
//     >
//       <List>
//         <ListItem button component={Link} to="/" className={classes.drawerItem}>
//           <ListItemText primary="Home" />
//         </ListItem>
//         <ListItem button component={Link} to="/about" className={classes.drawerItem}>
//           <ListItemText primary="About" />
//         </ListItem>
//         <ListItem button component={Link} to="/services" className={classes.drawerItem}>
//           <ListItemText primary="Services" />
//         </ListItem>
//         <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
//           <ListItemText primary="Marketplace" />
//         </ListItem>
//         <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
//           <ListItemText primary="Contact" />
//         </ListItem>
//         <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
//           <ListItemText primary="Sign In" />
//         </ListItem>
//       </List>
//     </Box>
//   );
//
//   return (
//     <>
//       <Collapse in={notificationVisible} className={classes.notificationBar}>
//         <Alert
//           severity="info"
//           action={
//             <IconButton
//               aria-label="close"
//               color="inherit"
//               size="small"
//               onClick={handleNotificationClose}
//             >
//               <CloseIcon fontSize="inherit" />
//             </IconButton>
//           }
//         >
//           Enterprise-grade Notification: New features released! Check them out now!
//         </Alert>
//       </Collapse>
//       <AppBar className={`${classes.appBar} ${scrolled ? classes.scrolledAppBar : ''}`} style={{ top: notificationVisible ? '48px' : '0' }}>
//         <Toolbar>
//           <IconButton
//             edge="start"
//             color="inherit"
//             aria-label="menu"
//             className={classes.menuButton}
//             onClick={toggleDrawer(true)}
//           >
//             <MenuIcon />
//           </IconButton>
//           <Typography variant="h6" component="div" className={classes.logo}>
//             <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
//               <Link to="/">
//                 <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
//               </Link>
//             </Tooltip>
//           </Typography>
//           <Box className={classes.navLinks}>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/">Home</Button>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/about">About</Button>
//             <Button
//               className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`}
//               aria-controls="services-menu"
//               aria-haspopup="true"
//               onClick={handleMenuClick}
//             >
//               Services
//             </Button>
//             <Menu
//               id="services-menu"
//               anchorEl={anchorEl}
//               keepMounted
//               open={Boolean(anchorEl)}
//               onClose={handleMenuClose}
//             >
//               <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
//               <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
//             </Menu>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/contact">Contact</Button>
//           </Box>
//           <Box className={classes.signInButton}>
//             <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/signin">Sign In</Button>
//           </Box>
//           <Button className={`${classes.requestDemoButton} ${scrolled ? classes.scrolledRequestDemoButton : ''}`} component={Link} to="/contact">
//             Request a Demo
//           </Button>
//         </Toolbar>
//       </AppBar>
//       <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
//         {drawerList}
//       </Drawer>
//     </>
//   );
// }
//
// export default Header;


import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Menu,
  MenuItem,
  Tooltip,
  Alert,
  Collapse,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary

const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: 'background-color 0.3s',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    position: 'fixed',
    top: 0,
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    marginBottom: 0,
  },
  scrolledAppBar: {
    backgroundColor: theme.palette.primary.main,
  },
  notificationBar: {
    width: '100%',
    position: 'fixed',
    top: 0,
    zIndex: theme.zIndex.appBar + 1,
  },
  logo: {
    flexGrow: 1,
    textAlign: 'left',
  },
  navLinks: {
    flexGrow: 2,
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  signInButton: {
    flexGrow: 1,
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  requestDemoButton: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
  scrolledRequestDemoButton: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.grey[300],
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerList: {
    width: 250,
    paddingTop: theme.spacing(8), // Ensure the drawer content starts below the header
  },
  drawerItem: {
    textAlign: 'center',
  },
  tooltip: {
    fontSize: '1rem',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    color: 'rgba(0, 0, 0, 0.5)',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
  },
  menuItem: {
    color: theme.palette.text.primary,
    '&:hover': {
      color: theme.palette.secondary.dark,
    },
  },
  scrolledMenuItem: {
    color: theme.palette.common.white,
  },
}));

function Header() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationVisible, setNotificationVisible] = useState(true);
  const [scrolled, setScrolled] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = () => {
    setAnchorEl(null);
    setDrawerOpen(false);
  };

  const handleNotificationClose = () => {
    setNotificationVisible(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setNotificationVisible(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const drawerList = (
    <Box
      className={classes.drawerList}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/" className={classes.drawerItem}>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/about" className={classes.drawerItem}>
          <ListItemText primary="About" />
        </ListItem>
        <ListItem button component={Link} to="/services" className={classes.drawerItem}>
          <ListItemText primary="Services" />
        </ListItem>
        <ListItem button component={Link} to="/marketplace" className={classes.drawerItem}>
          <ListItemText primary="Marketplace" />
        </ListItem>
        <ListItem button component={Link} to="/contact" className={classes.drawerItem}>
          <ListItemText primary="Contact" />
        </ListItem>
        <ListItem button component={Link} to="/signin" className={classes.drawerItem}>
          <ListItemText primary="Sign In" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Collapse in={notificationVisible} className={classes.notificationBar}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={handleNotificationClose}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Enterprise-grade Notification: New features released! Check them out now!
        </Alert>
      </Collapse>
      <AppBar className={`${classes.appBar} ${scrolled ? classes.scrolledAppBar : ''}`} style={{ top: notificationVisible ? '48px' : '0' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            className={classes.menuButton}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" className={classes.logo}>
            <Tooltip title="SecureAI Guard" classes={{ tooltip: classes.tooltip }} arrow>
              <Link to="/">
                <img src={Logo} alt="SecureAI Guard" style={{ height: '40px' }} />
              </Link>
            </Tooltip>
          </Typography>
          <Box className={classes.navLinks}>
            <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/">Home</Button>
            <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/about">About</Button>
            <Button
              className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`}
              aria-controls="services-menu"
              aria-haspopup="true"
              onClick={handleMenuClick}
            >
              Services
            </Button>
            <Menu
              id="services-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem component={Link} to="/services" onClick={handleMenuItemClick}>All Services</MenuItem>
              <MenuItem component={Link} to="/marketplace" onClick={handleMenuItemClick}>Marketplace</MenuItem>
            </Menu>
            <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/contact">Contact</Button>
          </Box>
          <Box className={classes.signInButton}>
            <Button className={`${classes.menuItem} ${scrolled ? classes.scrolledMenuItem : ''}`} component={Link} to="/signin">Sign In</Button>
          </Box>
          <Button className={`${classes.requestDemoButton} ${scrolled ? classes.scrolledRequestDemoButton : ''}`} component={Link} to="/contact">
            Request a Demo
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList}
      </Drawer>
    </>
  );
}

export default Header;

