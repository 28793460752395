// // Footer.js
// import React from 'react';
// import { AppBar, Toolbar, Typography, Link, Container } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// const useStyles = makeStyles((theme) => ({
//   footer: {
//     top: 'auto',
//     bottom: 0,
//     backgroundColor: '#333',
//     color: 'white',
//   },
//   container: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
// }));
// const Footer = () => {
//   const classes = useStyles();
//   return (
//     <AppBar position='fixed' className={classes.footer}>
//       <Toolbar>
//         <Container className={classes.container}>
//           <Typography variant='body1'>© 2023 My Website</Typography>
//           <div>
//             <Link href='#' color='inherit' style={{ marginRight: 16 }}>
//               Privacy Policy
//             </Link>
//             <Link href='#' color='inherit'>
//               Terms of Use
//             </Link>
//           </div>
//         </Container>
//       </Toolbar>
//     </AppBar>
//   );
// };
// export default Footer;

// import React from 'react';
// import { Typography, Container, Grid, Link } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// const useStyles = makeStyles((theme) => ({
//   footer: {
//     backgroundColor: '#3f51b5', // Change to adapt the background color
//     padding: theme.spacing(6, 0),
//     marginTop: 'auto',
//   },
//   link: {
//     color: '#fff', // Change to adapt the link color
//   },
//   typography: {
//     color: '#fff', // Change to adapt the text color
//   }
// }));
// const Footer = () => {
//   const classes = useStyles();
//   return (
//     <footer className={classes.footer}>
//       <Container maxWidth='lg'>
//         <Grid container spacing={4} justifyContent='center'>
//           <Grid item xs={12} sm={4}>
//             <Typography variant='h6' className={classes.typography} gutterBottom>
//               About Us
//             </Typography>
//             <Typography variant='body2' className={classes.typography}>
//               We are a team of dedicated professionals committed to providing top-notch services.
//             </Typography>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Typography variant='h6' className={classes.typography} gutterBottom>
//               Services
//             </Typography>
//             <Link href='#' variant='body2' className={classes.link} display='block'>
//               Web Development
//             </Link>
//             <Link href='#' variant='body2' className={classes.link} display='block'>
//               Mobile Apps
//             </Link>
//             <Link href='#' variant='body2' className={classes.link} display='block'>
//               Cloud Solutions
//             </Link>
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <Typography variant='h6' className={classes.typography} gutterBottom>
//               Contact Us
//             </Typography>
//             <Typography variant='body2' className={classes.typography}>
//               Email: info@mywebsite.com
//             </Typography>
//             <Typography variant='body2' className={classes.typography}>
//               Phone: (123) 456-7890
//             </Typography>
//           </Grid>
//         </Grid>
//         <Typography variant='body2' color='inherit' align='center' className={classes.typography} style={{ marginTop: '20px' }}>
//           © {new Date().getFullYear()} My Website. All rights reserved.
//         </Typography>
//       </Container>
//     </footer>
//   );
// };
// export default Footer;


// import React from 'react';
// import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
// import { Facebook, Twitter, LinkedIn, GitHub } from '@mui/icons-material';
//
// function Footer() {
//     return (
//         <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 6 }}>
//             <Container maxWidth="lg">
//                 <Grid container spacing={4}>
//                     <Grid item xs={12} sm={4}>
//                         <Typography variant="h6" gutterBottom>
//                             SecureAI Guard
//                         </Typography>
//                         <Typography variant="body2">
//                             Enterprise-grade Security Layer for Language Model Applications.
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={12} sm={4}>
//                         <Typography variant="h6" gutterBottom>
//                             Quick Links
//                         </Typography>
//                         <Link href="/" color="inherit" variant="body2" display="block" gutterBottom>
//                             Home
//                         </Link>
//                         <Link href="/contact" color="inherit" variant="body2" display="block" gutterBottom>
//                             Contact
//                         </Link>
//                         <Link href="/marketplace" color="inherit" variant="body2" display="block" gutterBottom>
//                             Marketplace
//                         </Link>
//                     </Grid>
//                     <Grid item xs={12} sm={4}>
//                         <Typography variant="h6" gutterBottom>
//                             Follow Us
//                         </Typography>
//                         <Box>
//                             <IconButton color="inherit" href="https://facebook.com">
//                                 <Facebook />
//                             </IconButton>
//                             <IconButton color="inherit" href="https://twitter.com">
//                                 <Twitter />
//                             </IconButton>
//                             <IconButton color="inherit" href="https://linkedin.com">
//                                 <LinkedIn />
//                             </IconButton>
//                             <IconButton color="inherit" href="https://github.com">
//                                 <GitHub />
//                             </IconButton>
//                         </Box>
//                     </Grid>
//                 </Grid>
//                 <Box mt={4} textAlign="center">
//                     <Typography variant="body2">
//                         © {new Date().getFullYear()} SecureAI Guard. All rights reserved.
//                     </Typography>
//                 </Box>
//             </Container>
//         </Box>
//     );
// }
//
// export default Footer;


// import React from 'react';
// import { Box, Container, Grid, Typography, Link, IconButton, TextField, Button } from '@mui/material';
// import { Facebook, Twitter, LinkedIn, GitHub } from '@mui/icons-material';
//
// function Footer() {
//     return (
//         <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 6 }}>
//             <Container maxWidth="lg">
//                 <Grid container spacing={4}>
//                     <Grid item xs={12} sm={3}>
//                         <Typography variant="h6" gutterBottom>
//                             SecureAI Guard
//                         </Typography>
//                         <Typography variant="body2">
//                             Enterprise-grade Security Layer for Language Model Applications.
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={12} sm={3}>
//                         <Typography variant="h6" gutterBottom>
//                             Quick Links
//                         </Typography>
//                         <Link href="/" color="inherit" variant="body2" display="block" gutterBottom>
//                             Home
//                         </Link>
//                         <Link href="/about" color="inherit" variant="body2" display="block" gutterBottom>
//                             About
//                         </Link>
//                         <Link href="/services" color="inherit" variant="body2" display="block" gutterBottom>
//                             Services
//                         </Link>
//                         <Link href="/marketplace" color="inherit" variant="body2" display="block" gutterBottom>
//                             Marketplace
//                         </Link>
//                         <Link href="/contact" color="inherit" variant="body2" display="block" gutterBottom>
//                             Contact
//                         </Link>
//                     </Grid>
//                     <Grid item xs={12} sm={3}>
//                         <Typography variant="h6" gutterBottom>
//                             Contact Information
//                         </Typography>
//                         <Typography variant="body2" display="block" gutterBottom>
//                             Email: support@secureaiguard.com
//                         </Typography>
//                         <Typography variant="body2" display="block" gutterBottom>
//                             Phone: +1 (123) 456-7890
//                         </Typography>
//                         <Typography variant="body2" display="block" gutterBottom>
//                             Address: 1234 AI Street, Tech City, TX 75001
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={12} sm={3}>
//                         <Typography variant="h6" gutterBottom>
//                             Follow Us
//                         </Typography>
//                         <Box>
//                             <IconButton color="inherit" href="https://facebook.com">
//                                 <Facebook />
//                             </IconButton>
//                             <IconButton color="inherit" href="https://twitter.com">
//                                 <Twitter />
//                             </IconButton>
//                             <IconButton color="inherit" href="https://linkedin.com">
//                                 <LinkedIn />
//                             </IconButton>
//                             <IconButton color="inherit" href="https://github.com">
//                                 <GitHub />
//                             </IconButton>
//                         </Box>
//                         <Typography variant="h6" gutterBottom mt={4}>
//                             Newsletter
//                         </Typography>
//                         <Box component="form" noValidate autoComplete="off">
//                             <TextField
//                                 variant="outlined"
//                                 placeholder="Your email"
//                                 size="small"
//                                 sx={{ bgcolor: 'white', borderRadius: 1, mb: 2 }}
//                                 fullWidth
//                             />
//                             <Button variant="contained" color="secondary" fullWidth>
//                                 Subscribe
//                             </Button>
//                         </Box>
//                     </Grid>
//                 </Grid>
//                 <Box mt={4} textAlign="center">
//                     <Typography variant="body2">
//                         © {new Date().getFullYear()} SecureAI Guard. All rights reserved.
//                     </Typography>
//                 </Box>
//             </Container>
//         </Box>
//     );
// }
//
// export default Footer;


// import React from 'react';
// import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
// import { Facebook, Twitter, LinkedIn, GitHub } from '@mui/icons-material';
//
// function Footer() {
//     return (
//         <Box sx={{ bgcolor: 'primary.main', color: 'white', py: 6 }}>
//             <Container maxWidth="lg">
//                 <Grid container spacing={4}>
//                     <Grid item xs={12} sm={3}>
//                         <Typography variant="h6" gutterBottom>
//                             <img src="..\..\logo.png" alt="SecureAI Guard" style={{ height: '40px' }} />
//                         </Typography>
//                         <Typography variant="body2">
//                             Enterprise-grade Security Layer for Language Model Applications.
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={12} sm={3}>
//                         <Typography variant="h6" gutterBottom>
//                             Quick Links
//                         </Typography>
//                         <Link href="/" color="inherit" variant="body2" display="block" gutterBottom>
//                             Home
//                         </Link>
//                         <Link href="/about" color="inherit" variant="body2" display="block" gutterBottom>
//                             About
//                         </Link>
//                         <Link href="/services" color="inherit" variant="body2" display="block" gutterBottom>
//                             Services
//                         </Link>
//                         <Link href="/marketplace" color="inherit" variant="body2" display="block" gutterBottom>
//                             Marketplace
//                         </Link>
//                         <Link href="/contact" color="inherit" variant="body2" display="block" gutterBottom>
//                             Contact
//                         </Link>
//                         <Link href="/signin" color="inherit" variant="body2" display="block" gutterBottom>
//                             Sign In
//                         </Link>
//                     </Grid>
//                     <Grid item xs={12} sm={3}>
//                         <Typography variant="h6" gutterBottom>
//                             Contact Information
//                         </Typography>
//                         <Typography variant="body2" display="block" gutterBottom>
//                             Email: support@secureaiguard.com
//                         </Typography>
//                         <Typography variant="body2" display="block" gutterBottom>
//                             Phone: +1 (123) 456-7890
//                         </Typography>
//                         <Typography variant="body2" display="block" gutterBottom>
//                             Address: 1234 AI Street, Tech City, TX 75001
//                         </Typography>
//                     </Grid>
//                     <Grid item xs={12} sm={3}>
//                         <Typography variant="h6" gutterBottom>
//                             Follow Us
//                         </Typography>
//                         <Box>
//                             <IconButton color="inherit" href="https://facebook.com">
//                                 <Facebook />
//                             </IconButton>
//                             <IconButton color="inherit" href="https://twitter.com">
//                                 <Twitter />
//                             </IconButton>
//                             <IconButton color="inherit" href="https://linkedin.com">
//                                 <LinkedIn />
//                             </IconButton>
//                             <IconButton color="inherit" href="https://github.com">
//                                 <GitHub />
//                             </IconButton>
//                         </Box>
//                     </Grid>
//                 </Grid>
//                 <Box mt={4} textAlign="center">
//                     <Typography variant="body2">
//                         © {new Date().getFullYear()} SecureAI Guard. All rights reserved.
//                     </Typography>
//                 </Box>
//             </Container>
//         </Box>
//     );
// }
//
// export default Footer;



// import React from 'react';
// import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
// import { Facebook, Twitter, LinkedIn, GitHub } from '@mui/icons-material';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   footer: {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     padding: theme.spacing(6, 0),
//   },
//   logo: {
//     height: '50px',
//     marginBottom: theme.spacing(2),
//   },
//   link: {
//     display: 'block',
//     marginBottom: theme.spacing(1),
//     color: theme.palette.common.white,
//     '&:hover': {
//       textDecoration: 'underline',
//     },
//   },
//   iconButton: {
//     color: theme.palette.common.white,
//     '&:hover': {
//       color: theme.palette.secondary.main,
//     },
//   },
// }));
//
// function Footer() {
//   const classes = useStyles();
//
//   return (
//     <Box className={classes.footer}>
//       <Container maxWidth="lg">
//         <Grid container spacing={4}>
//           <Grid item xs={12} sm={3}>
//             <img src={Logo} alt="SecureAI Guard" className={classes.logo} />
//             <Typography variant="body2">
//               Enterprise-grade Security Layer for Language Model Applications.
//             </Typography>
//           </Grid>
//           <Grid item xs={12} sm={3}>
//             <Typography variant="h6" gutterBottom>
//               Quick Links
//             </Typography>
//             <Link href="/" className={classes.link}>
//               Home
//             </Link>
//             <Link href="/about" className={classes.link}>
//               About
//             </Link>
//             <Link href="/services" className={classes.link}>
//               Services
//             </Link>
//             <Link href="/marketplace" className={classes.link}>
//               Marketplace
//             </Link>
//             <Link href="/contact" className={classes.link}>
//               Contact
//             </Link>
//             <Link href="/signin" className={classes.link}>
//               Sign In
//             </Link>
//           </Grid>
//           <Grid item xs={12} sm={3}>
//             <Typography variant="h6" gutterBottom>
//               Contact Information
//             </Typography>
//             <Typography variant="body2" display="block" gutterBottom>
//               Email: support@secureaiguard.com
//             </Typography>
//             <Typography variant="body2" display="block" gutterBottom>
//               Phone: +1 (123) 456-7890
//             </Typography>
//             <Typography variant="body2" display="block" gutterBottom>
//               Address: 1234 AI Street, Tech City, TX 75001
//             </Typography>
//           </Grid>
//           <Grid item xs={12} sm={3}>
//             <Typography variant="h6" gutterBottom>
//               Follow Us
//             </Typography>
//             <Box>
//               <IconButton className={classes.iconButton} href="https://facebook.com">
//                 <Facebook />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://twitter.com">
//                 <Twitter />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://linkedin.com">
//                 <LinkedIn />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://github.com">
//                 <GitHub />
//               </IconButton>
//             </Box>
//           </Grid>
//         </Grid>
//         <Box mt={4} textAlign="center">
//           <Typography variant="body2">
//             © {new Date().getFullYear()} SecureAI Guard. All rights reserved.
//           </Typography>
//         </Box>
//       </Container>
//     </Box>
//   );
// }
//
// export default Footer;


// import React from 'react';
// import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
// import { Facebook, Twitter, LinkedIn, GitHub } from '@mui/icons-material';
// import { makeStyles } from '@mui/styles'; // Add this import
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   footer: {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     padding: theme.spacing(6, 0),
//   },
//   logo: {
//     height: '150px',
//     marginBottom: theme.spacing(2),
//   },
//   link: {
//     display: 'block',
//     marginBottom: theme.spacing(1),
//     color: theme.palette.common.white,
//     '&:hover': {
//       textDecoration: 'underline',
//     },
//   },
//   iconButton: {
//     color: theme.palette.common.white,
//     '&:hover': {
//       color: theme.palette.secondary.main,
//     },
//   },
// }));
//
// function Footer() {
//   const classes = useStyles();
//
//   return (
//     <Box className={classes.footer}>
//       <Container maxWidth="lg">
//         <Grid container spacing={4}>
//           <Grid item xs={12} sm={3}>
//             <img src={Logo} alt="SecureAI Guard" className={classes.logo} />
//             <Typography variant="body2">
//               Enterprise-grade Security Layer for Large Language Model Applications.
//             </Typography>
//           </Grid>
//           <Grid item xs={12} sm={3}>
//             <Typography variant="h6" gutterBottom>
//               Quick Links
//             </Typography>
//             <Link href="/" className={classes.link}>
//               Home
//             </Link>
//             <Link href="/about" className={classes.link}>
//               About
//             </Link>
//             <Link href="/services" className={classes.link}>
//               Services
//             </Link>
//             <Link href="/marketplace" className={classes.link}>
//               Marketplace
//             </Link>
//             <Link href="/contact" className={classes.link}>
//               Contact
//             </Link>
//             <Link href="/signin" className={classes.link}>
//               Sign In
//             </Link>
//           </Grid>
//           <Grid item xs={12} sm={3}>
//             <Typography variant="h6" gutterBottom>
//               Contact Information
//             </Typography>
//             <Typography variant="body2" display="block" gutterBottom>
//               Email: support@secureaiguard.com
//             </Typography>
//             <Typography variant="body2" display="block" gutterBottom>
//               Phone: +44 (7879) 222-333
//             </Typography>
//             {/*<Typography variant="body2" display="block" gutterBottom>*/}
//             {/*  /!*Address: 1234 AI Street, Tech City, TX 75001*!/*/}
//             {/*</Typography>*/}
//           </Grid>
//           <Grid item xs={12} sm={3}>
//             <Typography variant="h6" gutterBottom>
//               Follow Us
//             </Typography>
//             <Box>
//               <IconButton className={classes.iconButton} href="https://facebook.com">
//                 <Facebook />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://twitter.com">
//                 <Twitter />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://linkedin.com">
//                 <LinkedIn />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://github.com">
//                 <GitHub />
//               </IconButton>
//             </Box>
//           </Grid>
//         </Grid>
//         <Box mt={4} textAlign="center">
//           <Typography variant="body2">
//             © {new Date().getFullYear()} SecureAI Guard. All rights reserved.
//           </Typography>
//         </Box>
//       </Container>
//     </Box>
//   );
// }
//
// export default Footer;



// import React from 'react';
// import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
// import { Facebook, Twitter, LinkedIn, GitHub } from '@mui/icons-material';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   footer: {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     padding: theme.spacing(6, 0),
//     [theme.breakpoints.down('sm')]: {
//       padding: theme.spacing(4, 0),
//     },
//   },
//   logo: {
//     height: '150px',
//     marginBottom: theme.spacing(2),
//     [theme.breakpoints.down('sm')]: {
//       height: '100px',
//     },
//   },
//   link: {
//     display: 'block',
//     marginBottom: theme.spacing(1),
//     color: theme.palette.common.white,
//     '&:hover': {
//       textDecoration: 'underline',
//     },
//   },
//   iconButton: {
//     color: theme.palette.common.white,
//     '&:hover': {
//       color: theme.palette.secondary.main,
//     },
//   },
//   socialIcons: {
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.up('md')]: {
//       justifyContent: 'flex-start',
//     },
//   },
//   contactInfo: {
//     [theme.breakpoints.down('sm')]: {
//       textAlign: 'center',
//     },
//   },
//   quickLinks: {
//     [theme.breakpoints.down('sm')]: {
//       textAlign: 'center',
//     },
//   },
//   followUs: {
//     [theme.breakpoints.down('sm')]: {
//       textAlign: 'center',
//     },
//   },
// }));
//
// function Footer() {
//   const classes = useStyles();
//
//   return (
//     <Box className={classes.footer}>
//       <Container maxWidth="lg">
//         <Grid container spacing={4}>
//           <Grid item xs={12} sm={3}>
//             <img src={Logo} alt="SecureAI Guard" className={classes.logo} />
//             <Typography variant="body2">
//               Enterprise-grade Security Layer for Large Language Model Applications.
//             </Typography>
//           </Grid>
//           <Grid item xs={12} sm={3} className={classes.quickLinks}>
//             <Typography variant="h6" gutterBottom>
//               Quick Links
//             </Typography>
//             <Link href="/" className={classes.link}>
//               Home
//             </Link>
//             <Link href="/about" className={classes.link}>
//               About
//             </Link>
//             <Link href="/services" className={classes.link}>
//               Services
//             </Link>
//             <Link href="/marketplace" className={classes.link}>
//               Marketplace
//             </Link>
//             <Link href="/contact" className={classes.link}>
//               Contact
//             </Link>
//             <Link href="/signin" className={classes.link}>
//               Sign In
//             </Link>
//           </Grid>
//           <Grid item xs={12} sm={3} className={classes.contactInfo}>
//             <Typography variant="h6" gutterBottom>
//               Contact Information
//             </Typography>
//             <Typography variant="body2" display="block" gutterBottom>
//               Email: support@secureaiguard.com
//             </Typography>
//             <Typography variant="body2" display="block" gutterBottom>
//               Phone: +44 (7879) 222-333
//             </Typography>
//           </Grid>
//           <Grid item xs={12} sm={3} className={classes.followUs}>
//             <Typography variant="h6" gutterBottom>
//               Follow Us
//             </Typography>
//             <Box className={classes.socialIcons}>
//               <IconButton className={classes.iconButton} href="https://facebook.com" aria-label="Facebook">
//                 <Facebook />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://twitter.com" aria-label="Twitter">
//                 <Twitter />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://linkedin.com" aria-label="LinkedIn">
//                 <LinkedIn />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://github.com" aria-label="GitHub">
//                 <GitHub />
//               </IconButton>
//             </Box>
//           </Grid>
//         </Grid>
//         <Box mt={4} textAlign="center">
//           <Typography variant="body2">
//             © {new Date().getFullYear()} SecureAI Guard. All rights reserved.
//           </Typography>
//         </Box>
//       </Container>
//     </Box>
//   );
// }
//
// export default Footer;





// import React from 'react';
// import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
// import { Facebook, LinkedIn, GitHub } from '@mui/icons-material';
// import { makeStyles } from '@mui/styles';
// import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary
// import XIcon from '../../public/assets/images/twitter.png'; // Add the new X (formerly Twitter) logo
//
// const useStyles = makeStyles((theme) => ({
//   footer: {
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//     padding: theme.spacing(6, 0),
//     [theme.breakpoints.down('sm')]: {
//       padding: theme.spacing(4, 0),
//     },
//   },
//   logo: {
//     height: '150px',
//     marginBottom: theme.spacing(2),
//     [theme.breakpoints.down('sm')]: {
//       height: '100px',
//     },
//   },
//   link: {
//     display: 'block',
//     marginBottom: theme.spacing(1),
//     color: theme.palette.common.white,
//     '&:hover': {
//       textDecoration: 'underline',
//     },
//   },
//   iconButton: {
//     color: theme.palette.common.white,
//     '&:hover': {
//       color: theme.palette.secondary.main,
//     },
//   },
//   socialIcons: {
//     display: 'flex',
//     justifyContent: 'center',
//     [theme.breakpoints.up('md')]: {
//       justifyContent: 'flex-start',
//     },
//   },
//   contactInfo: {
//     [theme.breakpoints.down('sm')]: {
//       textAlign: 'center',
//     },
//   },
//   quickLinks: {
//     [theme.breakpoints.down('sm')]: {
//       textAlign: 'center',
//     },
//   },
//   followUs: {
//     [theme.breakpoints.down('sm')]: {
//       textAlign: 'center',
//     },
//   },
//   footerGrid: {
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     [theme.breakpoints.up('md')]: {
//       flexDirection: 'row',
//       justifyContent: 'space-between',
//     },
//   },
//   footerSection: {
//     marginBottom: theme.spacing(4),
//     [theme.breakpoints.up('md')]: {
//       marginBottom: 0,
//     },
//   },
// }));
//
// function Footer() {
//   const classes = useStyles();
//
//   return (
//     <Box className={classes.footer}>
//       <Container maxWidth="lg">
//         <Grid container spacing={4} className={classes.footerGrid}>
//           <Grid item xs={12} md={3} className={classes.footerSection}>
//             <img src={Logo} alt="SecureAI Guard" className={classes.logo} />
//             <Typography variant="body2">
//               Enterprise-grade Security Layer for Large Language Model Applications.
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={3} className={`${classes.quickLinks} ${classes.footerSection}`}>
//             <Typography variant="h6" gutterBottom>
//               Quick Links
//             </Typography>
//             <Link href="/" className={classes.link}>
//               Home
//             </Link>
//             <Link href="/about" className={classes.link}>
//               About
//             </Link>
//             <Link href="/services" className={classes.link}>
//               Services
//             </Link>
//             <Link href="/marketplace" className={classes.link}>
//               Marketplace
//             </Link>
//             <Link href="/contact" className={classes.link}>
//               Contact
//             </Link>
//             <Link href="/signin" className={classes.link}>
//               Sign In
//             </Link>
//           </Grid>
//           <Grid item xs={12} md={3} className={`${classes.contactInfo} ${classes.footerSection}`}>
//             <Typography variant="h6" gutterBottom>
//               Contact Information
//             </Typography>
//             <Typography variant="body2" display="block" gutterBottom>
//               Email: support@secureaiguard.com
//             </Typography>
//             <Typography variant="body2" display="block" gutterBottom>
//               Phone: +44 (7879) 222-333
//             </Typography>
//           </Grid>
//           <Grid item xs={12} md={3} className={`${classes.followUs} ${classes.footerSection}`}>
//             <Typography variant="h6" gutterBottom>
//               Follow Us
//             </Typography>
//             <Box className={classes.socialIcons}>
//               <IconButton className={classes.iconButton} href="https://facebook.com" aria-label="Facebook">
//                 <Facebook />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://twitter.com" aria-label="Twitter">
//                 <img src={XIcon} alt="X" style={{ height: '24px', width: '24px' }} />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://linkedin.com" aria-label="LinkedIn">
//                 <LinkedIn />
//               </IconButton>
//               <IconButton className={classes.iconButton} href="https://github.com" aria-label="GitHub">
//                 <GitHub />
//               </IconButton>
//             </Box>
//           </Grid>
//         </Grid>
//         <Box mt={4} textAlign="center">
//           <Typography variant="body2">
//             © {new Date().getFullYear()} SecureAI Guard. All rights reserved.
//           </Typography>
//         </Box>
//       </Container>
//     </Box>
//   );
// }
//
// export default Footer;





import React from 'react';
import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import { Facebook, LinkedIn, GitHub } from '@mui/icons-material';
import XIcon from '@mui/icons-material/X'; // Import the new X (formerly Twitter) logo
import { makeStyles } from '@mui/styles';
import Logo from '../../public/assets/images/logo.png'; // Adjust the path as necessary

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(6, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
  logo: {
    height: '150px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: '100px',
    },
  },
  link: {
    display: 'block',
    marginBottom: theme.spacing(1),
    color: theme.palette.common.white,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  iconButton: {
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  contactInfo: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  quickLinks: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  followUs: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

function Footer() {
  const classes = useStyles();

  return (
    <Box className={classes.footer}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={3}>
            <img src={Logo} alt="SecureAI Guard" className={classes.logo} />
            <Typography variant="body2">
              Enterprise Security Layer for Large Language Model Applications.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.quickLinks}>
            <Typography variant="h6" gutterBottom>
              Quick Links
            </Typography>
            <Link href="/" className={classes.link}>
              Home
            </Link>
            <Link href="/about" className={classes.link}>
              About
            </Link>
            <Link href="/services" className={classes.link}>
              Services
            </Link>
            <Link href="/marketplace" className={classes.link}>
              Marketplace
            </Link>
            <Link href="/contact" className={classes.link}>
              Contact
            </Link>
            <Link href="/signin" className={classes.link}>
              Sign In
            </Link>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.contactInfo}>
            <Typography variant="h6" gutterBottom>
              Contact Information
            </Typography>
            <Typography variant="body2" display="block" gutterBottom>
              Email: support@secureaiguard.com
            </Typography>
            <Typography variant="body2" display="block" gutterBottom>
              Phone: +44 (7879) 222-333
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} className={classes.followUs}>
            <Typography variant="h6" gutterBottom>
              Follow Us
            </Typography>
            <Box className={classes.socialIcons}>
              <IconButton className={classes.iconButton} href="https://facebook.com" aria-label="Facebook">
                <Facebook />
              </IconButton>
              <IconButton className={classes.iconButton} href="https://twitter.com" aria-label="Twitter">
                <XIcon />
              </IconButton>
              <IconButton className={classes.iconButton} href="https://linkedin.com" aria-label="LinkedIn">
                <LinkedIn />
              </IconButton>
              <IconButton className={classes.iconButton} href="https://github.com" aria-label="GitHub">
                <GitHub />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        <Box mt={4} textAlign="center">
          <Typography variant="body2">
            © {new Date().getFullYear()} SecureAI Guard. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
