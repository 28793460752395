// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
// const useStyles = makeStyles((theme) => ({
//   root: {
//     height: '100vh',
//     backgroundImage: 'url(https://source.unsplash.com/random)',
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     color: theme.palette.common.white,
//     textAlign: 'center',
//   },
//   button: {
//     marginTop: theme.spacing(4),
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.common.white,
//   },
// }));
// const Home = () => {
//   const classes = useStyles();
//   return (
//     <div className={classes.root}>
//       <Container>
//         <Typography variant='h2' component='h1'>
//           Welcome to My Website
//         </Typography>
//         <Typography variant='h5' component='h2' gutterBottom>
//           Explore the world of React and Material-UI
//         </Typography>
//         <Button variant='contained' className={classes.button}>
//           Get Started
//         </Button>
//       </Container>
//     </div>
//   );
// };
// export default Home;

// // Home.js
// import React from 'react';
// import { Container, Typography, Grid, Paper, Button } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//     paddingTop: theme.spacing(4),
//     paddingBottom: theme.spacing(4),
//   },
//   paper: {
//     padding: theme.spacing(2),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
//   },
//   header: {
//     marginBottom: theme.spacing(4),
//   },
//   button: {
//     marginTop: theme.spacing(2),
//   },
//   container: {
//     backgroundColor: '#f5f5f5',
//     padding: theme.spacing(4),
//     borderRadius: '8px',
//   },
// }));
// const Home = () => {
//   const classes = useStyles();
//   return (
//     <Container className={classes.root}>
//       <Typography variant='h2' align='center' className={classes.header}>
//         Welcome to My Website
//       </Typography>
//       <Grid container spacing={3}>
//         <Grid item xs={12} sm={6}>
//           <Paper className={classes.paper}>
//             <Typography variant='h5'>Feature 1</Typography>
//             <Typography variant='body1'>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//             </Typography>
//             <Button variant='contained' color='primary' className={classes.button}>
//               Learn More
//             </Button>
//           </Paper>
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <Paper className={classes.paper}>
//             <Typography variant='h5'>Feature 2</Typography>
//             <Typography variant='body1'>
//               Lorem ipsum dolor sit amet, consectetur adipiscing elit.
//             </Typography>
//             <Button variant='contained' color='primary' className={classes.button}>
//               Learn More
//             </Button>
//           </Paper>
//         </Grid>
//       </Grid>
//     </Container>
//   );
// };
// export default Home;


// src/components/Home.js
// import React from 'react';
// import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
// import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
// const Home = () => {
//   return (
//     <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
//       <Header />
//       <Container component='main' sx={{ mt: 8, mb: 2 }} maxWidth='sm'>
//         <Typography variant='h2' component='h1' gutterBottom>
//           Welcome to My App
//         </Typography>
//         <Typography variant='h5' component='h2' gutterBottom>
//           {'This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information.'}
//         </Typography>
//         <Typography variant='body1'>Use it whenever you need a heading to draw attention to specific content.</Typography>
//         <Button variant='contained' color='primary' sx={{ mt: 3 }}>
//           Get Started
//         </Button>
//       </Container>
//       <Footer />
//     </Box>
//   );
// };
// export default Home;


// import React from 'react';
// import { Container, Typography, Box, Grid, Paper, Button } from '@mui/material';
// import Features from '../components/Features';
// import FutureFeatures from '../components/FutureFeatures';
// import Installation from '../components/Installation';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
// import Contact from '../components/Contact';
// import Support from '../components/Support';
// import License from '../components/License';
// import FeaturesAndFutureFeatures from '@components/FeaturesAndFutureFeatures';
// function Home() {
//     return (
//         <Box sx={{ backgroundColor: '#1c1c1e', color: 'white', minHeight: '100vh', paddingBottom: '2rem' }}>
//             <Container maxWidth='lg'>
//                 <Box py={8} display='flex' flexDirection='column' alignItems='center'>
//                     <img src='/path/to/logo.png' alt='Logo' style={{ marginBottom: '2rem' }} />
//                     <Typography variant='h2' component='h1' gutterBottom align='center' sx={{ fontWeight: 'bold' }}>
//                         SecureAI Guard
//                     </Typography>
//                     <Typography variant='h5' component='h2' gutterBottom align='center'>
//                         Enterprise-grade Security Layer for Language Model Applications
//                     </Typography>
//                     <Box mt={4} display='flex' justifyContent='center' gap={2}>
//                         <Button variant='contained' color='primary' size='large' href='#features' sx={{ backgroundColor: '#e53935', color: '#fff' }}>
//                             Learn More
//                         </Button>
//                         <Button variant='outlined' color='primary' size='large' href='#contact' sx={{ borderColor: '#e53935', color: '#e53935' }}>
//                             Contact Us
//                         </Button>
//                     </Box>
//                 </Box>
//                 <Box mt={8} mb={4} display='flex' justifyContent='center'>
//                     <img src='/path/to/hero-image.png' alt='Hero' style={{ maxWidth: '100%', borderRadius: '8px' }} />
//                 </Box>
//                 <Box id='features' my={4}>
//                     <FeaturesAndFutureFeatures />
//                 </Box>
//                 <Box my={4}>
//                     <Performance />
//                 </Box>
//                 <Box my={4}>
//                     <Approach />
//                 </Box>
//             </Container>
//         </Box>
//     );
// }
// export default Home;


// import React from 'react';
// import { Container, Typography, Box, Grid, Paper, Button } from '@mui/material';
// import Features from '../components/Features';
// import FutureFeatures from '../components/FutureFeatures';
// import Installation from '../components/Installation';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
// import Contact from '../components/Contact';
// import Support from '../components/Support';
// import License from '../components/License';
// import FeaturesAndFutureFeatures from "@components/FeaturesAndFutureFeatures";
//
// function Home() {
//     return (
//         <Container maxWidth="lg">
//             <Box my={4}>
//                 <Paper elevation={3} sx={{ padding: 4 }}>
//                     <Typography variant="h2" component="h1" gutterBottom align="center">
//                         Welcome to SecureAI Guard
//                     </Typography>
//                     <Typography variant="h5" component="h2" gutterBottom align="center">
//                         Enterprise-grade Security Layer for Large Language Model and Generative AI Applications
//                     </Typography>
//                     <Box mt={4} display="flex" justifyContent="center">
//                         <Button variant="contained" color="primary" size="large" href="#features">
//                             Learn More
//                         </Button>
//                     </Box>
//                 </Paper>
//             </Box>
//             <Box id="features" my={4}>
//                 <FeaturesAndFutureFeatures />
//             </Box>
//             {/*<Box my={4}>*/}
//             {/*    <FutureFeatures />*/}
//             {/*</Box>*/}
//             {/*<Box my={4}>*/}
//             {/*    <Installation />*/}
//             {/*</Box>*/}
//             <Box my={4}>
//                 <Performance />
//             </Box>
//             <Box my={4}>
//                 <Approach />
//             </Box>
//             {/*<Box my={4}>*/}
//             {/*    <Contact />*/}
//             {/*</Box>*/}
//             {/*<Box my={4}>*/}
//             {/*    <Support />*/}
//             {/*</Box>*/}
//             {/*<Box my={4}>*/}
//             {/*    <License />*/}
//             {/*</Box>*/}
//         </Container>
//     );
// }
//
// export default Home;


// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Paper, Button } from '@mui/material';
// import FeaturesAndFutureFeatures from '../components/FeaturesAndFutureFeatures';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   heroSection: {
//     height: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundImage: 'url(https://source.unsplash.com/random)',
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//     color: theme.palette.common.white,
//     textAlign: 'left',
//   },
//   heroContent: {
//     maxWidth: '600px',
//   },
//   heroButtons: {
//     marginTop: theme.spacing(4),
//     '& > *': {
//       marginRight: theme.spacing(2),
//     },
//   },
//   heroImage: {
//     width: '100%',
//     maxWidth: '600px',
//     height: 'auto',
//   },
// }));
//
// function Home() {
//   const classes = useStyles();
//
//   return (
//     <div className={classes.root}>
//       <Box className={classes.heroSection}>
//         <Container maxWidth="lg">
//           <Grid container spacing={4} alignItems="center">
//             <Grid item xs={12} md={6}>
//               <Box className={classes.heroContent}>
//                 <Typography variant="h2" component="h1" gutterBottom>
//                   Welcome to SecureAI Guard
//                 </Typography>
//                 <Typography variant="h5" component="h2" gutterBottom>
//                   Enterprise-grade Security Layer for Large Language Model and Generative AI Applications
//                 </Typography>
//                 <Box className={classes.heroButtons}>
//                   <Button variant="contained" color="primary" size="large" href="#features">
//                     Learn More
//                   </Button>
//                   <Button variant="outlined" color="inherit" size="large" href="#contact">
//                     Contact Us
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <img src="/assets/images/image1.jpg" alt="Futuristic Figure" className={classes.heroImage} />
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//       <Container maxWidth="lg">
//         <Box id="features" my={4}>
//           <FeaturesAndFutureFeatures />
//         </Box>
//         <Box my={4}>
//           <Performance />
//         </Box>
//         <Box my={4}>
//           <Approach />
//         </Box>
//       </Container>
//     </div>
//   );
// }
//
// export default Home;


// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Button } from '@mui/material';
// import FeaturesAndFutureFeatures from '../components/FeaturesAndFutureFeatures';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   heroSection: {
//     height: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundImage: 'url(../../public/assets/images/futuristic2.jpg)',
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//     color: theme.palette.common.white,
//     textAlign: 'left',
//   },
//   heroContent: {
//     maxWidth: '600px',
//   },
//   heroButtons: {
//     marginTop: theme.spacing(4),
//     '& > *': {
//       marginRight: theme.spacing(2),
//     },
//   },
//   heroImage: {
//     width: '100%',
//     maxWidth: '600px',
//     height: 'auto',
//   },
// }));
//
// function Home() {
//   const classes = useStyles();
//
//   return (
//     <div className={classes.root}>
//       <Box className={classes.heroSection}>
//         <Container maxWidth="lg">
//           <Grid container spacing={4} alignItems="center">
//             <Grid item xs={12} md={6}>
//               <Box className={classes.heroContent}>
//                 <Typography variant="h2" component="h1" gutterBottom>
//                   Welcome to SecureAI Guard
//                 </Typography>
//                 <Typography variant="h5" component="h2" gutterBottom>
//                   Enterprise-grade Security Layer for Large Language Model and Generative AI Applications
//                 </Typography>
//                 <Box className={classes.heroButtons}>
//                   <Button variant="contained" color="primary" size="large" href="#features">
//                     Learn More
//                   </Button>
//                   <Button variant="outlined" color="inherit" size="large" href="#contact">
//                     Contact Us
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <img src="../../public/assets/images/futuristic2.jpg" alt="Futuristic Figure" className={classes.heroImage} />
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//       <Container maxWidth="lg">
//         <Box id="features" my={4}>
//           <FeaturesAndFutureFeatures />
//         </Box>
//         <Box my={4}>
//           <Performance />
//         </Box>
//         <Box my={4}>
//           <Approach />
//         </Box>
//       </Container>
//     </div>
//   );
// }
//
// export default Home;



// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Button } from '@mui/material';
// import FeaturesAndFutureFeatures from '../components/FeaturesAndFutureFeatures';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   heroSection: {
//     height: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundImage: 'url(../../public/assets/images/futuristic2.jpg)',
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//     color: theme.palette.common.black,
//     textAlign: 'left',
//   },
//   heroContent: {
//     maxWidth: '600px',
//   },
//   heroButtons: {
//     marginTop: theme.spacing(4),
//     '& > *': {
//       marginRight: theme.spacing(2),
//     },
//   },
//   heroImage: {
//     width: '100%',
//     maxWidth: '600px',
//     height: 'auto',
//   },
// }));
//
// function Home() {
//   const classes = useStyles();
//
//   return (
//     <div className={classes.root}>
//       <Box className={classes.heroSection}>
//         <Container maxWidth="lg">
//           <Grid container spacing={4} alignItems="center">
//             <Grid item xs={12} md={6}>
//               <Box className={classes.heroContent}>
//                 <Typography variant="h2" component="h1" gutterBottom>
//                   SecureAI Guard
//                 </Typography>
//                 <Typography variant="h5" component="h2" gutterBottom>
//                   Enterprise-grade Security Layer for Large Language Model (LLMs) and Generative AI Applications.
//                 </Typography>
//                 <Box className={classes.heroButtons}>
//                   <Button variant="contained" color="primary" size="large" href="services">
//                     Learn More
//                   </Button>
//                   <Button variant="outlined" color="inherit" size="large" href="contact">
//                     Contact Us
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <img src="../../public/assets/images/futuristic2.jpg" alt="Futuristic Figure" className={classes.heroImage} />
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//       <Container maxWidth="lg">
//         <Box id="features" my={4}>
//           <FeaturesAndFutureFeatures />
//         </Box>
//         <Box my={4}>
//           <Performance />
//         </Box>
//         <Box my={4}>
//           <Approach />
//         </Box>
//       </Container>
//     </div>
//   );
// }
//
// export default Home;

// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Button } from '@mui/material';
// import FeaturesAndFutureFeatures from '../components/FeaturesAndFutureFeatures';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   heroSection: {
//     height: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundImage: 'url(/assets/images/futuristic2.jpg)', // Use an absolute path from the public directory
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//     color: theme.palette.common.black,
//     textAlign: 'left',
//     backgroundColor: "tomato"
//   },
//   heroContent: {
//     maxWidth: '600px',
//   },
//   heroButtons: {
//     marginTop: theme.spacing(4),
//     '& > *': {
//       marginRight: theme.spacing(2),
//     },
//   },
//   heroImage: {
//     width: '100%',
//     maxWidth: '600px',
//     height: 'auto',
//   },
// }));
// function Home() {
//   const classes = useStyles();
//   return (
//     <div className={classes.root}>
//       <Box className={classes.heroSection}>
//         <Container maxWidth='lg'>
//           <Grid container spacing={4} alignItems='center'>
//             <Grid item xs={12} md={6}>
//               <Box className={classes.heroContent}>
//                 <Typography variant='h2' component='h1' gutterBottom>
//                   SecureAI Guard
//                 </Typography>
//                 <Typography variant='h5' component='h2' gutterBottom>
//                   Enterprise-grade Security Layer for Large Language Model (LLMs) and Generative AI Applications.
//                 </Typography>
//                 <Box className={classes.heroButtons}>
//                   <Button variant='contained' color='primary' size='large' href='services'>
//                     Learn More
//                   </Button>
//                   <Button variant='outlined' color='inherit' size='large' href='contact'>
//                     Contact Us
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <img src='/assets/images/futuristic2.jpg' alt='Futuristic Figure' className={classes.heroImage} />
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//       <Container maxWidth='lg'>
//         <Box id='features' my={4}>
//           <FeaturesAndFutureFeatures />
//         </Box>
//         <Box my={4}>
//           <Performance />
//         </Box>
//         <Box my={4}>
//           <Approach />
//         </Box>
//       </Container>
//     </div>
//   );
// }
// export default Home;




// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Button } from '@mui/material';
// import FeaturesAndFutureFeatures from '../components/FeaturesAndFutureFeatures';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   heroSection: {
//     height: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundImage: 'url(/assets/images/futuristic2.jpg)', // Use an absolute path from the public directory
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//     color: theme.palette.common.white,
//     textAlign: 'left',
//     position: 'relative',
//     '&::before': {
//       content: '""',
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay for better text visibility
//       zIndex: 1,
//     },
//   },
//   heroContent: {
//     maxWidth: '600px',
//     position: 'relative',
//     zIndex: 2,
//   },
//   heroButtons: {
//     marginTop: theme.spacing(4),
//     '& > *': {
//       marginRight: theme.spacing(2),
//     },
//   },
//   heroImage: {
//     width: '100%',
//     maxWidth: '600px',
//     height: 'auto',
//     position: 'relative',
//     zIndex: 2,
//   },
// }));
//
// function Home() {
//   const classes = useStyles();
//
//   return (
//     <div className={classes.root}>
//       <Box className={classes.heroSection}>
//         <Container maxWidth="lg">
//           <Grid container spacing={4} alignItems="center">
//             <Grid item xs={12} md={6}>
//               <Box className={classes.heroContent}>
//                 <Typography variant="h2" component="h1" gutterBottom>
//                   SecureAI Guard
//                 </Typography>
//                 <Typography variant="h5" component="h2" gutterBottom>
//                   Enterprise-grade Security Layer for Large Language Model (LLMs) and Generative AI Applications.
//                 </Typography>
//                 <Box className={classes.heroButtons}>
//                   <Button variant="contained" color="primary" size="large" href="services">
//                     Learn More
//                   </Button>
//                   <Button variant="outlined" color="inherit" size="large" href="contact">
//                     Contact Us
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               <img src="/assets/images/futuristic2.jpg" alt="Futuristic Figure" className={classes.heroImage} />
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//       <Container maxWidth="lg">
//         <Box id="features" my={4}>
//           <FeaturesAndFutureFeatures />
//         </Box>
//         <Box my={4}>
//           <Performance />
//         </Box>
//         <Box my={4}>
//           <Approach />
//         </Box>
//       </Container>
//     </div>
//   );
// }
//
// export default Home;




// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Button } from '@mui/material';
// import FeaturesAndFutureFeatures from '../components/FeaturesAndFutureFeatures';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
// import HeroImage from '../../public/assets/images/futuristic2.jpg'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   heroSection: {
//     height: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundImage: `url(${HeroImage})`, // Use the imported image
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//     color: theme.palette.common.white,
//     textAlign: 'left',
//     position: 'relative',
//     '&::before': {
//       content: '""',
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay for better text visibility
//       zIndex: 1,
//     },
//   },
//   heroContent: {
//     maxWidth: '600px',
//     position: 'relative',
//     zIndex: 2,
//   },
//   heroButtons: {
//     marginTop: theme.spacing(4),
//     '& > *': {
//       marginRight: theme.spacing(2),
//     },
//   },
//   heroImage: {
//     width: '100%',
//     maxWidth: '600px',
//     height: 'auto',
//     position: 'relative',
//     zIndex: 2,
//   },
// }));
//
// function Home() {
//   const classes = useStyles();
//
//   return (
//     <div className={classes.root}>
//       <Box className={classes.heroSection}>
//         <Container maxWidth="lg">
//           <Grid container spacing={4} alignItems="center">
//             <Grid item xs={12} md={6}>
//               <Box className={classes.heroContent}>
//                 <Typography variant="h2" component="h1" gutterBottom>
//                   SecureAI Guard
//                 </Typography>
//                 <Typography variant="h5" component="h2" gutterBottom>
//                   Enterprise-grade Security Layer for Large Language Model (LLMs) and Generative AI Applications.
//                 </Typography>
//                 <Box className={classes.heroButtons}>
//                   <Button variant="contained" color="primary" size="large" href="services">
//                     Learn More
//                   </Button>
//                   <Button variant="outlined" color="inherit" size="large" href="contact">
//                     Contact Us
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//             <Grid item xs={12} md={6}>
//               {/*<img src={HeroImage} alt="Futuristic Figure" className={classes.heroImage} />*/}
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//       <Container maxWidth="lg">
//         <Box id="features" my={4}>
//           <FeaturesAndFutureFeatures />
//         </Box>
//         <Box my={4}>
//           <Performance />
//         </Box>
//         <Box my={4}>
//           <Approach />
//         </Box>
//       </Container>
//     </div>
//   );
// }
//
// export default Home;




// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Button } from '@mui/material';
// import FeaturesAndFutureFeatures from '../components/FeaturesAndFutureFeatures';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
// import HeroImage from '../../public/assets/images/image1.jpg'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   heroSection: {
//     height: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundImage: `url(${HeroImage})`, // Use the imported image
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//     color: theme.palette.common.white,
//     textAlign: 'left',
//     position: 'relative',
//     width: '100vw',
//     // marginTop: -theme.spacing(8), // Adjust for the height of the header
//     marginLeft: 'calc(-50vw + 50%)', // Center the section
//     marginTop: 0, // Remove any margin at the top
//     // marginRight: 'calc(50vw + 50%)', // Center the section
//     '&::before': {
//       content: '""',
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay for better text visibility
//       zIndex: 1,
//     },
//   },
//   slider: {
//   width: '100%',
//   height: '100%',
//   '& .slick-slide img': {
//     width: '100%',
//     height: '100vh',
//     objectFit: 'cover', // Maintain aspect ratio
//   },
// },
//   heroContent: {
//     maxWidth: '600px',
//     position: 'relative',
//     zIndex: 2,
//     // width: '100%',
//   },
//   heroButtons: {
//     marginTop: theme.spacing(4),
//     '& > *': {
//       marginRight: theme.spacing(2),
//     },
//   },
//   heroImage: {
//     width: '100%',
//     maxWidth: '600px',
//     height: 'auto',
//     position: 'relative',
//     zIndex: 2,
//   },
// }));
//
// function Home() {
//   const classes = useStyles();
//
//   return (
//     <div className={classes.root}>
//       <Box className={classes.heroSection}>
//         <Container maxWidth="lg">
//           <Grid container spacing={2} alignItems="center">
//             <Grid item xs={12} md={6}>
//               <Box className={classes.heroContent}>
//                 <Typography variant="h2" component="h1" gutterBottom>
//                   SecureAI Guard
//                 </Typography>
//                 <Typography variant="h5" component="h2" gutterBottom>
//                   Enterprise-grade Security Layer for Large Language Models (LLMs) and Generative AI Applications.
//                 </Typography>
//                 <Box className={classes.heroButtons}>
//                   <Button variant="contained" color="primary" size="large" href="services">
//                     Learn More
//                   </Button>
//                   <Button variant="outlined" color="inherit" size="large" href="contact">
//                     Contact Us
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//             {/*<Grid item xs={12} md={6}>*/}
//             {/*  /!*<img src={HeroImage} alt="Futuristic Figure" className={classes.heroImage} />*!/*/}
//             {/*</Grid>*/}
//           </Grid>
//         </Container>
//       </Box>
//       <Container maxWidth="lg">
//         <Box id="features" my={4}>
//           <FeaturesAndFutureFeatures />
//         </Box>
//         <Box my={4}>
//           <Performance />
//         </Box>
//         <Box my={4}>
//           <Approach />
//         </Box>
//       </Container>
//     </div>
//   );
// }
//
// export default Home;
//



// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Button } from '@mui/material';
// import FeaturesAndFutureFeatures from '../components/FeaturesAndFutureFeatures';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
// import HeroImage from '../../public/assets/images/image1.jpg'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   heroSection: {
//     height: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundImage: `url(${HeroImage})`,
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//     color: theme.palette.common.white,
//     textAlign: 'left',
//     position: 'relative',
//     width: '100vw',
//     marginLeft: 'calc(-50vw + 50%)',
//     marginTop: 0,
//     '&::before': {
//       content: '""',
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       zIndex: 1,
//     },
//   },
//   heroContent: {
//     maxWidth: '600px',
//     position: 'relative',
//     zIndex: 2,
//   },
//   heroButtons: {
//     marginTop: theme.spacing(4),
//     '& > *': {
//       marginRight: theme.spacing(2),
//     },
//   },
//   section: {
//     padding: theme.spacing(8, 0),
//     [theme.breakpoints.down('sm')]: {
//       padding: theme.spacing(4, 0),
//     },
//   },
//   sectionTitle: {
//     marginBottom: theme.spacing(4),
//   },
// }));
//
// function Home() {
//   const classes = useStyles();
//
//   return (
//     <div className={classes.root}>
//       <Box className={classes.heroSection}>
//         <Container maxWidth="lg">
//           <Grid container spacing={2} alignItems="center">
//             <Grid item xs={12} md={6}>
//               <Box className={classes.heroContent}>
//                 <Typography variant="h2" component="h1" gutterBottom>
//                   SecureAI Guard
//                 </Typography>
//                 <Typography variant="h5" component="h2" gutterBottom>
//                   Enterprise-grade Security Layer for Large Language Models (LLMs) and Generative AI Applications.
//                 </Typography>
//                 <Box className={classes.heroButtons}>
//                   <Button variant="contained" color="primary" size="large" href="/services">
//                     Learn More
//                   </Button>
//                   <Button variant="outlined" color="inherit" size="large" href="/contact">
//                     Contact Us
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//       <Container maxWidth="lg">
//         <Box id="features" className={classes.section}>
//           <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
//             Features & Future Features
//           </Typography>
//           <FeaturesAndFutureFeatures />
//         </Box>
//         <Box className={classes.section}>
//           <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
//             Performance
//           </Typography>
//           <Performance />
//         </Box>
//         <Box className={classes.section}>
//           <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
//             Our Approach
//           </Typography>
//           <Approach />
//         </Box>
//       </Container>
//     </div>
//   );
// }
//
// export default Home;




// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Button } from '@mui/material';
// import FeaturesAndFutureFeatures from '../components/FeaturesAndFutureFeatures';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
// import HeroImage from '../../public/assets/images/image1.jpg'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   heroSection: {
//     height: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundImage: `url(${HeroImage})`,
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//     color: theme.palette.common.white,
//     textAlign: 'left',
//     position: 'relative',
//     width: '100vw',
//     marginLeft: 'calc(-50vw + 50%)',
//     marginTop: 0,
//     '&::before': {
//       content: '""',
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       zIndex: 1,
//     },
//   },
//   heroContent: {
//     maxWidth: '600px',
//     position: 'relative',
//     zIndex: 2,
//   },
//   heroButtons: {
//     marginTop: theme.spacing(4),
//     '& > *': {
//       marginRight: theme.spacing(2),
//     },
//   },
//   section: {
//     padding: theme.spacing(8, 0),
//     [theme.breakpoints.down('sm')]: {
//       padding: theme.spacing(4, 0),
//     },
//   },
//   sectionTitle: {
//     marginBottom: theme.spacing(4),
//   },
// }));
//
// function Home() {
//   const classes = useStyles();
//
//   return (
//     <div className={classes.root}>
//       <Box className={classes.heroSection}>
//         <Container maxWidth="lg">
//           <Grid container spacing={2} alignItems="center">
//             <Grid item xs={12} md={6}>
//               <Box className={classes.heroContent}>
//                 <Typography variant="h2" component="h1" gutterBottom>
//                   SecureAI Guard
//                 </Typography>
//                 <Typography variant="h5" component="h2" gutterBottom>
//                   Enterprise-grade Security Layer for Large Language Models (LLMs) and Generative AI Applications.
//                 </Typography>
//                 <Box className={classes.heroButtons}>
//                   <Button variant="contained" color="primary" size="large" href="/services">
//                     Learn More
//                   </Button>
//                   <Button variant="outlined" color="inherit" size="large" href="/contact">
//                     Contact Us
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//       <Container maxWidth="lg">
//         <Box id="features" className={classes.section}>
//           <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
//             Features & Future Features
//           </Typography>
//           <FeaturesAndFutureFeatures />
//         </Box>
//         <Box className={classes.section}>
//           <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
//             Performance
//           </Typography>
//           <Performance />
//         </Box>
//         <Box className={classes.section}>
//           <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
//             Our Approach
//           </Typography>
//           <Approach />
//         </Box>
//       </Container>
//     </div>
//   );
// }
//
// export default Home;


// import React from 'react';
// import { makeStyles } from '@mui/styles';
// import { Container, Typography, Box, Grid, Button } from '@mui/material';
// import FeaturesAndFutureFeatures from '../components/FeaturesAndFutureFeatures';
// import Performance from '../components/Performance';
// import Approach from '../components/Approach';
// import HeroImage from '../../public/assets/images/image1.jpg'; // Adjust the path as necessary
//
// const useStyles = makeStyles((theme) => ({
//   root: {
//     backgroundColor: theme.palette.background.default,
//     paddingBottom: theme.spacing(6),
//   },
//   heroSection: {
//     height: '100vh',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     backgroundImage: `url(${HeroImage})`,
//     backgroundSize: 'cover',
//     backgroundRepeat: 'no-repeat',
//     backgroundPosition: 'center',
//     color: theme.palette.common.white,
//     textAlign: 'left',
//     position: 'relative',
//     width: '100vw',
//     marginLeft: 'calc(-50vw + 50%)',
//     marginTop: 0,
//     '&::before': {
//       content: '""',
//       position: 'absolute',
//       top: 0,
//       left: 0,
//       width: '100%',
//       height: '100%',
//       backgroundColor: 'rgba(0, 0, 0, 0.5)',
//       zIndex: 1,
//     },
//   },
//   heroContent: {
//     maxWidth: '600px',
//     position: 'relative',
//     zIndex: 2,
//   },
//   heroButtons: {
//     marginTop: theme.spacing(4),
//     '& > *': {
//       marginRight: theme.spacing(2),
//     },
//   },
//   section: {
//     padding: theme.spacing(8, 0),
//     [theme.breakpoints.down('sm')]: {
//       padding: theme.spacing(4, 0),
//     },
//   },
//   sectionTitle: {
//     marginBottom: theme.spacing(4),
//   },
// }));
//
// function Home() {
//   const classes = useStyles();
//
//   return (
//     <div className={classes.root}>
//       <Box className={classes.heroSection}>
//         <Container maxWidth="lg">
//           <Grid container spacing={2} alignItems="center">
//             <Grid item xs={12} md={6}>
//               <Box className={classes.heroContent}>
//                 <Typography variant="h2" component="h1" gutterBottom>
//                   SecureAI Guard
//                 </Typography>
//                 <Typography variant="h5" component="h2" gutterBottom>
//                   Enterprise Security Layer for Large Language Models (LLMs) and Generative AI Applications.
//                 </Typography>
//                 <Box className={classes.heroButtons}>
//                   <Button variant="contained" color="primary" size="large" href="/services">
//                     Learn More
//                   </Button>
//                   <Button variant="outlined" color="inherit" size="large" href="/contact">
//                     Contact Us
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//           </Grid>
//         </Container>
//       </Box>
//       <Container maxWidth="lg">
//         <Box id="features" className={classes.section}>
//           <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
//             Features & Future Features
//           </Typography>
//           <FeaturesAndFutureFeatures />
//         </Box>
//         <Box className={classes.section}>
//           <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
//             Performance
//           </Typography>
//           <Performance />
//         </Box>
//         <Box className={classes.section}>
//           <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
//             Our Approach
//           </Typography>
//           <Approach />
//         </Box>
//       </Container>
//     </div>
//   );
// }
//
// export default Home;





import React from 'react';
import { makeStyles } from '@mui/styles';
import { Container, Typography, Box, Grid, Button } from '@mui/material';
import FeaturesAndFutureFeatures from '../components/FeaturesAndFutureFeatures';
import Performance from '../components/Performance';
import Approach from '../components/Approach';
import HeroImage from '../../public/assets/images/image1.jpg'; // Adjust the path as necessary

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(6),
  },
  heroSection: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${HeroImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    color: theme.palette.common.white,
    textAlign: 'left',
    position: 'relative',
    width: '100vw',
    marginLeft: 'calc(-50vw + 50%)',
    marginTop: 0,
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1,
    },
  },
  heroContent: {
    maxWidth: '600px',
    position: 'relative',
    zIndex: 2,
  },
  heroButtons: {
    marginTop: theme.spacing(4),
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
  section: {
    padding: theme.spacing(8, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
    },
  },
  sectionTitle: {
    marginBottom: theme.spacing(4),
  },
}));

function Home() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box className={classes.heroSection}>
        <Container maxWidth="lg">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box className={classes.heroContent}>
                <Typography variant="h2" component="h1" gutterBottom>
                  SecureAI Guard
                </Typography>
                <Typography variant="h5" component="h2" gutterBottom>
                  Enterprise-grade Security Layer for Large Language Models (LLMs) and Generative AI Applications.
                </Typography>
                <Box className={classes.heroButtons}>
                  <Button variant="contained" color="primary" size="large" href="/services">
                    Learn More
                  </Button>
                  <Button variant="outlined" color="inherit" size="large" href="/contact">
                    Contact Us
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="lg">
        <Box id="features" className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
            Features & Future Features
          </Typography>
          <FeaturesAndFutureFeatures />
        </Box>
        <Box className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
            Performance
          </Typography>
          <Performance />
        </Box>
        <Box className={classes.section}>
          <Typography variant="h4" component="h2" className={classes.sectionTitle} align="center">
            Our Approach
          </Typography>
          <Approach />
        </Box>
      </Container>
    </div>
  );
}

export default Home;

